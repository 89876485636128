import { Grid, Typography } from '@mui/material'
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'
import {
    Archive as ArchiveIcon,
    Restore,
    DeleteForever,
} from '@mui/icons-material/'
import { useContext, useEffect, useReducer } from 'react'
import {
    DataGridLayout,
    FormSection,
    UniversalCookieCrumb,
    UniversalDataTable,
    UniversalLoadingCircle,
    UniversalModal,
    UniversalToolBar,
} from '../../UniversalComponents'

import { ToastMessage } from '../../../context/ToastPopUpContext'
import { useMinimizer } from '../../../context/MinimizeContext'
import {
    deactivateManufacturers,
    deleteManufacturer,
    getManufacturers,
} from '../../../apiRoutes'

import {
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../utilities/functionTools'
import { useAuth, usePermissions } from '../../../context/AuthenticationContext'
import { permissionsCheck } from '../../../utilities/functionTools'
import ManufacturerForm from './ManufacturerForm'

const today = new Date().toISOString().substring(0, 10)

const Manufacturer = ({ restoredData }) => {
    const reducer = (state, newState) => ({ ...state, ...newState })
    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { username, activeManufacturer, userRole, userPreferences, _id } =
        currentUser.auth
    const userPermissions = usePermissions()

    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: {},
        singleManufacturer: {},
        formCache: {},
        activateData: [],
        viewDeactivated: false,
        data: [],
        manufacturers: {
            columns: [
                {
                    field: 'manufacturerName',
                    headerName: 'MANUFACTURER',
                    width: 150,
                },
                {
                    field: 'manufacturerType',
                    headerName: 'MANUFACTURER TYPE',
                    width: 150,
                },
                {
                    field: 'manufacturerContactName',
                    headerName: 'CONTACT NAME',
                    width: 150,
                },
                {
                    field: 'manufacturerContactEmail',
                    headerName: 'EMAIL',
                    width: 150,
                },
            ],
            density: 'standard',
        },
        density:
            userPreferences?.dataTableColumns?.manufacturers?.density ||
            'standard',
    }

    const setToastMessage = useContext(ToastMessage)
    const [state, setState] = useReducer(reducer, defaultState)
    const { setMinimized } = useMinimizer()

    const {
        open,
        row,
        formCache,
        viewDeactivated,
        data,
        activateData,
        manufacturers,
        density,
        singleManufacturer,
    } = state

    const getData = async (active) => {
        try {
            const manufacturers = await getManufacturers(active)

            if (manufacturers && manufacturers.length !== 0) {
                const manufacturersList = standardColumnMapper(
                    defaultColumns,
                    manufacturers
                )
                const findActiveManufacturer = manufacturersList.find(
                    ({ manufacturerName }) =>
                        manufacturerName === activeManufacturer
                )

                setState({
                    data: manufacturersList,
                    singleManufacturer: findActiveManufacturer,
                })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const manufacturers =
            userPreferences?.dataTableColumns?.manufacturers?.columns

        if (Array.isArray(manufacturers)) {
            const mapColumns = manufacturers.map((manufacturer) => ({
                field: manufacturer?.field,
                headerName: manufacturer.headerName,
                width: manufacturer?.width,
                isVisible: manufacturer.isVisible,
            }))

            const format = {
                columns: mapColumns,
            }

            setState({ ...state, manufacturers: format })
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'manufacturers',
                value: manufacturers,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'manufacturerName',
            setState,
            getData,
            route: deactivateManufacturers,
            viewDeactivated,
            setToastMessage,
            page: 'manufacturers',
        })
    }

    // returnKey is the key of the value wanting to be returned.. example manufacturerName: 'Osmosis'
    // route is the API route function name being imported into index. example deactivateManufacturers
    // page is the object being defined in the API route for example const { manufacturers, updatedBy, activate } = req.body
    // manufactuers is the page

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'manufacturerName',
            setState,
            getData,
            route: deleteManufacturer,
            viewDeactivated,
            setToastMessage,
            page: 'manufacturers',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Manufacturers?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        getData(true)
        collectColumnData()
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.manufacturerName) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        if (newData !== undefined) {
            return getData(!viewDeactivated)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.manufacturerName
                ? `Edit: ${formCache.manufacturerName}`
                : 'Add New Boat Manufacturer',
            link: '/admin/boats',
            data: formCache.id
                ? { ...formCache, tab: 'manufacturer' }
                : { ...formCache, id: 'newManfacturer', tab: 'manufacturer' },
            id: formCache.id ? formCache.id : 'newManufactuer',
        })
        setState({ open: false })
    }

    const defaultColumns = [
        'manufacturerName',
        'manufacturerContactName',
        'manufacturerContactEmail',
        'archived',
        'active',
    ]

    const buttons = [
        {
            buttonName: 'Add Manufacturer',
            buttonIcon: <PrecisionManufacturingIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Manufacturer'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Manufacturer'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Manufacturers'
                : 'View Deactivated Manufacturers',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        // {
        //     buttonName: 'Delete',
        //     buttonIcon: <DeleteForever />,
        //     buttonFunction: handleDeletionCheck,
        //     disabled: permissionsCheck(userPermissions, 'Super Admin'),
        //     display: !viewDeactivated ? 'none' : null,
        // },
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setData = { columns: format, density: setDensity }

        setState({
            manufacturers: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            manufacturers: {
                ...manufacturers,
                density: newDensity,
            },
        })
    }
    const isSuperAdmin = userRole === 'Super Admin'
    // logic to differentiate between super admin and other users

    return (
        <Grid container>
            {!isSuperAdmin ? (
                <Grid container>
                    <Grid item xs={12}>
                        <UniversalCookieCrumb />
                    </Grid>
                    <Grid item xs={12}>
                        <ManufacturerForm
                            row={singleManufacturer}
                            formDescription={''}
                            onClose={() => {}}
                            permissionCheck={permissionsCheck(
                                userPermissions,
                                'Manufacturer'
                            )}
                        />
                    </Grid>
                </Grid>
            ) : (
                <DataGridLayout
                    cookieCrumbs={<UniversalCookieCrumb />}
                    toolbar={<UniversalToolBar buttons={buttons} />}
                    dataGrid={
                        <UniversalLoadingCircle
                            data={data}
                            reloadFunction={
                                !viewDeactivated
                                    ? () => getData(true)
                                    : () => getData(false)
                            }
                            customTimeoutMessage={
                                !!viewDeactivated &&
                                'No Deactivated Manufacturers Available'
                            }
                        >
                            <UniversalDataTable
                                data={data}
                                apiDensity={density}
                                apiColumns={manufacturers?.columns}
                                savePreferences={() => submitColumnData()}
                                visibleColumns={[]}
                                getRowData={(tableRow) =>
                                    setModalVisbility(tableRow, undefined)
                                }
                                onRowSelectionModelChange={(newSelection) => {
                                    setState({ activateData: newSelection })
                                }}
                                onUpdatedColumnsChange={
                                    handleUpdatedColumnsChange
                                }
                                onDensityChange={handleDensityChange}
                            />
                        </UniversalLoadingCircle>
                    }
                />
            )}

            <UniversalModal
                title={
                    row.id
                        ? `Currently Editing ${row.manufacturerName}`
                        : 'Add New Manufacturer'
                }
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                    marginBottom: 5,
                }}
                timeStamp={`${
                    row.updatedBy
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                onMinimize={minimizeModal}
                draggable={true}
                minimizable={true}
            >
                <ManufacturerForm
                    row={row}
                    formDescription={''}
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Manufacturer'
                    )}
                />
            </UniversalModal>
        </Grid>
    )
}

export default Manufacturer
