import boats from './Boats'
import engines from './Engines'
import users from './Users'
import devices from './Devices'
import dealers from './Dealers'
import signals from './Signals'

export const Boats = boats
export const Engines = engines
export const Users = users
export const Devices = devices
export const Dealers = dealers
export const Signals = signals
