import {
    UniversalDataTable,
    UniversalModal,
} from '../../../../UniversalComponents'
import { useContext, useEffect, useReducer, useRef } from 'react'
import { Button, Grid } from '@mui/material'
import { AddBox } from '@mui/icons-material'
import EngineCalibrationForm from './EngineCalibrationForm'
import {
    permissionsCheck,
    standardColumnMapper,
} from '../../../../../utilities/functionTools'
import {
    useAuth,
    usePermissions,
} from '../../../../../context/AuthenticationContext'
import { ToastMessage } from '../../../../../context/ToastPopUpContext'

const today = new Date().toISOString().substring(0, 10)

interface engineCalibration {
    activeEngineCalibrationFile: any
    engineCalibrationFiles: any
    addCalibrationFile: any
}

const EngineCalibration = ({
    activeEngineCalibrationFile,
    engineCalibrationFiles,
    addCalibrationFile,
}: engineCalibration) => {
    const currentUser = useAuth()
    const { setUser } = useAuth()
    const setToastMessage = useContext(ToastMessage)
    const { userPreferences, username } = currentUser.auth
    const userPermissions = usePermissions()
    const defaultState = {
        row: {},
        density:
            userPreferences?.dataTableColumns?.engines?.engineCalibration
                ?.density || 'standard',
        open: false,
        engines: {
            engineCalibration: {
                columns: [
                    {
                        field: 'engineCalibrationActive',
                        headerName: 'ACTIVE',
                        width: 150,
                        type: 'boolean',
                    },
                    {
                        field: 'engineCalibrationECMPartNumber',
                        headerName: 'ECM PN',
                        width: 100,
                        type: 'string',
                    },
                    {
                        field: 'engineCalibrationProductionDate',
                        headerName: 'PROD DATE',
                        width: 100,
                        type: 'string',
                    },
                    {
                        field: 'engineCalibrationRevision',
                        headerName: 'REV',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'engineCalibrationSoftwareModel',
                        headerName: 'SW MODEL',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'engineCalibrationEControlsSoftwareRevision',
                        headerName: 'ECONTROLS SW REV',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'engineCalibrationFileName',
                        headerName: 'EMOT FILE NAME',
                        width: 100,
                        type: 'string',
                    },
                    {
                        field: 'engineCalibrationReleaseNotes',
                        headerName: 'RELEASE NOTES',
                        width: 100,
                        type: 'string',
                    },
                    {
                        field: 'engineCalibrationChecksum',
                        headerName: 'CHECKSUM',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'engineCalibrationSeverityLevel',
                        headerName: 'Severity Level',
                        width: 150,
                        type: 'string',
                    },
                ],
                density: 'standard',
            },
        },
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const { density, open, row, engines } = state
    const { engineCalibration } = engines

    const collectColumnData = () => {
        const data =
            userPreferences?.dataTableColumns?.engines?.engineCalibration
                ?.columns

        if (Array.isArray(data)) {
            const mapColumns = data?.map((item) => ({
                field: item?.field,
                headerName: item?.headerName,
                width: item?.width,
                isVisible: item?.isVisible,
                type: item?.type,
            }))

            const format = { engineCalibration: { columns: mapColumns } }

            setState({ ...state, engines: format })
        }
    }

    const submitColumnData = async () => {
        try {
            setUser({
                key: 'engines',
                value: engines,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }
    useEffect(() => {
        collectColumnData()
    }, [])

    const defaultColumns = [
        'engineCalibrationECMPartNumber',
        'engineCalibrationProductionDate',
        'engineCalibrationRevision',
        'engineCalibrationSoftwareModel',
        'engineCalibrationEControlsSoftwareRevision',
        'engineCalibrationFileName',
        'engineCalibrationReleaseNotes',
        'engineCalibrationChecksum',
        'engineCalibrationApproved',
        'engineCalibrationReviewers',
        'engineCalibrationActive',
        'engineCalibrationSeverityLevel',
        'updatedBy',
        'updatedAt',
        'createdAt',
    ]
    const parsedEngineCalibrationFiles = standardColumnMapper(
        defaultColumns,
        engineCalibrationFiles
    )

    const visibleColumns = [
        'engineCalibrationECMPartNumber',
        'engineCalibrationProductionDate',
        'engineCalibrationRevision',
        'engineCalibrationSoftwareModel',
        'engineCalibrationEControlsSoftwareRevision',
        'engineCalibrationFileName',
        'engineCalibrationReleaseNotes',
        'engineCalibrationChecksum',
        'engineCalibrationActive',
        'engineCalibrationSeverityLevel',
    ]

    const customColumnNames = [
        'ECM PN',
        'PROD DATE',
        'REV',
        'SW MODEL',
        'ECONTROLS SW REV',
        'EMOT FILE NAME',
        'RELEASE NOTES',
        'CHECKSUM',
        'ACTIVE',
        'Severity Level',
    ]

    const setModalVisbility = async (tableRow: any) => {
        if (tableRow?.engineCalibrationFileName) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({
                row: defaultState.row,
                open: !open,
            })
        }
    }

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
            type: col.type,
        }))

        const setDensity = state.density

        const setData = {
            engineCalibration: {
                ...engineCalibration,
                columns: format,
                density: setDensity,
            },
        }

        setState({
            engines: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            engines: {
                engineCalibration: {
                    ...engineCalibration,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBox />}
                    onClick={(tableRow) => {
                        setState({ open: true }), setModalVisbility(tableRow)
                    }}
                    fullWidth
                    sx={{
                        height: 55,
                    }}
                    disabled={permissionsCheck(userPermissions, 'Engines')}
                >
                    Add Calibration File
                </Button>
            </Grid>
            <Grid item xs={12}>
                {engineCalibrationFiles &&
                    engineCalibrationFiles.length !== 0 && (
                        <UniversalDataTable
                            data={parsedEngineCalibrationFiles}
                            sx={{ marginTop: -2 }}
                            apiDensity={density}
                            apiColumns={engines?.engineCalibration?.columns}
                            savePreferences={() => submitColumnData()}
                            visibleColumns={[]}
                            customColumnNames={customColumnNames}
                            getRowData={(tableRow) =>
                                setModalVisbility(tableRow)
                            }
                            checkboxSelection={false}
                            onUpdatedColumnsChange={handleUpdatedColumnsChange}
                            onDensityChange={handleDensityChange}
                        />
                    )}
            </Grid>
            <UniversalModal
                title={`${
                    row.id
                        ? `Calibration File : ${row.engineCalibrationFileName}`
                        : 'Configure A New Engine Calibration File'
                }`}
                timeStamp={`${
                    row.engineCalibrationFileName
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
            >
                <EngineCalibrationForm
                    row={row}
                    onClose={(tableRow) => setModalVisbility(tableRow)}
                    quickStart={false}
                    addCalibrationFile={addCalibrationFile}
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Engines'
                    )}
                    calibrationData={engineCalibrationFiles}
                />
            </UniversalModal>
        </Grid>
    )
}

export default EngineCalibration
