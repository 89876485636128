import { useRef, useEffect, useReducer, useContext } from 'react'
import { Grid, Stack, Typography, CircularProgress } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import {
    FormSection,
    UniversalCookieCrumb,
    UniversalLoadingCircle,
    UniversalDataTable,
} from '../../UniversalComponents'
import { useAuth } from '../../../context/AuthenticationContext'
import { useToastMessage } from '../../../context/ToastPopUpContext'
import { getBoats, getTelemetry } from '../../../apiRoutes'
import AnalyticsFilter from './FleetAnalyticsFilter'
import {
    BarChart,
    DonutBoatModelGraph,
    DonutBoatManufacturerGraph,
    EngineHoursHistogram,
} from './FleetAnalyticsCharts'
import {
    parseTelemetryData,
    parseTelemetryDataColumns,
    parseTelemetryDataGrid,
    standardColumnMapper,
} from '../../../utilities/functionTools'

const FleetAnalytics = () => {
    const defaultState = {
        telemetry: [],
        telemtryDatagrid: [],
        telemetryDatagridColumns: [],
        fullHinOptions: [],
        fullHin: '',
        submitted: {
            submittedWithHin: false,
            submittedTelemetryType: 'Combined',
        },
        filters: {
            startDate: dayjs().startOf('hour').startOf('week'),
            endDate: dayjs().endOf('hour').endOf('week'),
            telemetryType: 'Combined',
            boatId: '',
        },
        loading: true,
        datagridView: false,
    }

    const currentUser = useAuth()
    const setToastMessage = useToastMessage()
    const { activeManufacturer, user, userRole } = currentUser.auth
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        telemetry,
        telemetryDatagrid,
        telemetryDatagridColumns,
        filters,
        loading,
        fullHinOptions,
        fullHin,
        submitted,
        datagridView,
    } = state
    const { startDate, endDate, telemetryType, _id } = filters
    const { submittedTelemetryType, submittedWithHin, submittedWithDatagrid } =
        submitted
    const getOptionsData = async () => {
        try {
            let parsedFullHinOptions: any = []
            const getBoatData = await getBoats(true)

            if (getBoatData && getBoatData?.length !== 0) {
                parsedFullHinOptions = getBoatData.map((boat) => ({
                    id: boat?._id,
                    label: boat?.fullHin,
                }))
            }
            setState({
                fullHinOptions: parsedFullHinOptions,
            })
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }
    const getData = async (filters: any) => {
        try {
            let parsedTelemetryData: any = []
            let parsedTelemetryDataColumns: any = []
            let parsedTelemetryDataGrid: any = []

            const getTelemetryData = await getTelemetry(filters)

            if (getTelemetryData && getTelemetryData?.length !== 0) {
                parsedTelemetryData = parseTelemetryData(getTelemetryData)
                parsedTelemetryDataColumns =
                    parseTelemetryDataColumns(getTelemetryData)
                parsedTelemetryDataGrid =
                    parseTelemetryDataGrid(getTelemetryData)
                parsedTelemetryDataGrid = standardColumnMapper(
                    parsedTelemetryDataColumns,
                    parsedTelemetryDataGrid
                )
            }

            setState({
                telemetry: parsedTelemetryData,
                telemetryDatagridColumns: parsedTelemetryDataColumns,
                telemetryDatagrid: parsedTelemetryDataGrid,
                submitted: {
                    submittedTelemetryType: telemetryType,
                    submittedWithHin: fullHin ? true : false,
                },
                loading: false,
            })
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }
    useEffect(() => {
        getOptionsData()
        getData(filters)
    }, [])

    const telemetryOptions = ['Combined', 'Ignition', 'Sleep']
    const setFilter = (e: any) => {
        const { name, value } = e.target
        if (name === 'startDate') {
            setState({
                filters: { ...filters, [name]: dayjs(value).startOf('hour') },
            })
        } else if (name === 'endDate') {
            setState({
                filters: { ...filters, [name]: dayjs(value).endOf('hour') },
            })
        } else if (name === 'boatId') {
            setState({
                fullHin: value?.label ? value.label : '',
                filters: { ...filters, [name]: value?.id ? value.id : '' },
            })
        } else if (name === 'datagridView') {
            setState({
                datagridView: !datagridView,
            })
        } else {
            setState({
                filters: { ...filters, [name]: value },
            })
        }
    }

    const handleSubmit = () => {
        setState({ loading: true })
        getData(filters)
    }

    const batteryFooterLabels = [
        '0v',
        '2v',
        '6v',
        '8v',
        '10v',
        '12v',
        '14v',
        '16v',
    ]
    const engineSpeedFooterLabels = [
        '0rpm',
        '1000rpm',
        '2000rpm',
        '3000rpm',
        '4000rpm',
        '5000rpm',
        '6000rpm',
        '7000rpm',
        '8000rpm',
    ]
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: 10 }}>
                <UniversalCookieCrumb customTitle="Fleet Analytics" />
            </Grid>

            <Grid item xs={12}>
                <AnalyticsFilter
                    startDate={startDate}
                    endDate={endDate}
                    telemetryType={telemetryType}
                    telemetryOptions={telemetryOptions}
                    datagridView={datagridView}
                    fullHin={fullHin}
                    fullHinOptions={fullHinOptions}
                    handleSubmit={handleSubmit}
                    setFilter={setFilter}
                />
            </Grid>
            {(loading && (
                <Grid xs={12} item>
                    <Stack
                        sx={{
                            color: 'grey.500',
                            direction: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 25,
                        }}
                        spacing={2}
                        direction="row"
                    >
                        <Typography color={'primary'} variant="h4">
                            Generating Charts...
                        </Typography>

                        <CircularProgress color="primary" size={100} />
                    </Stack>
                </Grid>
            )) ||
                (!loading && !datagridView && (
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {!submittedWithHin && (
                                <Grid item xs={6}>
                                    <FormSection title="Cumlative Entries By Boat Model">
                                        <Grid item xs={12} md={12}>
                                            <DonutBoatModelGraph
                                                telemetry={telemetry}
                                            />
                                        </Grid>
                                    </FormSection>
                                </Grid>
                            )}
                            {!submittedWithHin && (
                                <Grid item xs={6}>
                                    <FormSection title="Cumlative Entries By Boat Manufacturer">
                                        <Grid item xs={12} md={12}>
                                            <DonutBoatManufacturerGraph
                                                telemetry={telemetry}
                                            />
                                        </Grid>
                                    </FormSection>
                                </Grid>
                            )}
                            <Grid item xs={4}>
                                <FormSection title="Cumulative Battery 1">
                                    <Grid item xs={12} md={12}>
                                        <BarChart
                                            telemetry={telemetry}
                                            rawSignal="Analog_Input_1"
                                            telemetryType={
                                                submittedTelemetryType
                                            }
                                            label="Battery 1"
                                            footerLabels={batteryFooterLabels}
                                        />
                                    </Grid>
                                </FormSection>
                            </Grid>
                            <Grid item xs={4}>
                                <FormSection title="Cumulative Battery 2">
                                    <Grid item xs={12} md={12}>
                                        <BarChart
                                            telemetry={telemetry}
                                            rawSignal="Analog_Input_2"
                                            telemetryType={
                                                submittedTelemetryType
                                            }
                                            label="Battery 2"
                                            footerLabels={batteryFooterLabels}
                                        />
                                    </Grid>
                                </FormSection>
                            </Grid>
                            <Grid item xs={4}>
                                <FormSection title="Cumulative Battery 3">
                                    <Grid item xs={12} md={12}>
                                        <BarChart
                                            telemetry={telemetry}
                                            rawSignal="Analog_Input_3"
                                            telemetryType={
                                                submittedTelemetryType
                                            }
                                            label="Battery 3"
                                            footerLabels={batteryFooterLabels}
                                        />
                                    </Grid>
                                </FormSection>
                            </Grid>
                            <Grid item xs={7}>
                                <FormSection title="Cumulative Engine Hours">
                                    <Grid item xs={12} md={12}>
                                        <EngineHoursHistogram
                                            telemetry={telemetry}
                                            rawSignal="Totalenginehours_0"
                                            telemetryType={
                                                submittedTelemetryType
                                            }
                                            label="Engine Hours"
                                        />
                                    </Grid>
                                </FormSection>
                            </Grid>
                            <Grid item xs={5}>
                                <FormSection title="Cumulative Engine Speeds">
                                    <Grid item xs={12} md={12}>
                                        <BarChart
                                            telemetry={telemetry}
                                            rawSignal="EngineSpeed_0"
                                            telemetryType={
                                                submittedTelemetryType
                                            }
                                            label="Engine Speed"
                                            footerLabels={
                                                engineSpeedFooterLabels
                                            }
                                        />
                                    </Grid>
                                </FormSection>
                            </Grid>
                        </Grid>
                    </Grid>
                )) ||
                (!loading && datagridView && (
                    <Grid item xs={12}>
                        {telemetryDatagridColumns &&
                        telemetryDatagridColumns?.length !== 0 ? (
                            <UniversalDataTable
                                data={telemetryDatagrid}
                                visibleColumns={telemetryDatagridColumns}
                                apiDensity="compact"
                                checkboxSelection={false}
                                pinnedColumns={{
                                    left: ['fullHin'],
                                    right: ['timestamp'],
                                }}
                            />
                        ) : (
                            <Typography color={'primary'} variant="h4">
                                No Data found for that search.
                            </Typography>
                        )}
                    </Grid>
                ))}
        </Grid>
    )
}

export default FleetAnalytics
