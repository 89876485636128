import {
    Grid,
    Button,
    styled,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
} from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useContext, useReducer, useRef } from 'react'
import { ToastMessage } from '../../../context/ToastPopUpContext'
import { FormSection, SaveButton } from '../../UniversalComponents'
import { excelFileParser } from '../../../utilities/functionTools'
import responseError from '../../../context/responseError/responseError'
import { addMultipleDevices } from '../../../apiRoutes'
import { useAuth } from '../../../context/AuthenticationContext'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

const MultipleDeviceUploader = ({ closeModal, onClose }) => {
    const defaultState = {
        formData: {},
        selectedFile: [],
        open: false,
    }

    const reducer = (state: any, newState: any) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)
    const { selectedFile, formData, open } = state
    const currentUser = useAuth()
    const { user } = currentUser.auth

    const { activeManufacturer } = user

    const fileInputRef = useRef<HTMLInputElement | null>(null)

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]
            setState({ selectedFile: file })

            setToastMessage('File uploaded successfully!', 'success')
        }
    }

    const handleButtonClick = () => {
        fileInputRef.current?.click()
    }
    const handleReset = () => setState({ selectedFile: [] })

    const handleSubmit = async () => {
        try {
            const findManufacturer = activeManufacturer?._id
            const newFormData = {
                findManufacturer,
            }

            const parsedFormData = excelFileParser(newFormData, selectedFile)
            let res: any

            {
                res = await addMultipleDevices(parsedFormData)
                if (res) {
                    const { data, status } = res

                    if (data && status === 200) {
                        const deviceSerials = data?.data.map(
                            ({ deviceSerial }: any) => deviceSerial
                        )

                        setToastMessage(
                            `${deviceSerials} saved successfully'`,
                            'success'
                        )
                        closeModal()
                        onClose()
                    } else {
                        responseError(res, data)
                    }
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    })

    const openGuidelines = () => setState({ open: true })

    return (
        <Grid container spacing={2} marginTop={3}>
            <FormSection
                title={`XLSX File ${
                    selectedFile.length !== 0 ? ` - ${selectedFile?.name}` : ''
                } `}
                reset={
                    <Button onClick={openGuidelines}>
                        <HelpOutlineIcon />
                    </Button>
                }
            >
                <Grid item xs={12}>
                    <Grid item xs={12} md={12}>
                        <center>
                            <Button onClick={handleButtonClick} size="large">
                                <UploadFileIcon
                                    sx={{
                                        fontSize: 50,
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    alignContent="right"
                                    sx={{
                                        marginLeft: 1,
                                    }}
                                >
                                    UPLOAD FILE
                                </Typography>
                            </Button>
                        </center>

                        <VisuallyHiddenInput
                            ref={fileInputRef}
                            type="file"
                            accept=".xlsx"
                            onChange={handleFileSelect}
                        />

                        <Dialog
                            open={open}
                            onClose={() => setState({ open: false })}
                            maxWidth="sm"
                            fullWidth
                        >
                            <DialogTitle>
                                Instructions for Excel File
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="body1" gutterBottom>
                                    1. Order of Columns:
                                </Typography>
                                <Typography
                                    variant="body2"
                                    component="div"
                                    sx={{ marginLeft: 2 }}
                                >
                                    A. Column A is reserved for Device Serial.{' '}
                                    <br />
                                    B. Column B is reserved for ICCID / SIM ID.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{ marginTop: 2 }}
                                >
                                    2. Entitle Sheet name as{' '}
                                    <strong>Sheet1</strong>.
                                </Typography>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>
            </FormSection>
            <SaveButton
                handleSubmit={handleSubmit}
                handleReset={handleReset}
                disabledSave={selectedFile.length === 0}
                disabledReset={selectedFile.length === 0}
            />
        </Grid>
    )
}

export default MultipleDeviceUploader
