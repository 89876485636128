import { FormSection } from '../../../components/UniversalComponents'
import { Step1Engine } from './Engines'
import { Step1Boat, Step2Boat, Step3Boat, Step4Boat } from './Boats'
import { Grid, Button, Typography } from '@mui/material'
import UniversalImageDisplayer from '../../../components/UniversalComponents/UniversalImageDisplay'

const renderStep = (
    step,
    type,
    permissionsCheck,
    setNextStep,
    endQuickStart
) => {
    if (type === 'engine') {
        if (step === 1) {
            return (
                <Step1Engine
                    setNextStep={setNextStep}
                    permissionsCheck={permissionsCheck}
                    endQuickStart={endQuickStart}
                />
            )
        }
    } else if (type === 'boat') {
        if (step === 1) {
            return (
                <Step1Boat
                    setNextStep={setNextStep}
                    permissionsCheck={permissionsCheck}
                />
            )
        }
        if (step === 2) {
            return (
                <Step2Boat
                    setNextStep={setNextStep}
                    permissionsCheck={permissionsCheck}
                />
            )
        }
        if (step === 3) {
            return (
                <Step3Boat
                    setNextStep={setNextStep}
                    permissionsCheck={permissionsCheck}
                />
            )
        }
        if (step === 4) {
            return (
                <Step4Boat
                    setNextStep={setNextStep}
                    permissionsCheck={permissionsCheck}
                    endQuickStart={endQuickStart}
                />
            )
        }
    }
}

const QuickStartMenu = ({
    setStart,
    step,
    type,
    permissionsCheck,
    setNextStep,
    endQuickStart,
}) => {
    const titleGenerator =
        (step === 1 && type === 'engine' && 'Add Calibration File.') ||
        (step === 2 && type === 'engine' && 'Add Engine model.') ||
        (step === 1 && type === 'boat' && 'Provision Device') ||
        (step === 2 && type === 'boat' && 'Add Signal Profile') ||
        (step === 3 && type === 'boat' && 'Add Boat Model') ||
        (step === 4 && type === 'boat' && 'Configure New Boat')
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    align="center"
                    variant="h2"
                    color="primary"
                    sx={{ margin: 2 }}
                >
                    {step === 0
                        ? 'Which product you like to provision?'
                        : `Step ${step}: ${titleGenerator}`}
                </Typography>
                {type === '' ? (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setStart('boat', 1)}
                                fullWidth
                            >
                                <UniversalImageDisplayer
                                    url="https://oupstorage.blob.core.windows.net/os-model-images/G23.png?"
                                    alt="provision-boat"
                                    title="Provision Boat"
                                    width={'55%'}
                                    height={'55%'}
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setStart('engine', 1)}
                                fullWidth
                            >
                                <UniversalImageDisplayer
                                    url="https://pcmengines.com/wp-content/uploads/2023/12/ZZ8S-Transparent-1000x1000-1.png"
                                    alt="provision-engine"
                                    title="Provision Engine"
                                    width={'55%'}
                                    height={'55%'}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    renderStep(
                        step,
                        type,
                        permissionsCheck,
                        setNextStep,
                        endQuickStart
                    )
                )}
            </Grid>
        </Grid>
    )
}

export default QuickStartMenu
