import { useReducer, useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import NavMenuDrawer from './NavMenuDrawer'
import NavMenuToolbar from './NavMenuToolbar'
import { Authentication } from '../../context/AuthenticationContext'
import LoginPage from '../LoginPage'
import OsmosisVideo from '../../assets/osmosis/Media/OsmosisVideo.mp4'
import OsmosisLoginPage from '../../assets/osmosis/Media/OsmosisLoginPageVideo.mp4'
import { AppBar, Box, Grid } from '@mui/material'
import { permissionsCheck } from '../../utilities/functionTools'
import { usePermissions } from '../../context/AuthenticationContext'
import DeactivateAccount from '../LoginPage/DeactivateAccount'

const drawerWidth = 240

interface Props {
    window?: () => Window
}

const Loading = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            />
        </Box>
    )
}

const LoggedOut = () => {
    return (
        <>
            <video
                autoPlay
                loop
                muted
                id="background-video"
                src={OsmosisLoginPage}
            />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },

                    backgroundColor: 'primary',
                }}
            >
                <LoginPage />
            </Box>
        </>
    )
}

const LoggedIn = ({ handleDrawerToggle, mobileOpen, container }) => {
    return (
        <Grid container sx={{ flex: 1 }} className="app-background">
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <NavMenuToolbar handleDrawerToggle={handleDrawerToggle} />
            </AppBar>
            <Box
                component="nav"
                sx={{
                    width: { sm: drawerWidth },
                    flexShrink: { sm: 0 },
                }}
                aria-label="mailbox folders"
            >
                <NavMenuDrawer
                    handleDrawerToggle={handleDrawerToggle}
                    mobileOpen={mobileOpen}
                    container={container}
                    drawerWidth={drawerWidth}
                />
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    backgroundColor: 'primary',
                }}
            >
                <Outlet />
            </Box>
        </Grid>
    )
}

const NavSidebarRoot = (props: Props) => {
    const { window } = props
    const defaultState = {
        mobileOpen: false,
    }
    const reducer = (oldState, newState) => ({ ...oldState, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { mobileOpen } = state
    const AuthenticationContext = useContext(Authentication)
    const { auth } = AuthenticationContext
    const { authenticated, loading } = auth

    const handleDrawerToggle = () => {
        setState({ mobileOpen: !mobileOpen })
    }

    const container =
        window !== undefined ? () => window().document.body : undefined

    // console.log(authenticated, 'authentication')
    const location = useLocation()
    const { pathname } = location

    return (
        <Box sx={{ display: 'flex' }}>
            {(pathname === '/deactivate-account' && <DeactivateAccount />) ||
                (!authenticated &&
                    loading &&
                    pathname !== '/deactivate-account' && <Loading />) ||
                (!authenticated &&
                    !loading &&
                    pathname !== '/deactivate-account' && <LoggedOut />) ||
                (authenticated &&
                    !loading &&
                    pathname !== '/deactivate-account' && (
                        <LoggedIn
                            handleDrawerToggle={handleDrawerToggle}
                            container={container}
                            mobileOpen={mobileOpen}
                        />
                    ))}
        </Box>
    )
}

export default NavSidebarRoot
