import { ReactNode } from 'react'
import { useJsApiLoader, Libraries } from '@react-google-maps/api'
import { osmosisGoogleMapApi } from '../../../config/osmosisConfig'
import { CircularProgress, Grid, Typography } from '@mui/material'

const GOOGLE_MAPS_LIBRARIES: Libraries = ['places']

interface GoogleMapsWrapperProps {
    children: ReactNode
}

export const GoogleMapsWrapper = ({ children }: GoogleMapsWrapperProps) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: osmosisGoogleMapApi,
        libraries: GOOGLE_MAPS_LIBRARIES,
        language: 'en',
    })

    if (!isLoaded) {
        return (
            <Grid item xs={12}>
                <Typography align="center">
                    Loading....
                    <CircularProgress color="primary" size={'50px'} />
                </Typography>
            </Grid>
        )
    }

    return <>{children}</>
}
