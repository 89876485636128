import { Button, Grid, Toolbar, Typography, AppBar } from '@mui/material'

const UniversalToolBar = ({ buttons }) => {
    return (
        <Grid container>
            {buttons.map(
                ({
                    buttonName,
                    buttonIcon,
                    buttonFunction,
                    disabled,
                    display,
                }) => (
                    <Button
                        startIcon={buttonIcon}
                        onClick={buttonFunction}
                        key={`button.${buttonName}`}
                        disabled={disabled}
                        sx={{ display: display }}
                    >
                        {buttonName}
                    </Button>
                )
            )}
        </Grid>
    )
}

export default UniversalToolBar
