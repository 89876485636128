import { useReducer, useEffect, useContext, useRef } from 'react'

import {
    UniversalModal,
    UniversalDataTable,
    UniversalLoadingCircle,
    UniversalToolBar,
    FormSection,
} from '../../../UniversalComponents'
import { Grid, Typography } from '@mui/material'

import {
    Delete as DeleteIcon,
    Archive as ArchiveIcon,
    ArrowOutward as ArrowOutwardIcon,
    AddBox as AddBoxIcon,
    Restore,
    DeleteForever,
} from '@mui/icons-material/'
import EngineeringIcon from '@mui/icons-material/Engineering'
import { getBoats, deactivateBoats, deleteBoat } from '../../../../apiRoutes'
import BoatConfigurationForm from './BoatConfigForms/BoatConfigurationForm'
import BoatConfigurationInsights from './BoatConfigInsights'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { useAuth } from '../../../../context/AuthenticationContext'
import { Socket } from '../../../../context/SocketContext'
import { useMinimizer } from '../../../../context/MinimizeContext'
import {
    columnMapper,
    handleActivation,
    handleDeletion,
    standardColumnMapper,
} from '../../../../utilities/functionTools'
import { useSocket } from '../../../../context/SocketContext'
import { usePermissions } from '../../../../context/AuthenticationContext'
import { permissionsCheck } from '../../../../utilities/functionTools'

const today = new Date().toISOString().substring(0, 10)

const BoatManagement = () => {
    const defaultColumnVisibility = {
        nickname: true,
        shortHin: true,
        fullHin: true,
        deviceSerial: true,
        dealerName: true,
        dealerVisibility: true,
        gpsEnabled: true,
    }
    const setToastMessage = useContext(ToastMessage)
    const authentication = useAuth()
    const currentUser = useAuth()
    const { setUser } = useAuth()
    const { setMinimized, restoredData } = useMinimizer()
    const { auth, setAuth } = authentication
    const { username, userRole, activeManufacturer, userPreferences, _id } =
        auth
    const boatManagementTable = auth?.userPreferences?.boatManagementTable
        ? auth?.userPreferences?.boatManagementTable
        : []
    const defaultState = {
        open: false,
        row: {
            gpsEnabled: false,
            manufacturer: { manufacturerName: activeManufacturer },
        },
        columnVisibility:
            Object.keys(boatManagementTable).length !== 0
                ? boatManagementTable
                : defaultColumnVisibility,
        reset: false,
        openBoatInsights: false,
        formCache: {},
        archive: [],
        viewArchived: false,
        filteredBoats: [],
        viewDeactivated: false,
        activateData: [],
        data: [],
        boats: {
            boatConfig: {
                columns: [
                    {
                        field: 'fullHin',
                        headerName: 'HIN',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'manufacturerName',
                        headerName: 'MANUFACTURER',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'nickname',
                        headerName: 'BOAT NICKNAME',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'modelYear',
                        headerName: 'MODEL YEAR',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'model',
                        headerName: 'MODEL',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'deviceSerial',
                        headerName: 'DEVICE SERIAL',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'iccid',
                        headerName: 'SIM ID',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'engineName',
                        headerName: 'ENGINE',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'engineSerial',
                        headerName: 'ENGINE SERIAL',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'enginemanufacturerName',
                        headerName: 'ENGINE MANUFACTURER',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'dealerName',
                        headerName: 'DEALER NAME',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'ownerEmail',
                        headerName: 'OWNER EMAIL',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'gpsEnabled',
                        headerName: 'GPS ENABLED',
                        width: 300,
                        type: 'boolean',
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.boats?.boatConfig?.density ||
            'standard',
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const userPermissions = usePermissions()

    const {
        open,
        row,
        columnVisibility,
        openBoatInsights,
        formCache,
        archive,
        viewArchived,
        filteredBoats,
        viewDeactivated,
        data,
        activateData,
        boats,
        density,
    } = state

    const { boatConfig } = boats

    const interval = useRef<any>()
    const { socket } = useSocket()

    const getData = async (active) => {
        try {
            const data = await getBoats(active)

            if (data && data.length !== 0) {
                const boats = columnMapper(defaultColumns, data)

                setState({ data: boats })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const boats =
            userPreferences?.dataTableColumns?.boats?.boatConfig?.columns

        if (Array.isArray(boats)) {
            const mapColumns = boats.map((boat) => ({
                field: boat?.field,
                headerName: boat?.headerName,
                width: boat?.width,
                isVisible: boat.isVisible,
                type: boat?.type,
            }))

            const format = {
                boatConfig: {
                    columns: mapColumns,
                },
            }

            setState({ ...state, boats: format })
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'boats',
                value: boats,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'fullHin',
            setState,
            getData,
            route: deactivateBoats,
            viewDeactivated,
            setToastMessage,
            page: 'boats',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'fullHin',
            setState,
            getData,
            route: deleteBoat,
            viewDeactivated,
            setToastMessage,
            page: 'boats',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Boats?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }

    useEffect(() => {
        collectColumnData()
        getData(true)
        socket.on('boat-configuration', (newData) => {
            if (newData) {
                getData(true)
                setToastMessage(`Boat configuration has just updated.`)
            }
        })
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    useEffect(() => {
        if (restoredData?.id !== undefined) {
            setState({ row: restoredData, open: true })
            setMinimized(false, restoredData.id, true)
        }
    }, [restoredData])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.fullHin) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({
                row: defaultState.row,
                open: !open,
            })
        }
        if (newData !== undefined) {
            return getData(true)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache?.fullHin
                ? `Edit: ${formCache.fullHin}`
                : 'Configure a Boat',
            link: '/admin/boats',
            data: formCache.id
                ? { ...formCache, tab: 'configuration' }
                : {
                      ...formCache,
                      id: 'newConfiguration',
                      tab: 'configuration',
                  },
            id: formCache.id ? formCache.id : 'newConfiguration',
        })
        setState({ open: false })
    }

    const setColumnHeaders = (columnHeader, reset?: boolean) => {
        if (!reset) {
            setState({
                columnVisibility: {
                    ...defaultColumnVisibility,
                    ...boatManagementTable,
                    ...columnHeader,
                },
            })
            setAuth({
                preferences: {
                    boatManagementTable: {
                        ...defaultColumnVisibility,
                        ...boatManagementTable,
                        ...columnHeader,
                    },
                },
            })
        } else {
            setAuth({
                preferences: {
                    boatManagementTable: defaultColumnVisibility,
                },
            })
        }
    }

    const defaultColumns = [
        'fullHin',
        'manufacturer.manufacturerName',
        'nickname',
        'boatModel.modelYear',
        'boatModel.model',
        'device.deviceSerial',
        'device.iccid',
        'engine.engineModel.engineModelName',
        'engineSerial',
        'engine.manufacturer.manufacturerName',
        'dealer.dealerName',
        'ownerEmail',
        'gpsEnabled',
        'engine.engineModel.engineModelYear',
        'device._id',
        'engine._id',
        'dealer._id',
        'boatModel._id',
        'createdAt',
        'productionStartDate',
        'shipDate',
        'deliveryDate',
        'dealerVisibility',
        'downloadFileMatchFlag',
        'shortHin',
        'archived',
        'updatedBy',
        'updatedAt',
        'engine.engineIdentifier',
        'engine.engineName',
        'tags',
    ]

    const buttons = [
        {
            buttonName: 'Add New Boat Configuration',
            buttonIcon: <EngineeringIcon />,
            buttonFunction: setModalVisbility,
            disabled: permissionsCheck(userPermissions, 'Boats'),
        },

        {
            buttonName: 'Reset Table',
            buttonIcon: <Restore />,
            buttonFunction: () => {
                setState({ columnVisibility: defaultColumnVisibility })
                setColumnHeaders(defaultColumnVisibility, true)
            },
            disabled: permissionsCheck(userPermissions, 'Boats'),
        },

        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Boats'),
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Boats'
                : 'View Deactivated Boats',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
        // {
        //     buttonName: 'Export',
        //     buttonIcon: <ArrowOutwardIcon />,
        //     buttonFunction: () => {},
        // },
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
            type: col.type,
        }))

        const setDensity = state.density

        const setData = { boatConfig: { columns: format, density: setDensity } }

        setState({
            boats: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            boats: {
                boatConfig: {
                    ...boatConfig,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={!viewDeactivated ? getData : () => {}}
                    customTimeoutMessage={
                        !!viewDeactivated && 'No Deactivated Boats Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        apiDensity={density}
                        apiColumns={boats?.boatConfig?.columns}
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        getRowData={(tableRow) =>
                            // setModalVisbility(tableRow, undefined)
                            setState({
                                openBoatInsights: true,
                                row: tableRow,
                            })
                        }
                        getColumnHeaderVisibility={(columnHeader) =>
                            setColumnHeaders(columnHeader)
                        }
                        columnsAvailable={columnVisibility}
                        onRowSelectionModelChange={(newSelection) => {
                            setState({
                                activateData: newSelection,
                            })
                        }}
                        pinnedColumns={{
                            left: ['fullHin'],
                            right: [''],
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>
            </Grid>
            <UniversalModal
                title={'Configure a Boat'}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                timeStamp={`${
                    row.fullHin
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                onMinimize={minimizeModal}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 10,
                    marginBottom: 5,
                }}
                draggable={true}
                minimizable={true}
            >
                <BoatConfigurationForm
                    row={row}
                    userRole={userRole}
                    formDescription="Enter Boat Details To Add A New Boat."
                    onClose={(tableRow: any, newData?: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    permissionCheck={permissionsCheck(userPermissions, 'Boats')}
                />
            </UniversalModal>
            {
                /// Important to be conditionally formatted for mounting
            }
            {openBoatInsights === true && (
                <UniversalModal
                    title={`${row.fullHin} Live Data Insights`}
                    open={openBoatInsights}
                    onClose={() => {
                        setState({
                            openBoatInsights: false,
                            row: defaultState.row,
                        })
                        clearInterval(interval.current)
                        interval.current = undefined
                        return interval.current
                    }}
                    wrapperStyle={{
                        margin: 'auto',
                        width: '90%',
                        marginTop: 10,
                        marginBottom: 5,
                    }}
                >
                    <BoatConfigurationInsights
                        row={row}
                        openEdit={() =>
                            setState({
                                open: true,
                                openBoatInsights: false,
                            })
                        }
                        interval={interval}
                        editBoat={true}
                    />
                </UniversalModal>
            )}
        </Grid>
    )
}
export default BoatManagement
