import {
    Grid,
    Typography,
    TextField,
    IconButton,
    Tooltip,
    styled,
    Avatar,
    Box,
    FormControlLabel,
    Switch,
} from '@mui/material'
import { useContext, useEffect, useReducer, useState } from 'react'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import {
    addSignal,
    getSignalList,
    getMeasurement,
    addTag,
    unlinkTag,
} from '../../../../apiRoutes'

import { useAuth } from '../../../../context/AuthenticationContext'
import {
    Autocomplete,
    FormSection,
    SaveButton,
    UniversalTagToolbar,
} from '../../../UniversalComponents'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import { parseFormWithFile } from '../../../../utilities/functionTools'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import responseError from '../../../../context/responseError/responseError'
import { basicValidator } from '../../../../utilities/functionTools'
import SignalDefaultIconCard from './SignalDefaultIcon'
const SignalManagerForm = ({
    row,
    formDescription,
    onClose,
    saveCache,
    signals,
    permissionCheck,
    defaultIcons,
}) => {
    const currentUser = useAuth()
    const { username, user } = currentUser.auth
    const setToastMessage = useContext(ToastMessage)
    const defaultState = {
        formData: {
            id: row.id || '',
            signalName: row.signalName || '',
            signalFriendlyName: row.signalFriendlyName || '',
            updatedBy: row.updateBy || username,
            signalIconUrl: row.signalIconUrl || '',
            universalSignalListId: row?.universalSignalList?._id || '',
            activeWeb: row.id ? row.activeWeb : true,
            activeMobile: row.id ? row.activeMobile : false,
            unitOfMeasurement: row?.unitOfMeasurement || '',
            signalPersists: row.id ? row.signalPersists : false,
            manufacturer: row.manufacturer ? row.manufacturer : '',
        },
        formValidation: {
            signalFriendlyNameValidation: false,
            signalNameValidation: false,
            unitOfMeasurementValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
        unitOfMeasurementLabel: row?.unitOfMeasurement?.unitOfMeasurement || '',
        selectedFiles: [],
        setSelectedFiles: [],
        modifiedBy: row.modifiedBy || '',
        measurement: [],
        measurementOptions: [],
        signalList: [],
        permissionsCheck: permissionCheck,
        icon: '',
        successfulSubmission: false,
        entryId: undefined,
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        formValidation,
        selectedFiles,
        unitOfMeasurementLabel,
        measurementOptions,
        signalList,
        icon,
        successfulSubmission,
        entryId,
    } = state

    const {
        signalFriendlyName,
        unitOfMeasurement,
        signalIconUrl,
        universalSignalListId,
        signalName,
        signalPersists,
    } = formData

    const {
        signalFriendlyNameValidation,
        signalNameValidation,
        unitOfMeasurementValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleChange = (e: any) => {
        const { name, value } = e.target

        const isFieldDirty = formData[name] !== value

        if (name === 'unitOfMeasurement') {
            setState({
                formData: {
                    ...formData,
                    unitOfMeasurement: value?.unitOfMeasurement,
                },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
                unitOfMeasurementLabel: value?.label ? value.label : '',
            })
        } else if (name === 'signalName') {
            if (value) {
                setState({
                    formData: {
                        ...formData,
                        signalName: value.label,
                        universalSignalListId: value.id,
                    },
                    formValidation: {
                        ...formValidation,
                        isFormDirty: true,
                    },
                })
            } else {
                setState({
                    formData: {
                        ...formData,
                        signalName: '',
                        universalSignalListId: '',
                    },
                    formValidation: {
                        ...formValidation,
                        isFormDirty: true,
                    },
                })
            }
        } else if (name === 'signalIconUrl') {
            setState({
                formData: { ...formData, [name]: value },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
                icon: '',
                selectedFiles: [],
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
            })
        }
        return saveCache({ ...formData, [name]: value })
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
            icon: defaultState.icon,
            unitOfMeasurementLabel: defaultState.unitOfMeasurementLabel,
        })

    const handleTagSubmit = async (tagData, removedTags) => {
        try {
            if (successfulSubmission) {
                const tag = await addTag({ tagData, entryId })

                if (tag) {
                    const { data, status } = tag

                    if (data && status === 200) {
                        console.log('Tag updated successfully')
                    } else {
                        responseError(tag, row)
                    }
                }

                if (removedTags && removedTags.length > 1) {
                    const removeTags: any = await unlinkTag({ removedTags })

                    if (removeTags) {
                        const { data, status } = removeTags

                        if (data && status === 200) {
                            console.log('Tag removed successfully')
                        } else {
                            responseError(removeTags, row)
                        }
                    }
                }
                setState({ successfulSubmission: false })
                onClose(row.signalFriendlyName, row)
            }
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleSubmit = async () => {
        try {
            const newFormData = {
                ...formData,
                unitOfMeasurement: unitOfMeasurement?._id,
                universalSignalList: universalSignalListId,
                manufacturer: user?.activeManufacturer?._id,
            }

            // delete newFormData.signalName

            const parsedFormData = parseFormWithFile(newFormData, selectedFiles)

            let res: any

            res = await addSignal(parsedFormData)

            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `Signal: ${data.signalFriendlyName} was ${
                            row.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )
                    setState({ entryId: data?._id, successfulSubmission: true })
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const getData = async () => {
        let getMeasurementData: any = []
        let getSignalListData: any = []

        let getMeasurementOptions: any = []
        try {
            getMeasurementData = await getMeasurement(true)
            getSignalListData = await getSignalList()

            if (getSignalListData && getSignalListData.length !== 0) {
                const filteredSignalList: any = []
                getSignalListData?.forEach((signal) => {
                    if (
                        signals.filter(
                            (item) => item.signalName === signal.signalName
                        ).length === 0
                    ) {
                        const newSignal = {
                            id: signal._id,
                            label: signal.signalName,
                        }
                        filteredSignalList.push(newSignal)
                        return filteredSignalList
                    }
                })

                if (filteredSignalList && filteredSignalList?.length !== 0) {
                    getSignalListData = filteredSignalList
                }
            }

            if (getMeasurementData && getMeasurementData?.length !== 0) {
                getMeasurementOptions = getMeasurementData?.map(
                    (measurement) => ({
                        id: measurement?._id,
                        label: measurement?.unitOfMeasurement,
                        unitOfMeasurement: measurement,
                    })
                )
            }

            setState({
                measurementOptions: getMeasurementOptions,
                signalList: getSignalListData,
            })
        } catch (error: any) {
            setToastMessage(error, 'error')
            console.error(error, 'error')
        }
    }

    useEffect(() => {
        getData()
        saveCache(row)
    }, [])

    const handleFileSelect = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]
            const cleanedFileName = file.name.replaceAll(' ', '')
            const modifiedFile = new File([file], cleanedFileName, {
                type: file.type,
            })

            const reader = (file, callback) => {
                const fr = new FileReader()
                fr.onload = () => callback(null, fr.result)
                fr.onerror = (err) => callback(err)
                fr.readAsDataURL(file)
            }
            reader(file, (err, res) => {
                setState({
                    icon: res,
                    selectedFiles: [modifiedFile],
                    formValidation: {
                        ...formValidation,
                        isFormDirty: true,
                    },
                })
            })
        }
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    })

    useEffect(() => {
        if (isFormDirty) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [isFormDirty])

    return (
        <Grid container sx={{ marginTop: 8 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <UniversalTagToolbar
                        row={row}
                        pageType="signals"
                        entryId={entryId}
                        addTag={handleTagSubmit}
                        setFormDirty={() =>
                            setState({
                                formValidation: {
                                    ...formValidation,
                                    isFormValid: true,
                                },
                            })
                        }
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <FormSection title="Signal Values" titleAlignment="left">
                        <Grid item xs={12}>
                            <Autocomplete
                                options={signalList}
                                value={signalName}
                                handleChange={handleChange}
                                required={true}
                                label="Reported Signal Name"
                                name="signalName"
                                disabled={!!row._id}
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={signalNameValidation}
                                helperText={
                                    signalNameValidation
                                        ? 'Please enter  a signal name.'
                                        : ''
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required={true}
                                name="signalFriendlyName"
                                label="Customized Display Name"
                                fullWidth
                                value={signalFriendlyName}
                                onChange={handleChange}
                                disabled={permissionCheck}
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                error={signalFriendlyNameValidation}
                                helperText={
                                    signalFriendlyNameValidation
                                        ? 'Please signal friendly name.'
                                        : ''
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                options={measurementOptions}
                                handleChange={handleChange}
                                value={unitOfMeasurementLabel}
                                fullWidth
                                label="Unit of Measurement"
                                name="unitOfMeasurement"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="unitOfMeasurementAbrv"
                                label="Abrv"
                                fullWidth
                                value={
                                    unitOfMeasurement?.unitOfMeasurementAbrv
                                        ? unitOfMeasurement?.unitOfMeasurementAbrv
                                        : ''
                                }
                                disabled
                            />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                marginTop: 3,
                                padding: 1,
                                textAlign: 'right',
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={() =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    signalPersists:
                                                        !signalPersists,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                        inputProps={{
                                            'aria-label': 'controlled',
                                        }}
                                        id="switch.signalPersists"
                                        name="switch.signalPersists"
                                        checked={signalPersists}
                                        key="switch.signalPersists"
                                    />
                                }
                                label="Persistent Signal"
                                key="signalPersistsb"
                                disabled={permissionCheck}
                            />
                            <Tooltip
                                color="primary"
                                title={
                                    <Typography fontSize="h5">
                                        Toggling on will have the signal value
                                        persist after the device is turned off
                                    </Typography>
                                }
                            >
                                <HelpOutlineIcon />
                            </Tooltip>
                        </Grid>
                    </FormSection>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Grid container spacing={2}>
                        <FormSection
                            title="Signal Displayed Icon"
                            titleAlignment="center"
                        >
                            <Grid item xs={12}>
                                <label htmlFor="raised-button-file">
                                    <Tooltip
                                        title={
                                            signalIconUrl
                                                ? 'Replace Default Icon'
                                                : 'Upload Icon'
                                        }
                                    >
                                        <center>
                                            {signalIconUrl || icon ? (
                                                <IconButton component="label">
                                                    <Box>
                                                        <Avatar
                                                            src={
                                                                icon ||
                                                                signalIconUrl
                                                            }
                                                            sx={{
                                                                width: 100,
                                                                height: 100,
                                                            }}
                                                        />
                                                        <VisuallyHiddenInput
                                                            onChange={(event) =>
                                                                handleFileSelect(
                                                                    event
                                                                )
                                                            }
                                                            type="file"
                                                            accept="image/*"
                                                            id='htmlFor="raised-button-file'
                                                            disabled={
                                                                permissionCheck
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                            ) : (
                                                <IconButton component="label">
                                                    <DriveFolderUploadIcon
                                                        sx={{
                                                            fontSize: 40,
                                                        }}
                                                    />
                                                    <VisuallyHiddenInput
                                                        onChange={(event) =>
                                                            handleFileSelect(
                                                                event
                                                            )
                                                        }
                                                        type="file"
                                                        accept="image/*"
                                                        id='htmlFor="raised-button-file'
                                                        disabled={
                                                            permissionCheck
                                                        }
                                                    />
                                                </IconButton>
                                            )}
                                        </center>
                                    </Tooltip>
                                </label>
                            </Grid>
                        </FormSection>
                        {defaultIcons && defaultIcons?.length !== 0 && (
                            <Grid item xs={12} sx={{ marginTop: 3 }}>
                                <FormSection
                                    title="Select a Default Icon"
                                    titleAlignment="left"
                                >
                                    {defaultIcons.map((defaultIcon: any) => (
                                        <SignalDefaultIconCard
                                            cdnUrl={defaultIcon?.cdnUrl}
                                            name={defaultIcon?.name}
                                            signalIconUrl={signalIconUrl}
                                            handleChange={handleChange}
                                            key={defaultIcon._id}
                                            icon={icon}
                                        />
                                    ))}
                                </FormSection>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>

            <Grid container sx={{ marginTop: 3 }}>
                <SaveButton
                    handleSubmit={handleSubmit || permissionCheck}
                    handleReset={handleReset || permissionCheck}
                    disabledSave={!isFormValid}
                    disabledReset={!isFormDirty}
                />
            </Grid>
        </Grid>
    )
}

export default SignalManagerForm
