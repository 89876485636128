import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api'
import { useState, useCallback, memo, useEffect } from 'react'
import { Grid } from '@mui/material'
import { GoogleMapsWrapper } from './hooks/GoogleMapsApiWrapper'

// Google Maps Documentation here: https://www.npmjs.com/package/@react-google-maps/api
interface FleetGpsObject {
    lat: number
    lng: number
    name: string
}

interface UniversalGoogleMapProps {
    gps?: { lat: string; lng: string }
    style: {
        width?: string | number
        height?: string | number
        backgroundColor?: string
        margin?: string | number
    }
    fleetGps?: FleetGpsObject[]
    mapZoom?: number
}

const UniversalGoogleMap = ({
    gps,
    style,
    fleetGps,
    mapZoom = 9, // Default zoom level
}: UniversalGoogleMapProps) => {
    let lat = 28.446881475873266
    let lng = -81.1580598190508

    if (gps) {
        lat = parseFloat(gps.lat)
        lng = parseFloat(gps.lng)
    }

    const containerStyle = {
        width: style.width,
        height: style.height,
    }

    const center = {
        lat,
        lng,
    }

    const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null)

    const onMapLoad = useCallback(
        (map) => {
            const bounds = new google.maps.LatLngBounds(center)
            map.fitBounds(bounds)

            let listener = google.maps.event.addListener(
                map,
                'idle',
                function () {
                    if (!fleetGps && map.getZoom() > 13) {
                        map.setZoom(13)
                    } else {
                        map.setZoom(6)
                    }
                    google.maps.event.removeListener(listener)
                }
            )

            setGoogleMap(map)
        },
        [center, fleetGps]
    )

    const onUnmount = useCallback(() => {
        setGoogleMap(null)
    }, [])

    useEffect(() => {
        return () => setGoogleMap(null)
    }, [])

    return (
        <Grid
            container
            sx={{
                backgroundColor: style.backgroundColor,
                margin: style.margin,
            }}
        >
            <GoogleMapsWrapper>
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={mapZoom}
                    onLoad={onMapLoad}
                    onUnmount={onUnmount}
                >
                    {gps && gps.lng && gps.lat && (
                        <MarkerF clickable={true} position={{ lat, lng }} />
                    )}
                    {fleetGps?.map(({ lat, lng, name }) => (
                        <MarkerF
                            key={`${name}.marker`}
                            clickable={true}
                            position={{ lat, lng }}
                            label={name}
                        />
                    ))}
                </GoogleMap>
            </GoogleMapsWrapper>
        </Grid>
    )
}

export default memo(UniversalGoogleMap)
