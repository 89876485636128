import buildRoute from './buildRoute'

export const getManufacturers = async (active) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'manufacturers',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const addManufacturer = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'file',
            route: 'manufacturer',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const archiveManufacturers = async (manufacturers: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'archive-manufacturer',
            data: manufacturers,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deactivateManufacturers = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'manufacturer-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const deleteManufacturer = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'manufacturer-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    manufacturers: []
    activate: boolean
}

const manufacturerRoutes = {
    getManufacturers,
    addManufacturer,
    deleteManufacturer,
    deactivateManufacturers,
}

export default manufacturerRoutes
