import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js'
import { Pie, Bar, Doughnut } from 'react-chartjs-2'
ChartJS.register(ArcElement, Legend, Tooltip)

export const SleepingPieChart = ({ telemetry }) => {
    const data = [
        {
            label: 'Offline',
            count: telemetry.filter(
                (entry: any) => entry.boatStatus === 'OFFLINE'
            ).length,
            hins: telemetry
                .filter((entry: any) => entry.boatStatus === 'OFFLINE')
                .map((entry: any) => entry.hin),
        },
        {
            label: 'Online',
            count: telemetry.filter(
                (entry: any) => entry.boatStatus === 'ONLINE'
            ).length,
            hins: telemetry
                .filter((entry: any) => entry.boatStatus === 'ONLINE')
                .map((entry: any) => entry.hin),
        },
        {
            label: 'Ignition',
            count: telemetry.filter(
                (entry: any) => entry.boatStatus === 'IGNITION'
            ).length,
            hins: telemetry
                .filter((entry: any) => entry.boatStatus === 'IGNITION')
                .map((entry: any) => entry.hin),
        },
        {
            label: 'Running',
            count: telemetry.filter(
                (entry: any) => entry.boatStatus === 'RUNNING'
            ).length,
            hins: telemetry
                .filter((entry: any) => entry.boatStatus === 'RUNNING')
                .map((entry: any) => entry.hin),
        },
    ]

    const chartData = {
        labels: data.map((datum) => datum.label),
        datasets: [
            {
                label: '# of Boats',
                data: data.map((datum) => datum.count),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    return (
        <div>
            <Pie data={chartData} />
        </div>
    )
}

export const ModelsBarGraph = ({ telemetry }) => {
    const models = [...new Set(telemetry.map((obj) => obj.model))]
    const modelsData = models.map((model) => ({
        label: model,
        count: telemetry.filter((obj: any) => obj.model === model).length,
        hins: telemetry
            .filter((obj: any) => obj.model === model)
            .map((entry) => entry.hin),
    }))

    const borderColor = [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)',
    ]

    const backgroundColor = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)',
    ]

    const modelsChartData = {
        labels: modelsData.map((datum) => datum.label),
        datasets: [
            {
                label: 'Boats by Model',
                data: modelsData.map((datum) => datum.count),
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1,
            },
        ],
    }

    return (
        <div>
            <Doughnut data={modelsChartData} />
        </div>
    )
}
export const ManufacturersChart = ({ telemetry }) => {
    const manufacturers = [
        ...new Set(
            telemetry.map((obj) => obj._doc.manufacturer.manufacturerName)
        ),
    ]
    const manufacturersData = manufacturers.map((manufacturer) => ({
        label: manufacturer,
        count: telemetry.filter(
            (obj: any) =>
                obj._doc.manufacturer.manufacturerName === manufacturer
        ).length,
        hins: telemetry
            .filter(
                (obj: any) =>
                    obj._doc.manufacturer.manufacturerName === manufacturer
            )
            .map((entry) => entry.hin),
    }))

    const borderColor = [
        'rgb(127,255,0)',
        'rgb(32,178,170)',
        'rgb(220,20,60)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)',
    ]

    const backgroundColor = [
        // Osmosis
        'rgba(127,255,0, 0.2)',
        //Kyma
        'rgba(32,178,170, 0.2)',
        // Nautique
        'rgba(220,20,60, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)',
    ]

    const manufacturersChartData = {
        labels: manufacturersData.map((datum) => datum.label),
        datasets: [
            {
                label: 'Boats by Manufacturer',
                data: manufacturersData.map((datum) => datum.count),
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1,
            },
        ],
    }

    return (
        <div>
            <Doughnut data={manufacturersChartData} />
        </div>
    )
}

export const EngineHoursHistogram = ({ telemetry }) => {
    const labels = ['0-10', '10-50', '50-100', '100+']
    const data = [
        telemetry
            .filter((obj: any) => obj.engineHours)
            .filter((entry: any) => Math.floor(entry.engineHours) < 10).length,
        telemetry
            .filter((obj: any) => obj.engineHours)
            .filter(
                (entry: any) =>
                    Math.floor(entry.engineHours) >= 10 &&
                    Math.floor(entry.engineHours) < 50
            ).length,
        telemetry
            .filter((obj: any) => obj.engineHours)
            .filter(
                (entry: any) =>
                    Math.floor(entry.engineHours) >= 50 &&
                    Math.floor(entry.engineHours) < 100
            ).length,
        telemetry
            .filter((obj: any) => obj.engineHours)
            .filter((entry: any) => Math.floor(entry.engineHours) >= 100)
            .length,
    ]

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: '# of Boats by Engine Hours',
                data: data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    return (
        <div>
            <Bar data={chartData} />
        </div>
    )
}
