import { useReducer, useRef, useEffect } from 'react'
import { useSocket } from '../../context/SocketContext'
import {
    FormSection,
    UniversalDataTable,
    UniversalGoogleMap,
    UniversalModal,
} from '../UniversalComponents'
import { Grid, Button } from '@mui/material'

import {
    SleepingPieChart,
    ModelsBarGraph,
    ManufacturersChart,
} from './ChartJsGraphics'
import { useAuth } from '../../context/AuthenticationContext'
import Manufacturer from '../Admin/Manufacturers'
import BoatConfigurationInsights from '../Admin/Boats/BoatConfiguration/BoatConfigInsights'

const Home = () => {
    const defaultState = {
        telemetry: [],
        fleetGps: [],
        fleetTable: [],
        openBoatInsights: false,
        row: {},
        histogramVisible: false,
    }
    const currentUser = useAuth()
    const { activeManufacturer, user, userRole } = currentUser.auth
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const mapFleetTelemetry = (telemetry: any) =>
        telemetry
            .map((item) => {
                if (
                    item &&
                    item.redisObj &&
                    item.redisObj.GPS_Latitude &&
                    item.redisObj.GPS_Longitude
                ) {
                    return {
                        lat: parseFloat(item.redisObj.GPS_Latitude),
                        lng: parseFloat(item.redisObj.GPS_Longitude),
                        name: item._doc.fullHin,
                    }
                }
            })
            .filter((item) => item !== undefined)
    const mapFleetTableTelemetry = (telemetry: any) =>
        telemetry
            .map((item) => ({
                id: item._doc._id,
                fullHin: item._doc.fullHin,
                nickname: item._doc.nickname,
                manufacturer: item._doc.manufacturer.manufacturerName,
                status: item.redisObj._boatStatus || 'NOT CONNECTED',
                modelImageUrl: item._doc.boatModel?.modelImageUrl,
                modelYear: item._doc.boatModel?.modelYear,
                model: item._doc.boatModel?.model,
                profileName: item._doc.boatModel?.signalProfile?.profileName,

                lastConnected: new Date(
                    item.redisObj._TS_Last_Logged
                ).toDateString(),
            }))
            .filter((item) => item !== undefined)

    const {
        telemetry,
        fleetGps,
        fleetTable,
        openBoatInsights,
        row,
        histogramVisible,
    } = state

    const { socket } = useSocket()

    const refreshCount = useRef(0)
    const interval = useRef<any>()

    useEffect(() => {
        socket.emit('allFleetLiveData', { user })
        socket.on('allFleetLiveDataRes', (data) => {
            if (data) {
                const filteredTelemetry = data.filter(
                    (obj: any) => obj.redisObj !== 'noRedisObj'
                )
                setState({
                    telemetry: filteredTelemetry,
                    fleetGps: mapFleetTelemetry(filteredTelemetry),
                    fleetTable: mapFleetTableTelemetry(filteredTelemetry),
                })
            }
            refreshCount.current = refreshCount.current + 1
            return refreshCount
        })

        return () => socket.emit('leave:room', '')
    }, [])

    useEffect(() => {
        socket.emit('allFleetLiveData', { user })
        socket.on('allFleetLiveDataRes', (data) => {
            if (data) {
                const filteredTelemetry = data.filter(
                    (obj: any) => obj.redisObj !== 'noRedisObj'
                )
                setState({
                    telemetry: filteredTelemetry,
                    fleetGps: mapFleetTelemetry(filteredTelemetry),
                    fleetTable: mapFleetTableTelemetry(filteredTelemetry),
                })
            }
            refreshCount.current = refreshCount.current + 1
            return refreshCount
        })

        return () => socket.emit('leave:room', '')
    }, [activeManufacturer])

    useEffect(() => {
        interval.current = setInterval(() => {
            socket.emit('allFleetLiveData', { user })
            socket.on('allFleetLiveDataRes', (data) => {
                if (data) {
                    const filteredTelemetry = data.filter(
                        (obj: any) => obj.redisObj !== 'noRedisObj'
                    )
                    setState({
                        telemetry: filteredTelemetry,
                        fleetGps: mapFleetTelemetry(filteredTelemetry),
                        fleetTable: mapFleetTableTelemetry(filteredTelemetry),
                    })
                }
                refreshCount.current = refreshCount.current + 1
                return refreshCount
            })
        }, 10000)

        return () => {
            clearInterval(interval.current)
        }
    }, [interval, activeManufacturer])
    const style = {
        width: '100%',
        height: '600px',
        backgroundColor: 'inherit',
    }
    const visibleColumns = [
        'fullHin',
        'nickname',
        'manufacturer',
        'status',
        'lastConnected',
        'boatModel',
    ]
    const customColumnNames = [
        'HIN',
        'Boat Nickname',
        'Manufacturer',
        'Status',
        'Last Connected',
    ]

    return (
        <Grid
            container
            spacing={2}
            sx={{ marginTop: 10, marginLeft: 1, marginRight: 3 }}
        >
            <Grid item xs={12} md={8}>
                <Button
                    href="/analytics/fleet-analytics"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{
                        height: 55,
                        marginBottom: 15,
                    }}
                >
                    Show Fleet Histogram
                </Button>
                <FormSection title="Live Fleet Map View">
                    <Grid item xs={12}>
                        <UniversalGoogleMap
                            fleetGps={fleetGps}
                            style={style}
                            mapZoom={6}
                        />
                    </Grid>
                </FormSection>
                <FormSection title="Click on a boat in the table below to view insights">
                    <Grid item xs={12}>
                        <UniversalDataTable
                            data={fleetTable}
                            visibleColumns={visibleColumns}
                            customColumnNames={customColumnNames}
                            onRowSelectionModelChange={(newSelection) => {
                                setState({
                                    activateData: newSelection,
                                })
                            }}
                            checkboxSelection={false}
                            getRowData={(tableRow) =>
                                // setModalVisbility(tableRow, undefined)
                                setState({
                                    openBoatInsights: true,
                                    row: tableRow,
                                })
                            }
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} md={4}>
                <FormSection title="Live Fleet Status">
                    <Grid item xs={12} md={12}>
                        {telemetry && (
                            <SleepingPieChart
                                telemetry={telemetry.map((boat: any) => ({
                                    hin: boat._doc.fullHin,
                                    boatStatus: boat.redisObj._boatStatus,
                                }))}
                            />
                        )}
                    </Grid>
                </FormSection>
                <FormSection title="Live Fleet Models">
                    <Grid item xs={12} md={12}>
                        {telemetry && (
                            <ModelsBarGraph
                                telemetry={telemetry.map((boat: any) => ({
                                    hin: boat._doc.fullHin,
                                    model: boat._doc.boatModel.model,
                                }))}
                            />
                        )}
                    </Grid>
                </FormSection>
                {userRole === 'Super Admin' && (
                    <FormSection title="Live Fleet Manufacturer Count">
                        <Grid item xs={12} md={12}>
                            {telemetry && (
                                <ManufacturersChart telemetry={telemetry} />
                            )}
                        </Grid>
                    </FormSection>
                )}
            </Grid>

            {openBoatInsights === true && (
                <UniversalModal
                    title={`${row.fullHin} Live Data Insights`}
                    open={openBoatInsights}
                    onClose={() => {
                        setState({
                            openBoatInsights: false,
                            row: defaultState.row,
                        })
                        clearInterval(interval.current)
                        interval.current = undefined
                        return interval.current
                    }}
                    wrapperStyle={{
                        margin: 'auto',
                        width: '90%',
                        marginTop: 10,
                        marginBottom: 5,
                    }}
                >
                    <BoatConfigurationInsights
                        row={row}
                        openEdit={() =>
                            setState({
                                open: true,
                                openBoatInsights: false,
                            })
                        }
                        interval={interval}
                        editBoat={false}
                    />
                </UniversalModal>
            )}
        </Grid>
    )
}

export default Home
