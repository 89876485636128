import {
    Autocomplete,
    Button,
    Switch,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    Paper,
    FormControl,
    FormHelperText,
    Box,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useReducer, useEffect, useContext } from 'react'
import {
    addBoat,
    addTag,
    getEngines,
    getManufacturers,
    unlinkTag,
} from '../../../../../apiRoutes'
import { datePickerConverter } from '../../../../../utilities/functionTools'
import { getDevices } from '../../../../../apiRoutes'
import { getDealers } from '../../../../../apiRoutes'
import { ToastMessage } from '../../../../../context/ToastPopUpContext'
import { getUsers } from '../../../../../apiRoutes'
import { getBoatModels } from '../../../../../apiRoutes'
import {
    FormSection,
    SaveButton,
    UniversalTagToolbar,
    Autocomplete as UniversalAutocomplete,
} from '../../../../UniversalComponents'
import responseError from '../../../../../context/responseError/responseError'
import { useSocket } from '../../../../../context/SocketContext'

const BoatConfigurationForm = ({
    row,
    formDescription,
    onClose,
    saveCache,
    permissionCheck,

    userRole,
}) => {
    const setToastMessage = useContext(ToastMessage)
    const defaultState = {
        formData: {
            shortHin: row.shortHin || '',
            fullHin: row.fullHin || '',
            nickname: row?.nickname || '',
            engineIdentifier: row.engineIdentifier || '',
            device: row.deviceSerial || '',
            ownerEmail: row.ownerEmail || '',
            productionStartDate: datePickerConverter(row.productionStartDate),
            shipDate: datePickerConverter(row.shipDate),
            deliveryDate: datePickerConverter(row.deliveryDate),
            gpsEnabled: row.id ? row.gpsEnabled : true,
            dealerVisibility: row.id ? row.dealerVisibility : true,
            salesStatus: row.salesStatus || '',
            boatModel: row.model ? `${row.modelYear} - ${row.model}` : '',
            dealer: row.dealerName || '',
            id: row.id || '',
            engine: row.engineid || '',
            updatedBy: row.updatedBy || '',
            manufacturer: row?.manufacturer?.manufacturerName,
            engineName: row.engineName || '',
            engineSerial: row.engineSerial || '',
            tags: row.tags || [],
        },
        formValidation: {
            fullHinValidation: false,
            shortHinValidation: false,
            manufacturerValidation: false,
            boatModelValidation: false,
            deviceValidation: false,
            engineIdentifierValidation: false,
            dealerValidation: false,
            isFormDirty: false,
            isFormValid: false,
            nicknameValidation: false,
        },

        ownerName: '',
        engineManufacturerName: row.enginemanufacturerName || '',
        boatManufacturers: [],
        engines: [],
        boatModels: [],
        dealers: [],
        users: [],
        devices: [],
        boatManufacturerOptions: [],
        permissionsCheck: permissionCheck,
        deviceOptions: [],
        boatModelOptions: [],
        dealerOptions: [],
        userOptions: [],
        engineOptions: [],
        fullHinCharacterCount: '',
        entryId: undefined,
        successfulSubmission: false,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        formValidation,
        devices,
        dealers,
        users,
        boatManufacturers,
        engines,
        boatModels,
        permissionsCheck,
        engineManufacturerName,
        dealerName,
        ownerName,
        deviceSerial,
        model,
        boatManufacturerName,
        dealerOptions,
        deviceOptions,
        boatModelOptions,
        userOptions,
        boatManufacturerOptions,
        engineOptions,
        fullHinCharacterCount,
        entryId,
        successfulSubmission,
    } = state

    const {
        fullHin,
        shortHin,
        nickname,
        engineIdentifier,
        device,
        ownerEmail,
        productionStartDate,
        shipDate,
        deliveryDate,
        gpsEnabled,
        dealerVisibility,
        boatModel,
        dealer,
        updatedBy,
        engine,
        manufacturer,
        engineName,
        engineSerial,
        tags,
    } = formData

    const {
        fullHinValidation,
        shortHinValidation,
        manufacturerValidation,
        boatModelValidation,
        deviceValidation,
        isFormDirty,
        isFormValid,
        engineNameValidation,
        dealerValidation,
        nicknameValidation,
    } = formValidation

    const handleChange = (e: any) => {
        const { name, value } = e.target

        if (name === 'manufacturer') {
            setState({
                formData: {
                    ...formData,
                    [name]: value ? value : '',
                    boatModel: '',
                },
            })
            return saveCache({
                formData: {
                    ...formData,
                    [name]: value ? value : '',
                    boatModel: '',
                },
            })
        } else if (name === 'engineName') {
            const findEngineData =
                engines.length !== 0 &&
                engines?.find((engine) => engine.engineName === value)
            if (findEngineData) {
                setState({
                    formData: {
                        ...formData,
                        engineIdentifier: findEngineData?.engineIdentifier,
                        [name]: value,
                    },
                    engineManufacturerName:
                        findEngineData?.manufacturer?.manufacturerName,
                })

                const updatedFormData = {
                    formData: {
                        ...formData,
                        engineIdentifier: findEngineData?.engineIdentifier,
                        [name]: value,
                    },

                    engineManufacturerName:
                        findEngineData?.manufacturer?.manufacturerName,
                }

                return saveCache(updatedFormData)
            }
        } else if (name === 'ownerEmail') {
            const selectedOwner = users.find(
                (owner) => owner.userEmail === value
            )

            const updatedFormData = {
                formData: {
                    ...formData,
                    ownerEmail: value,
                },

                ownerName: selectedOwner
                    ? `${selectedOwner.userFirstName} ${selectedOwner.userLastName}`
                    : '',
            }

            setState({
                formData: {
                    ...formData,
                    ownerEmail: value,
                },
                ownerName: selectedOwner
                    ? `${selectedOwner.userFirstName} ${selectedOwner.userLastName}`
                    : '',
            })

            return saveCache(updatedFormData)
        } else if (name === 'fullHin') {
            const characterCount = value
            setState({
                fullHinCharacterCount: characterCount,
                formData: { ...formData, [name]: value },
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
                [name]: value ? value : '',
            })
            return saveCache({
                formData: { ...formData, [name]: value },
                [name]: value ? value : '',
            })
        }
    }

    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (name === 'fullHin') {
            if (value && value.length === 14) {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: false,
                        isFormDirty: true,
                    },

                    formData: {
                        ...formData,
                        shortHin: `${value.substring(5, 10)}`,
                    },
                })
            } else {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: true,
                        isFormDirty: true,
                    },

                    formData: {
                        ...formData,
                        shortHin: '',
                    },
                })
            }
        } else {
            if (value !== '') {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: false,
                        isFormDirty: true,
                    },
                })
            } else {
                setState({
                    formValidation: {
                        ...formValidation,
                        [name + 'Validation']: true,
                        isFormDirty: true,
                    },
                })
            }
        }
    }

    const handleTagSubmit = async (tagData, removedTags) => {
        try {
            if (successfulSubmission) {
                const tag = await addTag({ tagData, entryId })

                if (tag) {
                    const { data, status } = tag

                    if (data && status === 200) {
                        console.log('Tag updated successfully')
                    } else {
                        responseError(tag, row)
                    }
                }

                if (removedTags && removedTags.length > 1) {
                    const removeTags: any = await unlinkTag({ removedTags })

                    if (removeTags) {
                        const { data, status } = removeTags

                        if (data && status === 200) {
                            console.log('Tag removed successfully')
                        } else {
                            responseError(removeTags, row)
                        }
                    }
                }
                setState({ successfulSubmission: false })
                onClose(row.fullHin, row)
            }
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleSubmit = async () => {
        try {
            const findManufacturer = boatManufacturers.find(
                ({ manufacturerName }) => manufacturerName === manufacturer
            )

            const findBoatModel = boatModels.find(
                (boat) => `${boat.modelYear} - ${boat.model}` === boatModel
            )

            const findDevice = devices.find(
                ({ deviceSerial }) => deviceSerial === device
            )

            const findEngine = engines.find(
                ({ engineName }) => engineName === formData.engineName
            )

            const findDealer = dealers.find(
                ({ dealerName }) => dealerName === dealer
            )

            const parsedFormData = {
                ...formData,
                manufacturer: findManufacturer,
                device: findDevice,
                boatModel: findBoatModel,
                engine: findEngine,
                dealer: findDealer,
            }

            let res: any

            console.log('Submitting data new:', parsedFormData)
            res = await addBoat(parsedFormData)

            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `Boat: ${data.fullHin} was ${
                            row.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )

                    setState({ entryId: data?._id, successfulSubmission: true })
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const getData = async () => {
        let devices: any = []
        let deviceOptions: any = []
        let engines: any = []
        let engineOptions: any = []
        let dealers: any = []
        let dealerOptions: any = []
        let users: any = []
        let userOptions: any = []
        let boatManufacturers: any = []
        let boatManufacturerOptions: any = []
        let boatModels: any = []
        let boatModelOptions: any = []

        try {
            const getDeviceData = await getDevices(true)
            const getEngineData = await getEngines(true)
            const getDealerData = await getDealers(true)
            const getUserData = await getUsers(true)
            const getBoatManufacturerData = await getManufacturers(true)
            const getBoatModelData = await getBoatModels(true)

            if (getDeviceData && getDeviceData.length !== 0) {
                devices = getDeviceData.map((device) => device)

                deviceOptions = getDeviceData
                    .filter((device) => !device.boat)
                    .map((device) => device?.deviceSerial)
            }

            if (getEngineData && getEngineData.length !== 0) {
                engines = getEngineData
                engineOptions = engines.map((engine) => engine.engineName)
            }

            if (getDealerData && getDealerData.length !== 0) {
                dealers = getDealerData
                dealerOptions = dealers.map((dealer) => dealer?.dealerName)
            }

            if (getUserData && getUserData.length !== 0) {
                users = getUserData
                userOptions = users.map((user) => user.userEmail)
            }

            if (
                getBoatManufacturerData &&
                getBoatManufacturerData.length !== 0
            ) {
                boatManufacturers = getBoatManufacturerData.filter(
                    (manufacturer) => manufacturer.manufacturerType === 'boat'
                )
                boatManufacturerOptions = boatManufacturers.map(
                    ({ manufacturerName }) => manufacturerName
                )
            }

            if (getBoatModelData && getBoatModelData.length !== 0) {
                boatModels = getBoatModelData
                if (userRole === 'Super Admin') {
                    const findManufacturer = boatManufacturers.find(
                        ({ manufacturerName }) =>
                            manufacturerName === manufacturer
                    )

                    const filteredModels = boatModels
                        .filter(
                            (model) =>
                                model?.manufacturer._id ===
                                findManufacturer?._id
                        )
                        .sort((a, b) => b.modelYear - a.modelYear)
                        .map((model) => `${model.modelYear} - ${model.model}`)
                    boatModelOptions = filteredModels
                } else {
                    boatModelOptions = getBoatModelData
                        .sort((a, b) => b.modelYear - a.modelYear)
                        .map((model) => `${model.modelYear} - ${model.model}`)
                }
            }

            setState({
                devices,
                deviceOptions,
                engines,
                engineOptions,
                dealers,
                dealerOptions,
                users,
                userOptions,
                boatManufacturers,
                boatManufacturerOptions,
                boatModels,
                boatModelOptions,
            })
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    useEffect(() => {
        getData()
        saveCache(row)
    }, [])

    const filterModelsByManufacturerId = (manufacturer) => {
        if (manufacturer) {
            const findManufacturer = boatManufacturers.find(
                ({ manufacturerName }) => manufacturerName === manufacturer
            )

            const filteredModels = boatModels
                .filter(
                    (model) => model?.manufacturer._id === findManufacturer?._id
                )
                .sort((a, b) => b.modelYear - a.modelYear)
                .map((model) => `${model.modelYear} - ${model.model}`)

            return filteredModels
        } else {
            return []
        }
    }

    useEffect(() => {
        if (users && users.length !== 0) {
            const selectedOwner = users.find(
                (owner) => owner.userEmail === ownerEmail
            )

            if (selectedOwner) {
                const { userFirstName, userLastName } = selectedOwner
                const fullName = `${userFirstName} ${userLastName}`
                setState({
                    ownerName: fullName,
                })
            }
        }
    }, [ownerEmail, users])

    useEffect(() => {
        const findBoatModels = filterModelsByManufacturerId(manufacturer)

        if (findBoatModels) {
            setState({ boatModelOptions: findBoatModels })
        }
    }, [manufacturer])

    useEffect(() => {
        if (
            isFormDirty &&
            !fullHinValidation &&
            !shortHinValidation &&
            !manufacturerValidation &&
            !boatModelValidation &&
            !nicknameValidation &&
            !deviceValidation &&
            !engineNameValidation &&
            !dealerValidation &&
            fullHin &&
            shortHin &&
            manufacturer &&
            boatModel &&
            nickname &&
            device &&
            engineName &&
            dealer
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        fullHinValidation,
        shortHinValidation,
        manufacturerValidation,
        boatModelValidation,
        nicknameValidation,
        deviceValidation,
        engineNameValidation,
        dealerValidation,
        fullHin,
        shortHin,
        manufacturer,
        boatModel,
        nickname,
        device,
        engineName,
        dealer,
    ])

    const handleReset = () => {
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
            engineManufacturerName: defaultState.engineManufacturerName,
            fullHinCharacterCount: defaultState.fullHinCharacterCount,
        })
    }

    const handleDateReset = () => {
        setState({
            ...state,
            formData: {
                ...formData,
                shipDate: defaultState.formData.shipDate,
                deliveryDate: defaultState.formData.deliveryDate,
                productionStartDate: defaultState.formData.productionStartDate,
            },
        })
    }

    return (
        <Grid container spacing={2} sx={{ marginTop: 8 }}>
            <Grid item xs={12} sx={{ marginBottom: 10 }}>
                <UniversalTagToolbar
                    row={row}
                    pageType="boats"
                    entryId={entryId}
                    addTag={handleTagSubmit}
                    setFormDirty={() =>
                        setState({
                            formValidation: {
                                ...formValidation,
                                isFormValid: true,
                            },
                        })
                    }
                />
            </Grid>
            {
                /// Left side of Grid
            }
            <Grid item xs={12} md={9}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <FormSection title="Boat Info">
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        required={true}
                                        fullWidth
                                        name="fullHin"
                                        label="HIN"
                                        disabled={
                                            !!row.fullHin && permissionsCheck
                                        }
                                        value={fullHin}
                                        onChange={handleChange}
                                        onBlur={validateOnBlur}
                                        error={
                                            fullHinValidation &&
                                            fullHin.length !== 14
                                        }
                                        helperText={
                                            fullHinValidation &&
                                            fullHin.length !== 14
                                                ? 'Full HIN must be 14 characters long.'
                                                : ''
                                        }
                                        inputProps={{ maxLength: 14 }}
                                    />
                                    <Box>
                                        <Typography
                                            sx={{
                                                marginTop: 0.5,
                                                display: fullHinCharacterCount
                                                    ? ''
                                                    : 'none',
                                            }}
                                            fontSize={'small'}
                                            color={
                                                fullHinCharacterCount.length ===
                                                14
                                                    ? 'primary'
                                                    : '#AEAEAE'
                                            }
                                        >
                                            {`${
                                                fullHinCharacterCount.length
                                            } / ${14} Characters`}
                                        </Typography>
                                    </Box>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    name="shortHin"
                                    label="Short Hin"
                                    onBlur={validateOnBlur}
                                    disabled
                                    value={shortHin}
                                    error={shortHinValidation && !shortHin}
                                    helperText={
                                        shortHinValidation && !shortHin
                                            ? 'Please enter a enter a full Hin to populate the short Hin field.'
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required={true}
                                    fullWidth
                                    onChange={handleChange}
                                    onBlur={validateOnBlur}
                                    name="nickname"
                                    label="Boat Nickname"
                                    value={nickname}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <UniversalAutocomplete
                                    options={boatManufacturerOptions}
                                    value={manufacturer}
                                    handleChange={handleChange}
                                    required={true}
                                    onBlur={validateOnBlur}
                                    label="Boat Manufacturer"
                                    name="manufacturer"
                                    error={
                                        manufacturerValidation && !manufacturer
                                    }
                                    helperText={
                                        manufacturer && !manufacturer
                                            ? 'Please select a Enter A Manufacturer Name.'
                                            : ''
                                    }
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <UniversalAutocomplete
                                    onBlur={validateOnBlur}
                                    options={boatModelOptions}
                                    handleChange={handleChange}
                                    value={boatModel}
                                    fullWidth
                                    label="Boat Model"
                                    name="boatModel"
                                    error={boatModelValidation && !boatModel}
                                    helperText={
                                        boatModelValidation && !boatModel
                                            ? 'Please enter a select a Boat Model.'
                                            : ''
                                    }
                                    disabled={permissionsCheck}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <UniversalAutocomplete
                                    options={deviceOptions}
                                    value={device}
                                    handleChange={handleChange}
                                    required={true}
                                    onBlur={validateOnBlur}
                                    label="Device Serial"
                                    name="device"
                                    error={deviceValidation && !device}
                                    helperText={
                                        deviceValidation && !device
                                            ? 'Please select a Device Serial.'
                                            : ''
                                    }
                                    disabled={permissionsCheck}
                                />
                            </Grid>
                        </FormSection>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                    <Paper
                        sx={{
                            padding: 3,
                        }}
                        elevation={4}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ marginTop: -1 }}>
                                <Typography
                                    align="left"
                                    variant="h5"
                                    color={'primary'}
                                >
                                    Engine Info
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="engineManufacturer"
                                    name="engineManufacturer"
                                    label="Engine Manufacturer"
                                    value={engineManufacturerName}
                                    onChange={handleChange}
                                    onBlur={validateOnBlur}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="engineIdentifier"
                                    onBlur={validateOnBlur}
                                    onChange={handleChange}
                                    value={engineIdentifier}
                                    name="engineIdentifier"
                                    label="Engine Identifier"
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <UniversalAutocomplete
                                    fullWidth
                                    id="engineName"
                                    name="engineName"
                                    label="Engine"
                                    value={engineName}
                                    onBlur={validateOnBlur}
                                    options={engineOptions}
                                    handleChange={handleChange}
                                    clearFunction={() =>
                                        setState({
                                            formData: {
                                                ...formData,
                                                engineName: '',
                                                engineIdentifier: '',
                                            },
                                            engineManufacturerName: '',
                                        })
                                    }
                                    error={engineNameValidation && !engineName}
                                    helperText={
                                        engineNameValidation && !engineName
                                            ? 'Please Provide an Engine Name.'
                                            : ''
                                    }
                                    disabled={permissionsCheck}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="engineSerial"
                                    onBlur={validateOnBlur}
                                    onChange={handleChange}
                                    value={engineSerial}
                                    name="engineSerial"
                                    label="Engine Serial"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormSection title="Dealer Info">
                                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                    <UniversalAutocomplete
                                        onBlur={validateOnBlur}
                                        label="Dealer Name"
                                        options={dealerOptions}
                                        handleChange={handleChange}
                                        value={dealer}
                                        name="dealer"
                                        required
                                        disabled={permissionsCheck}
                                        error={dealerValidation && !dealer}
                                        helperText={
                                            dealerValidation && !dealer
                                                ? 'Please Provide a Dealer Name.'
                                                : ''
                                        }
                                        fullWidth
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={8} sx={{ marginBottom: 2 }}>
                            <FormSection title="Owner Info">
                                <Grid item xs={7}>
                                    <UniversalAutocomplete
                                        onBlur={validateOnBlur}
                                        options={userOptions}
                                        handleChange={handleChange}
                                        name="ownerEmail"
                                        label="Owner Email"
                                        value={ownerEmail}
                                        fullWidth
                                        disabled={permissionsCheck}
                                    />
                                </Grid>
                                <Grid item xs={5} sx={{ marginBottom: 2 }}>
                                    <TextField
                                        id="ownerName"
                                        fullWidth
                                        name="ownerName"
                                        onBlur={validateOnBlur}
                                        label="Owner Name"
                                        value={ownerName}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
                <Grid container sx={{ marginBottom: 1 }}>
                    <FormSection>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{ marginTop: -1 }}>
                                <Typography
                                    align="left"
                                    variant="h5"
                                    color={'primary'}
                                >
                                    GPS Info
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() =>
                                                setState({
                                                    formData: {
                                                        ...formData,
                                                        gpsEnabled: !gpsEnabled,
                                                    },
                                                    formValidation: {
                                                        ...formValidation,
                                                        isFormDirty: true,
                                                    },
                                                })
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                            id="switch.gpsEnabled"
                                            name="switch.gpsEnabled"
                                            checked={gpsEnabled}
                                            key="switch.gpsEnabled"
                                        />
                                    }
                                    label="GPS Enabled"
                                    key="switch.gpsEnabled"
                                    disabled={permissionsCheck}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            onChange={() =>
                                                setState({
                                                    formData: {
                                                        ...formData,
                                                        dealerVisibility:
                                                            !dealerVisibility,
                                                    },
                                                    formValidation: {
                                                        ...formValidation,
                                                        isFormDirty: true,
                                                    },
                                                })
                                            }
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                            id="switch.dealerVisibility"
                                            name="switch.dealerVisibilty"
                                            checked={dealerVisibility}
                                            key="switch.dealerVisibilty"
                                        />
                                    }
                                    label="Boat Visible to Dealer"
                                    key="switch.dealerVisibilty"
                                    disabled={permissionsCheck}
                                />
                            </Grid>
                        </Grid>
                    </FormSection>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                    <FormSection>
                        <Grid container spacing={2} sx={{ marginBottom: 0.5 }}>
                            <Grid item xs={12}>
                                <Typography
                                    align="left"
                                    variant="h5"
                                    color={'primary'}
                                >
                                    Sales Info
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label="Production Start Date"
                                        views={['year', 'month', 'day']}
                                        sx={{ width: '100%' }}
                                        value={productionStartDate}
                                        onChange={({ $d }) => {
                                            const event = {
                                                target: {
                                                    value: $d,
                                                    name: 'productionStartDate',
                                                },
                                            }
                                            setState({
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                            handleChange(event)
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: false,
                                            },
                                        }}
                                        disabled={permissionsCheck}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label="Ship Date"
                                        views={['year', 'month', 'day']}
                                        sx={{ width: '100%' }}
                                        value={shipDate}
                                        onChange={({ $d }) => {
                                            const event = {
                                                target: {
                                                    value: $d,
                                                    name: 'shipDate',
                                                },
                                            }
                                            setState({
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                            handleChange(event)
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: false,
                                            },
                                        }}
                                        disabled={permissionsCheck}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        label="Retail Delivery Date"
                                        views={['year', 'month', 'day']}
                                        sx={{ width: '100%' }}
                                        value={deliveryDate}
                                        onChange={({ $d }) => {
                                            const event = {
                                                target: {
                                                    value: $d,
                                                    name: 'deliveryDate',
                                                },
                                            }
                                            setState({
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                            handleChange(event)
                                        }}
                                        slotProps={{
                                            textField: {
                                                error: false,
                                            },
                                        }}
                                        disabled={permissionsCheck}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleDateReset}
                                    disabled={permissionsCheck}
                                    sx={{
                                        width: '100%',
                                        height: 55,
                                    }}
                                >
                                    Reset Sales Info
                                </Button>
                            </Grid>
                        </Grid>
                    </FormSection>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: -2 }}>
                <Grid item xs={12} md={12} sx={{ marginLeft: 2 }}>
                    <SaveButton
                        title="Required Fields Are Marked With An *"
                        manufacturer={row.manufacturerName}
                        titleAlignment="center"
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        disabledSave={!isFormValid || permissionCheck}
                        disabledReset={!isFormDirty || permissionCheck}
                        resetVariant="contained"
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BoatConfigurationForm
