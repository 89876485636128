import buildRoute from './buildRoute'

export const getUserProfiles = async (active) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'user-profiles',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

export const editUserProfile = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'user-profile-edit',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        return console.error(err)
    }
}

interface SelectionType {
    users: []
    activate: boolean
}

export const activateUserProfiles = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'user-profiles-activations',
            data: selection,
        })
        if (res.status) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}
