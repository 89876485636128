import {
    Autocomplete,
    Button,
    Switch,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    Paper,
    Stack,
    IconButton,
    Tooltip,
    Chip,
} from '@mui/material'
import { Delete as DeleteIcon, FilePresent } from '@mui/icons-material/'
import { useReducer, useEffect, useContext, useRef } from 'react'
import {
    addEvent,
    getSignals,
    getUsers,
    getUserNotificationSubscriptions,
} from '../../../../apiRoutes'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { SaveButton } from '../../../UniversalComponents'
import { styled } from '@mui/material/styles'
import { FormSection } from '../../../UniversalComponents'
import { Authentication } from '../../../../context/AuthenticationContext'
import responseError from '../../../../context/responseError/responseError'
import { basicValidator } from '../../../../utilities/functionTools'
import { UniversalAutoComplete } from '../../../UniversalComponents/UniversalFormStyles'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: 5,
}))

const EventManagerForm = ({
    row,
    formDescription,
    onClose,
    saveCache,
    permissionCheck,
}) => {
    const setToastMessage = useContext(ToastMessage)

    const defaultState = {
        formData: {
            id: row.id || '',
            notificationName: row.notificationName || '',
            notifyWeb: row.id ? row.notifyWeb : true,
            notifyEmail: row.id ? row.notifyEmail : false,
            notifyEmailList:
                row.notifyEmailList && row.notifyEmailList?.length !== 0
                    ? row.notifyEmailList
                    : [],
            notifySubscriptionList:
                row.notifySubscriptionList &&
                row.notifySubscriptionList?.length !== 0
                    ? row.notifySubscriptionList
                    : [],
            notificationFrequency: row.notificationFrequency || 'Weekly',
            notificationConditions: row.notificationConditions || [],
            signal: row.signalFriendlyName || '',
        },

        formValidation: {
            notificationNameValidation: false,
            signalValidation: false,
            isFormValid: false,
            isFormDirty: false,
            notifyEmailListValidaton: true,
        },

        closeModal: false,
        defaultValue: '',
        setDefaultValue: '',
        setInputValue: '',
        inputValue: '',
        measurement: [],
        userEmail: [],
        signals: [],
        signalOptions: [],
        getLogicOptions: [],
        userEmailOptions: [],
        userSubscriptionOptions: [],
        notificationSubscribers: [],
        unitOfMeasurement: row.unitOfMeasurement || '',
        resetConditions: [],
        permissionsCheck: permissionCheck,
    }
    const currentUser = useContext(Authentication)
    const { username } = currentUser.auth
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        formValidation,
        userEmailOptions,
        userSubscriptionOptions,
        notificationSubscribers,
        userEmail,
        signals,
        signalOptions,
        logicDataOptions,
        unitOfMeasurement,
    } = state

    const {
        notificationName,
        notifyWeb,
        notifyEmail,
        notifyEmailList,
        notifySubscriptionList,
        notificationFrequency,
        notificationConditions,
        signal,
    } = formData

    const {
        notificationNameValidation,
        signalValidation,
        isFormValid,
        isFormDirty,
        notifyEmailListValidaton,
    } = formValidation

    const changeRow = (operatorIndex, key, sum) => {
        const clonedConditions = [...notificationConditions]
        clonedConditions[operatorIndex][key] = sum

        return setState({
            formData: {
                ...formData,
                notificationConditions: clonedConditions,
            },
        })
    }

    const setConditions = (sum: any, index?: number) => {
        if (index === undefined) {
            const newCondition = [...notificationConditions, sum]
            return setState({
                formData: { ...formData, notificationConditions: newCondition },
            })
        } else {
            changeRow(index, Object.keys(sum)[0], Object.values(sum)[0])
        }
    }

    const handleDeleteCondition = (index) => {
        const newConditions = [...notificationConditions]
        const filteredConditions = newConditions.filter(
            (item, itemIndex) => itemIndex !== index
        )

        const resetOperation = {
            [`operation${index + 1}`]: '',
        }

        for (let i = index + 1; i <= newConditions.length; i++) {
            resetOperation[`operation${i}`] =
                formData[`operation${i + 1}`] || ''
        }

        setState({
            formData: {
                ...formData,
                notificationConditions: filteredConditions,
                ...resetOperation,
            },
            notificationConditions: filteredConditions,
        })
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target
        if (name === 'signal') {
            const findSignalId =
                signals.length !== 0 &&
                signals?.find((signal) => signal.signalFriendlyName === value)

            if (findSignalId) {
                setState({
                    formValidation: {
                        ...formValidation,
                        isFormDirty: true,
                    },
                    formData: {
                        ...formData,
                        [name]: value,
                    },
                    unitOfMeasurement:
                        findSignalId?.unitOfMeasurement?.unitOfMeasurement,
                })
            }
        } else {
            setState({
                formData: {
                    ...formData,
                    [name]: value,
                },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
            })
        }
    }

    const handleSubmit = async () => {
        try {
            if (!areConditionsValid()) {
                setToastMessage(
                    'Please Complete All Event Condition Details',
                    'error'
                )
                return
            } else {
                const findSignal = signals.find(
                    ({ signalFriendlyName }) => signalFriendlyName === signal
                )
                const parsedFormData = {
                    ...formData,
                    signal: findSignal,
                }

                delete parsedFormData.unitOfMeasurement
                let res: any

                res = await addEvent(parsedFormData)

                if (res) {
                    const { data, status } = res

                    if (data && status === 200) {
                        setToastMessage(
                            `Signal Event: ${data.notificationName} was ${
                                row.id ? 'edited' : 'added'
                            } successfully`,
                            'success'
                        )
                        onClose(row.notificationName, data)
                    } else {
                        responseError(res, row)
                    }
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleReset = () => {
        if (row.notificationName) {
            setState({
                formData: defaultState.formData,
                formValidation: defaultState.formValidation,
                unitOfMeasurement: defaultState.unitOfMeasurement,
            })
        } else {
            setState({
                formData: defaultState.formData,
                formValidation: defaultState.formValidation,
                unitOfMeasurement: defaultState.unitOfMeasurement,
            })
        }
    }

    const areConditionsValid = () => {
        const isConditionValid = notificationConditions.every(
            (notificationConditions) =>
                notificationConditions.logic &&
                notificationConditions.operand &&
                notificationConditions.value
        )
        return isConditionValid
    }

    const operators = ['>', '<', '=', '>=', '<=']
    const logicOptions = ['and', 'or', 'end']
    const frequencies = [
        'Daily',
        'Weekly',
        'On Wake',
        'On Sleep',
        'Immediately',
    ]

    const conditionsLengthValid = () => {
        if (notificationConditions && notificationConditions.length !== 0) {
            return true
        } else {
            return false
        }
    }

    const { current } = useRef(notifyEmailList)

    useEffect(() => {
        if (
            isFormDirty &&
            !notificationNameValidation &&
            !signalValidation &&
            conditionsLengthValid() &&
            areConditionsValid() &&
            notificationName &&
            signal
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        notificationNameValidation,
        signalValidation,
        notificationName,
        signal,
        conditionsLengthValid(),
        areConditionsValid(),
    ])

    const getData = async () => {
        let userEmail: any = []
        let userEmailOptions: any = []
        let signals: any = []
        let signalOptions: any = []

        let userSubscriptionOptions: any = []

        try {
            const getUserEmailData = await getUsers(true)
            const getSignalData = await getSignals(true)
            const getUserNotificationSubscriptionData =
                await getUserNotificationSubscriptions()

            if (getUserEmailData && getUserEmailData.length !== 0) {
                userEmail = getUserEmailData
                userEmailOptions = userEmail.map(({ userEmail }) => userEmail)

                userSubscriptionOptions =
                    getUserNotificationSubscriptionData.map(
                        (userNotification) => ({
                            ...userNotification,
                            id: userNotification._id,
                        })
                    )
            }
            if (getSignalData && getSignalData.length !== 0) {
                signals = getSignalData
                signalOptions = signals.map(
                    ({ signalFriendlyName }) => signalFriendlyName
                )
            }

            setState({
                userEmail,
                userEmailOptions,
                userSubscriptionOptions,
                signals,
                signalOptions,
            })
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    useEffect(() => {
        getData()
        saveCache(row)
    }, [])

    const getLogicOptions = (currentIndex) => {
        if (notificationConditions.length === 1) {
            return ['end']
        } else if (notificationConditions.length === 2) {
            return currentIndex === 1 ? ['end'] : logicOptions
        } else if (notificationConditions.length === 3) {
            return currentIndex === 2 ? ['end'] : logicOptions
        } else {
            return logicOptions
        }
    }

    return (
        <Grid container spacing={2} sx={{ marginTop: 4 }}>
            <Grid container spacing={2}>
                {
                    /// Left side of Grid
                }
                <Grid item xs={12} md={9}>
                    <FormSection title="Event Information">
                        <Grid item xs={12} md={12}>
                            <TextField
                                required={true}
                                fullWidth
                                name="notificationName"
                                label="Notification Name"
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                value={notificationName}
                                onChange={handleChange}
                                error={
                                    notificationNameValidation &&
                                    !notificationName
                                }
                                helperText={
                                    notificationNameValidation &&
                                    !notificationName
                                        ? 'Please enter a enter a notification name.'
                                        : ''
                                }
                                disabled={permissionCheck}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <UniversalAutoComplete
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                options={signalOptions}
                                disabled={permissionCheck}
                                handleChange={handleChange}
                                value={signal}
                                fullWidth
                                error={signalValidation && !signal}
                                helperText={
                                    signalValidation && !signal
                                        ? 'Please select a signal name'
                                        : ''
                                }
                                required
                                label="Signal Name"
                                name="signal"
                            />
                        </Grid>
                    </FormSection>

                    <FormSection title="Event Conditions">
                        <Grid item xs={12}>
                            <Stack>
                                {notificationConditions &&
                                    notificationConditions?.length !== 0 &&
                                    notificationConditions.map(
                                        (condition, index) => {
                                            if (
                                                condition &&
                                                condition.value !== undefined
                                            ) {
                                                return (
                                                    <Item
                                                        key={`${condition.sum}.${index}`}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={1}
                                                            >
                                                                <Tooltip
                                                                    title="Delete Condition"
                                                                    key={`${index}.edit`}
                                                                >
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        onClick={() => {
                                                                            handleDeleteCondition(
                                                                                index
                                                                            )
                                                                            setState(
                                                                                {
                                                                                    formValidation:
                                                                                        {
                                                                                            ...formValidation,
                                                                                            isFormDirty:
                                                                                                true,
                                                                                        },
                                                                                }
                                                                            )
                                                                        }}
                                                                        style={{
                                                                            marginTop: 8,
                                                                        }}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={3}
                                                            >
                                                                <TextField
                                                                    disabled
                                                                    id="unitOfMeasurement"
                                                                    name="unitOfMeasurement"
                                                                    label="Unit of Measurement"
                                                                    value={
                                                                        unitOfMeasurement
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    fullWidth
                                                                />
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={2.3}
                                                            >
                                                                <Autocomplete
                                                                    disablePortal
                                                                    disabled={
                                                                        permissionCheck
                                                                    }
                                                                    id="operand"
                                                                    options={
                                                                        operators
                                                                    }
                                                                    getOptionLabel={(
                                                                        option
                                                                    ) => option}
                                                                    onChange={(
                                                                        event,
                                                                        newValue
                                                                    ) => {
                                                                        const value =
                                                                            {
                                                                                operand:
                                                                                    newValue,
                                                                            }
                                                                        setConditions(
                                                                            value,
                                                                            index
                                                                        )
                                                                    }}
                                                                    value={
                                                                        condition.operand
                                                                    }
                                                                    fullWidth
                                                                    onBlur={(
                                                                        e: any
                                                                    ) =>
                                                                        basicValidator(
                                                                            e,
                                                                            formValidation,
                                                                            setState
                                                                        )
                                                                    }
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            required
                                                                            label="Operand"
                                                                            name="operand"
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            value={
                                                                                condition.operand
                                                                            }
                                                                            disabled={
                                                                                permissionCheck
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={3.3}
                                                            >
                                                                <TextField
                                                                    required
                                                                    id="value"
                                                                    name="value"
                                                                    label="Value"
                                                                    onBlur={(
                                                                        e: any
                                                                    ) =>
                                                                        basicValidator(
                                                                            e,
                                                                            formValidation,
                                                                            setState
                                                                        )
                                                                    }
                                                                    value={
                                                                        condition.value
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setConditions(
                                                                            {
                                                                                value: e
                                                                                    .target
                                                                                    .value,
                                                                            },
                                                                            index
                                                                        )
                                                                    }
                                                                    fullWidth
                                                                    disabled={
                                                                        permissionCheck
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={2.4}
                                                            >
                                                                <Autocomplete
                                                                    disablePortal
                                                                    id="logic"
                                                                    onBlur={(
                                                                        e: any
                                                                    ) =>
                                                                        basicValidator(
                                                                            e,
                                                                            formValidation,
                                                                            setState
                                                                        )
                                                                    }
                                                                    options={getLogicOptions(
                                                                        index
                                                                    )}
                                                                    getOptionLabel={(
                                                                        option
                                                                    ) => option}
                                                                    onChange={(
                                                                        event,
                                                                        newValue
                                                                    ) => {
                                                                        const value =
                                                                            {
                                                                                logic: newValue,
                                                                            }
                                                                        setConditions(
                                                                            value,
                                                                            index
                                                                        )
                                                                    }}
                                                                    value={
                                                                        condition.logic
                                                                    }
                                                                    fullWidth
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            required
                                                                            label="Logic"
                                                                            name="logic"
                                                                            onChange={
                                                                                handleChange
                                                                            }
                                                                            value={
                                                                                condition.logic
                                                                            }
                                                                            disabled={
                                                                                permissionCheck
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Item>
                                                )
                                            }
                                        }
                                    )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    if (notificationConditions.length < 3) {
                                        setConditions({
                                            operand: '',
                                            value: '',
                                            logic: '',
                                        })
                                        setState({
                                            formValidation: {
                                                ...formValidation,
                                                isFormDirty: true,
                                            },
                                        })
                                    } else {
                                        setToastMessage(
                                            'Maximum Number of Conditions (3) Have Been Met '
                                        )
                                    }
                                }}
                                sx={{
                                    width: '100%',
                                    height: 55,
                                }}
                                disabled={permissionCheck}
                            >
                                Add Conditional
                            </Button>
                        </Grid>
                    </FormSection>
                </Grid>
                {
                    /// Right Side of Grid
                }
                <Grid item xs={12} md={3}>
                    <FormSection title="Signal Events ">
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={() =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    notifyWeb: !notifyWeb,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                        inputProps={{
                                            'aria-label': 'controlled',
                                        }}
                                        id="switch.webNotification"
                                        name="switch.notifyWeb"
                                        checked={notifyWeb}
                                        key="switch.notifyWeb"
                                    />
                                }
                                label="Web Notification"
                                key="switch.notifyWeb"
                                disabled={permissionCheck}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={() =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    notifyEmail: !notifyEmail,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                        inputProps={{
                                            'aria-label': 'controlled',
                                        }}
                                        id="switch.notifyEmail"
                                        name="switch.notifyEmail"
                                        checked={notifyEmail}
                                        key="switch.notifyEmail"
                                    />
                                }
                                label="Email Notification"
                                key="switch.notifyEmail"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Autocomplete
                                disablePortal
                                id="notificationFrequency"
                                options={frequencies}
                                disabled={permissionCheck}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                    const data = {
                                        target: {
                                            name: 'notificationFrequency',
                                            value:
                                                typeof newValue === 'string'
                                                    ? newValue
                                                    : '',
                                        },
                                    }
                                    handleChange(data)
                                    setState({ emaiListDirty: true })
                                }}
                                fullWidth
                                value={notificationFrequency}
                                onBlur={(e: any) =>
                                    basicValidator(e, formValidation, setState)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Notification Frequency"
                                        name="notificationFrequency"
                                        onChange={handleChange}
                                        value={notificationFrequency}
                                        disabled={permissionCheck}
                                    />
                                )}
                            />
                        </Grid>
                    </FormSection>

                    {(notifyEmail || notifyWeb) && (
                        <FormSection title="Users to Notify">
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    multiple
                                    id="notifyEmailList"
                                    value={notifyEmailList}
                                    onBlur={() => {
                                        const validate =
                                            notifyEmailList.length !== 0
                                        if (validate) {
                                            setState({
                                                formValidation: {
                                                    ...formValidation,
                                                    notifyEmailListValidaton:
                                                        false,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                    }}
                                    onChange={(event, newValue) => {
                                        const data = {
                                            target: {
                                                name: 'notifyEmailList',
                                                value: newValue,
                                            },
                                        }
                                        handleChange(data)
                                    }}
                                    options={userEmailOptions}
                                    getOptionLabel={(option) => option}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip
                                                label={option}
                                                {...getTagProps({ index })}
                                                disabled={
                                                    notifyEmailList.indexOf(
                                                        index
                                                    ) !== -1
                                                }
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="notifyEmailList"
                                            label="Users to Notify"
                                            placeholder="Users to Notify"
                                            // error={
                                            //     ((notifyEmail &&
                                            //         notifyEmailList?.length ===
                                            //             0) ||
                                            //         (notifyWeb &&
                                            //             notifyEmailList?.length ===
                                            //                 0)) &&
                                            //     notifySubscriptionList.length ===
                                            //         0
                                            // }
                                            // helperText={
                                            //     ((notifyEmail &&
                                            //         notifyEmailList?.length ===
                                            //             0) ||
                                            //         (notifyWeb &&
                                            //             notifyEmailList?.length ===
                                            //                 0)) &&
                                            //     notifySubscriptionList.length ===
                                            //         0
                                            //         ? 'You must have at least one user selected.'
                                            //         : ''
                                            // }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    multiple
                                    id="notifySubscriptionList"
                                    value={notifySubscriptionList}
                                    onBlur={() => {
                                        const validate =
                                            notifySubscriptionList.length !== 0
                                        if (validate) {
                                            setState({
                                                formValidation: {
                                                    ...formValidation,
                                                    notifyEmailListValidaton:
                                                        false,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                    }}
                                    onChange={(event, newValue) => {
                                        const data = {
                                            target: {
                                                name: 'notifySubscriptionList',
                                                value: newValue,
                                            },
                                        }
                                        handleChange(data)
                                    }}
                                    options={userSubscriptionOptions}
                                    getOptionLabel={(option) =>
                                        option.subscriptionName
                                    }
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Tooltip
                                                title={option.subscribedUsers.map(
                                                    (
                                                        { userEmail },
                                                        childIndex
                                                    ) => {
                                                        if (
                                                            childIndex + 1 !==
                                                            option
                                                                .subscribedUsers
                                                                .length
                                                        ) {
                                                            return `${userEmail}, `
                                                        } else {
                                                            return `${userEmail}`
                                                        }
                                                    }
                                                )}
                                                key={`${option.subscriptionName}-${index}-tooltip`}
                                            >
                                                <Chip
                                                    label={
                                                        option.subscriptionName
                                                    }
                                                    {...getTagProps({
                                                        index,
                                                    })}
                                                    key={`${option.subscriptionName}-${index}-chip`}
                                                    disabled={
                                                        notifySubscriptionList.indexOf(
                                                            index
                                                        ) !== -1
                                                    }
                                                />
                                            </Tooltip>
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="notifySubscriptionList"
                                            label="User Notification Subscriptions"
                                            placeholder="User Notification Subscriptions"
                                            // error={
                                            //     (notifyEmail &&
                                            //         notifyEmailList?.length ===
                                            //             0) ||
                                            //     (notifyWeb &&
                                            //         notifyEmailList?.length ===
                                            //             0 &&
                                            //         notifySubscriptionList.length ===
                                            //             0)
                                            // }
                                            // helperText={
                                            //     ((notifyEmail &&
                                            //         notifyEmailList?.length ===
                                            //             0) ||
                                            //         (notifyWeb &&
                                            //             notifyEmailList?.length ===
                                            //                 0)) &&
                                            //     notifySubscriptionList.length ===
                                            //         0
                                            //         ? 'You must have at least one user email or subscription selected.'
                                            //         : ''
                                            // }
                                        />
                                    )}
                                />
                            </Grid>
                        </FormSection>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <SaveButton
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        disabledSave={!isFormValid || permissionCheck}
                        disabledReset={!isFormDirty || permissionCheck}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EventManagerForm
