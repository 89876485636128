import { Grid, Button, Fab, Typography, Paper } from '@mui/material'
import { UniversalModal } from '../../components/UniversalComponents'
import { useReducer, useEffect } from 'react'
import OzChatBox from './OzChatBox'
import OZLogo from '../../assets/osmosis/Logos/OZ.png'
const OzChatModal = () => {
    const defaultState = {
        open: false,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { open } = state

    return (
        <>
            {/* {!open && (
                <Fab
                    color="secondary"
                    aria-label="chat"
                    onClick={() => setState({ open: !open })}
                    style={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                    }}
                >
                    <img
                        src={OZLogo}
                        alt="Chat"
                        style={{ width: '95%', height: '95%', padding: 4 }}
                    />
                </Fab>
            )} */}
            <UniversalModal
                title="OZ Chat"
                open={open}
                onClose={() =>
                    setState({
                        open: !open,
                    })
                }
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 10,
                    marginBottom: 5,
                }}
            >
                <OzChatBox modal={true} />
            </UniversalModal>
        </>
    )
}

export default OzChatModal
