import {
    Grid,
    Typography,
    TextField,
    Button,
    Chip,
    IconButton,
    Tooltip,
    styled,
    Avatar,
    Box,
    FormControlLabel,
    Switch,
} from '@mui/material'
import { useContext, useEffect, useReducer } from 'react'
import { useMinimizer } from '../../../../context/MinimizeContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import {
    FormSection,
    UniversalReactSorter,
    SaveButton,
    Autocomplete,
} from '../../../UniversalComponents'
import {
    postSignalProfile,
    getSignals,
    getManufacturers,
    getEvents,
} from '../../../../apiRoutes'
import { useToastMessage } from '../../../../context/ToastPopUpContext'
import {
    permissionsCheck,
    basicValidator,
} from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'
import responseError from '../../../../context/responseError/responseError'
import SignalProfileCards from './SignalProfileCards'
import SignalProfileEventCard from './SignalProfileEventCard'
import { UniversalAutoComplete } from '../../../UniversalComponents/UniversalFormStyles'

interface SignalProfileManagerFormProps {
    row?: {
        id?: string
        webSignals?: any
        mobileSignals?: any
        profileName?: string
        updatedBy?: string
        manufacturer?: {
            manufacturerName?: string
        }
        boatEvents?: any
        fuel?: {
            _id?: string
            signalFriendlyName?: string
        }
        battery?: {
            _id: string
            signalFriendlyName: string
        }
        gpsMode?: {
            _id: string
            signalFriendlyName: string
        }
        gpsLat?: {
            _id: string
            signalFriendlyName: string
        }
        gpsLong?: {
            _id: string
            signalFriendlyName: string
        }
        boatState?: {
            _id: string
            signalFriendlyName: string
        }
    }
    formDescription?: string
    onClose: (tableRow: any, newData: any) => void
    saveCache: (e: any) => void
    permissionCheck: boolean
    quickStart?: boolean
}

const SignalProfileManagerForm = ({
    row,
    formDescription,
    onClose,
    saveCache,
    permissionCheck,
    quickStart,
}: SignalProfileManagerFormProps) => {
    const setToastMessage = useToastMessage()
    const userPermissions = usePermissions()
    const currentUser = useContext(Authentication)
    const { username } = currentUser.auth

    const defaultState = {
        formData: {
            id: row?.id || '',
            webSignals: row?.webSignals || [],
            mobileSignals: row?.mobileSignals || [],
            profileName: row?.profileName || '',
            updatedBy: row?.updatedBy || username,
            manufacturer: row?.manufacturer?.manufacturerName || '',
            boatEvents: row?.boatEvents || [],
            fuel: row?.fuel?.signalFriendlyName || null,
            battery: row?.battery?.signalFriendlyName || null,
            gpsMode: row?.gpsMode?.signalFriendlyName || null,
            gpsLong: row?.gpsLong?.signalFriendlyName || null,
            gpsLat: row?.gpsLat?.signalFriendlyName || null,
            boatState: row?.boatState?.signalFriendlyName || null,
        },
        formValidation: {
            profileNameValidation: false,
            manufacturerValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
        signals: [],
        defaultWebSignalList: [],
        defaultMobileSignalList: [],
        webSignalList: [],
        mobileSignalList: [],
        selectedWebSignal: '',
        selectedMobileSignal: '',
        signalEvents: [],
        signalEventList: [],
        selectedSignalEvent: '',
        defaultSignalEventList: [],
        coreSignalList: [],
    }
    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        formValidation,
        signals,
        signalEvents,
        signalEventList,
        defaultSignalEventList,
        selectedSignalEvent,
        defaultWebSignalList,
        defaultMobileSignalList,
        webSignalList,
        mobileSignalList,
        selectedWebSignal,
        selectedMobileSignal,
        coreSignalList,
    } = state

    const {
        id,
        webSignals,
        mobileSignals,
        profileName,
        updatedBy,
        manufacturer,
        boatEvents,
        fuel,
        battery,
        gpsMode,
        gpsLat,
        gpsLong,
        boatState,
    } = formData

    const {
        profileNameValidation,
        manufacturerValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleChange = (e: any) => {
        const { name, value } = e.target
        if (
            name === 'selectedWebSignal' ||
            name === 'selectedMobileSignal' ||
            name === 'selectedSignalEvent'
        ) {
            setState({
                [name]: value,
            })
        } else {
            setState({
                formData: { ...formData, [name]: value },
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
            })
        }

        return saveCache({ ...formData, [name]: value })
    }

    const handleAddSignal = (type: string) => {
        if (type === 'webSignal') {
            const addSignal = signals.find(
                (signal) => signal.signalFriendlyName === selectedWebSignal
            )

            const filteredWebSignalList = webSignalList
                .filter((signal) => signal !== selectedWebSignal)
                .map((signal) => signal)

            setState({
                selectedWebSignal: '',
                webSignalList: filteredWebSignalList,
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
                formData: {
                    ...formData,
                    webSignals: [...webSignals, addSignal],
                },
            })
        }

        if (type === 'mobileSignal') {
            const addSignal = signals.find(
                (signal) => signal.signalFriendlyName === selectedMobileSignal
            )

            const filteredMobileSignalList = mobileSignalList
                .filter((signal) => signal !== selectedMobileSignal)
                .map((signal) => signal)

            setState({
                selectedMobileSignal: '',
                mobileSignalList: filteredMobileSignalList,
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
                formData: {
                    ...formData,
                    mobileSignals: [...mobileSignals, addSignal],
                },
            })
        }
        if (type === 'boatEvent') {
            const addSignal = signalEvents.find(
                (signal) => signal.notificationName === selectedSignalEvent
            )

            const filteredEventSignalList = signalEventList
                .filter(
                    (notificationName) =>
                        notificationName !== selectedSignalEvent
                )
                .map((notificationName) => notificationName)

            setState({
                selectedSignalEvent: '',
                signalEventList: filteredEventSignalList,
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
                formData: {
                    ...formData,
                    boatEvents: [
                        ...boatEvents,
                        {
                            ...addSignal,
                            logEvents: false,
                            pushNotifications: false,
                        },
                    ],
                },
            })
        }
    }

    const handleRemoveSignal = (type: string, name: string) => {
        if (type === 'webSignal') {
            const filteredWebSignals = webSignals.filter(
                (signal) => signal.signalFriendlyName !== name
            )

            setState({
                webSignalList: [name, ...webSignalList],
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
                formData: {
                    ...formData,
                    webSignals: filteredWebSignals,
                },
            })
        }

        if (type === 'mobileSignal') {
            const filteredMobileSignals = mobileSignals.filter(
                (signal) => signal.signalFriendlyName !== name
            )

            setState({
                mobileSignalList: [name, ...mobileSignalList],
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
                formData: {
                    ...formData,
                    mobileSignals: filteredMobileSignals,
                },
            })
        }
        if (type === 'boatEvent') {
            const filteredBoatEvents = boatEvents.filter(
                (event) => event.notificationName !== name
            )

            setState({
                signalEventList: [name, ...signalEventList],
                formValidation: {
                    ...formValidation,
                    isFormDirty: true,
                },
                formData: {
                    ...formData,
                    boatEvents: filteredBoatEvents,
                },
            })
        }
    }

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
            webSignalList: defaultWebSignalList,
            mobileSignalList: defaultMobileSignalList,
            signalEventList: defaultSignalEventList,
        })

    const handleSubmit = async () => {
        try {
            const findFuel = signals.find(
                ({ signalFriendlyName }) => signalFriendlyName === fuel
            )
            const findBattery = signals.find(
                ({ signalFriendlyName }) => signalFriendlyName === battery
            )
            const findBoatState = signals.find(
                ({ signalFriendlyName }) => signalFriendlyName === boatState
            )
            const findGpsMode = signals.find(
                ({ signalFriendlyName }) => signalFriendlyName === gpsMode
            )
            const findGpsLat = signals.find(
                ({ signalFriendlyName }) => signalFriendlyName === gpsLat
            )
            const findGpsLong = signals.find(
                ({ signalFriendlyName }) => signalFriendlyName === gpsLong
            )

            const parsedFormData = {
                ...formData,
                fuel: findFuel !== undefined ? findFuel : null,
                battery: findBattery !== undefined ? findBattery : null,
                boatState: findBoatState !== undefined ? findBoatState : null,
                gpsMode: findGpsMode !== undefined ? findGpsMode : null,
                gpsLat: findGpsLat !== undefined ? findGpsLat : null,
                gpsLong: findGpsLong !== undefined ? findGpsLong : null,
            }

            let res: any

            res = await postSignalProfile(parsedFormData)

            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `Profile: ${data.profileName} was ${
                            row?.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )

                    onClose(row?.profileName, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleSetEvent = (type, index) => {
        const cloneBoatEvents = [...boatEvents]
        if (type === 'logEvents') {
            cloneBoatEvents[index].logEvents = !boatEvents[index].logEvents
        }
        if (type === 'pushNotifications') {
            cloneBoatEvents[index].pushNotifications =
                !boatEvents[index].pushNotifications
        }

        setState({
            formData: {
                ...formData,
                boatEvents: cloneBoatEvents,
            },
        })
    }
    const getData = async () => {
        try {
            const signalData = await getSignals(true)
            const signalEventData = await getEvents(true)
            let webSignalList = []
            let mobileSignalList = []
            let signalEventList = []
            let coreSignalList = []
            if (signalData && signalData?.length !== 0) {
                webSignalList = signalData.map(
                    (signal) => signal?.signalFriendlyName
                )
                mobileSignalList = signalData.map(
                    (signal) => signal?.signalFriendlyName
                )
                signalEventList = signalEventData.map(
                    (event) => event?.notificationName
                )

                coreSignalList = signalData.map(
                    (signal) => signal?.signalFriendlyName
                )

                if (row?.webSignals && row?.webSignals?.length !== 0) {
                    webSignals.forEach(({ signalFriendlyName }) => {
                        webSignalList = webSignalList.filter(
                            (signal) => signal !== signalFriendlyName
                        )

                        return webSignalList
                    })
                }
                if (row?.mobileSignals) {
                    mobileSignals.forEach(({ signalFriendlyName }) => {
                        mobileSignalList = mobileSignalList.filter(
                            (signal) => signal !== signalFriendlyName
                        )
                        return mobileSignalList
                    })
                }
                if (row?.boatEvents) {
                    boatEvents.forEach(({ notificationName }) => {
                        signalEventList = signalEventList.filter(
                            (signal) => signal !== notificationName
                        )
                        return signalEventList
                    })
                }

                setState({
                    signals: signalData,
                    defaultWebSignalList: webSignalList,
                    defaultMobileSignalList: mobileSignalList,
                    webSignalList: webSignalList,
                    mobileSignalList: mobileSignalList,
                    coreSignalList: coreSignalList,
                    signalEvents: signalEventData,
                    signalEventList,
                    defaultSignalEventList: signalEventList,
                })
            } else {
                setToastMessage(
                    'There are no signals to fetch. Please add one or check your connection.',
                    'error'
                )
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (isFormDirty && !profileNameValidation && profileName) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [isFormDirty, profileNameValidation, profileName])

    return (
        <Grid container spacing={2}>
            <Grid container spacing={2} sx={{ marginTop: 4 }}>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <FormSection
                                title="Signal Profile Details"
                                titleAlignment="left"
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        required={true}
                                        name="profileName"
                                        label="Profile Name"
                                        fullWidth
                                        value={profileName}
                                        onChange={handleChange}
                                        disabled={permissionCheck}
                                        onBlur={(e: any) =>
                                            basicValidator(
                                                e,
                                                formValidation,
                                                setState
                                            )
                                        }
                                        error={profileNameValidation}
                                        helperText={
                                            profileNameValidation
                                                ? 'Please enter a profile name.'
                                                : ''
                                        }
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <FormSection
                                title="Core Signals"
                                titleAlignment="left"
                            >
                                <Grid item xs={12} md={6}>
                                    <UniversalAutoComplete
                                        name="fuel"
                                        label="Fuel"
                                        fullWidth
                                        options={coreSignalList}
                                        value={fuel}
                                        handleChange={handleChange}
                                        disabled={permissionCheck}
                                        clearFunction={() =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    fuel: null,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <UniversalAutoComplete
                                        name="gpsLong"
                                        label="GPS Longitude"
                                        fullWidth
                                        options={coreSignalList}
                                        value={gpsLong}
                                        handleChange={handleChange}
                                        disabled={permissionCheck}
                                        clearFunction={() =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    gpsLong: null,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <UniversalAutoComplete
                                        name="boatState"
                                        label="Boat State"
                                        fullWidth
                                        options={coreSignalList}
                                        value={boatState}
                                        handleChange={handleChange}
                                        disabled={permissionCheck}
                                        clearFunction={() =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    boatState: null,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <UniversalAutoComplete
                                        name="gpsLat"
                                        label="GPS Latitude"
                                        fullWidth
                                        options={coreSignalList}
                                        value={gpsLat}
                                        handleChange={handleChange}
                                        disabled={permissionCheck}
                                        clearFunction={() =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    gpsLat: null,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <UniversalAutoComplete
                                        name="battery"
                                        label="Battery"
                                        fullWidth
                                        options={coreSignalList}
                                        value={battery}
                                        handleChange={handleChange}
                                        disabled={permissionCheck}
                                        clearFunction={() =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    battery: null,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <UniversalAutoComplete
                                        name="gpsMode"
                                        label="GPS Mode"
                                        fullWidth
                                        options={coreSignalList}
                                        value={gpsMode}
                                        handleChange={handleChange}
                                        disabled={permissionCheck}
                                        clearFunction={() =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    gpsMode: null,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormSection
                                title="Web Signals: (Drag to Re-Order)"
                                titleAlignment="left"
                            >
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={7}>
                                            <Autocomplete
                                                options={webSignalList}
                                                value={selectedWebSignal}
                                                handleChange={handleChange}
                                                required={true}
                                                label="Select Signal"
                                                name="selectedWebSignal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    handleAddSignal('webSignal')
                                                }
                                                disabled={!selectedWebSignal}
                                                sx={{
                                                    width: '100%',
                                                    height: 55,
                                                }}
                                            >
                                                Add Signal
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <UniversalReactSorter
                                        list={webSignals}
                                        setList={(drag) =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    webSignals: drag,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                        group="fixed"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'left',
                                            flexWrap: 'wrap',
                                            marginTop: 5,
                                            width: '100%',
                                        }}
                                    >
                                        {webSignals &&
                                            webSignals?.length !== 0 &&
                                            webSignals.map(
                                                ({
                                                    _id,
                                                    signalIconUrl,
                                                    signalFriendlyName,
                                                    unitOfMeasurement,
                                                }) => (
                                                    <SignalProfileCards
                                                        image={signalIconUrl}
                                                        key={`${_id}.card`}
                                                        description={'0'}
                                                        unitOfMeasurement={
                                                            unitOfMeasurement.unitOfMeasurementAbrv
                                                        }
                                                        title={
                                                            signalFriendlyName
                                                        }
                                                        size="web"
                                                        type="webSignal"
                                                        removeSignal={
                                                            handleRemoveSignal
                                                        }
                                                    />
                                                )
                                            )}
                                    </UniversalReactSorter>
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSection
                                title="Mobile Signals (Drag to Re-order)"
                                titleAlignment="left"
                            >
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={7}>
                                            <Autocomplete
                                                options={mobileSignalList}
                                                value={selectedMobileSignal}
                                                handleChange={handleChange}
                                                required={true}
                                                label="Select Signal"
                                                name="selectedMobileSignal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() =>
                                                    handleAddSignal(
                                                        'mobileSignal'
                                                    )
                                                }
                                                disabled={!selectedMobileSignal}
                                                sx={{
                                                    width: '100%',
                                                    height: 55,
                                                }}
                                            >
                                                Add Signal
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <UniversalReactSorter
                                        list={mobileSignals}
                                        setList={(drag) =>
                                            setState({
                                                formData: {
                                                    ...formData,
                                                    mobileSignals: drag,
                                                },
                                                formValidation: {
                                                    ...formValidation,
                                                    isFormDirty: true,
                                                },
                                            })
                                        }
                                        group="fixed"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'left',
                                            flexWrap: 'wrap',
                                            marginTop: 5,
                                            width: '100%',
                                        }}
                                    >
                                        {mobileSignals.map(
                                            ({
                                                _id,
                                                signalIconUrl,
                                                signalFriendlyName,
                                                unitOfMeasurement,
                                            }) => (
                                                <SignalProfileCards
                                                    image={signalIconUrl}
                                                    key={`${_id}.card`}
                                                    description={'0'}
                                                    unitOfMeasurement={
                                                        unitOfMeasurement.unitOfMeasurementAbrv
                                                    }
                                                    type="mobileSignal"
                                                    title={signalFriendlyName}
                                                    removeSignal={
                                                        handleRemoveSignal
                                                    }
                                                />
                                            )
                                        )}
                                    </UniversalReactSorter>
                                </Grid>
                            </FormSection>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                    <FormSection
                        title="Boat Signal Events"
                        titleAlignment="left"
                    >
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={7}>
                                    <Autocomplete
                                        options={signalEventList}
                                        value={selectedSignalEvent}
                                        handleChange={handleChange}
                                        label="Select Signal Event"
                                        name="selectedSignalEvent"
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                            handleAddSignal('boatEvent')
                                        }
                                        disabled={!selectedSignalEvent}
                                        sx={{
                                            width: '100%',
                                            height: 55,
                                        }}
                                    >
                                        Add Signal
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <UniversalReactSorter
                                list={boatEvents}
                                setList={(drag) =>
                                    setState({
                                        formData: {
                                            ...formData,
                                            boatEvents: drag,
                                        },
                                        formValidation: {
                                            ...formValidation,
                                            isFormDirty: true,
                                        },
                                    })
                                }
                                group="fixed"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'left',
                                    flexWrap: 'wrap',
                                    marginTop: 5,
                                    width: '100%',
                                }}
                            >
                                {boatEvents.map(
                                    (
                                        {
                                            _id,
                                            notificationName,
                                            logEvents,
                                            pushNotifications,
                                        },
                                        index
                                    ) => (
                                        <SignalProfileEventCard
                                            key={`${_id}.card`}
                                            logEvents={logEvents}
                                            pushNotifications={
                                                pushNotifications
                                            }
                                            notificationName={notificationName}
                                            index={index}
                                            setEvent={handleSetEvent}
                                            removeEvent={handleRemoveSignal}
                                            type="boatEvent"
                                        />
                                    )
                                )}
                            </UniversalReactSorter>
                        </Grid>
                    </FormSection>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <SaveButton
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        disabledSave={!isFormValid}
                        disabledReset={!isFormDirty}
                        quickStart={quickStart}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SignalProfileManagerForm
