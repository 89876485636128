import {
    Grid,
    Typography,
    Paper,
    Autocomplete,
    TextField,
    IconButton,
    Button,
    Tooltip,
} from '@mui/material'
import { osmosisGoogleMapAddressApi } from '../../config/osmosisConfig'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-google-places-autocomplete'
import { grey } from '@mui/material/colors'
import { useContext, useEffect, useState } from 'react'
import { GoogleMapsWrapper } from './hooks/GoogleMapsApiWrapper'
import { Authentication } from '../../context/AuthenticationContext'

interface UniversalFormSectionTypes {
    title?: string
    icon?: any
    size?: number
    save?: any
    reset?: any
    titleBottom?: any
    titleAlignment?: 'left' | 'center' | 'right'
    titleBottomAlignment?: 'left' | 'center' | 'right'
    spacing?: number
    sx?: {}
    children?: React.ReactNode
}

export const UniversalFormSection = ({
    title,
    size = 12,
    titleAlignment = 'left',
    spacing = 2,
    sx = { padding: 3 },
    children,
    icon,
    save,
    reset,
    titleBottom,
    titleBottomAlignment = 'center',
}: UniversalFormSectionTypes) => {
    return (
        <Grid item xs={size} sx={{ marginBottom: 2 }}>
            <Paper sx={sx} elevation={4}>
                <Grid container spacing={spacing}>
                    <Grid
                        container
                        sx={{
                            marginBottom: -2,
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Grid item xs={3}>
                            {save}
                        </Grid>
                        {reset}
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: -1 }}>
                        <Typography
                            align={titleAlignment}
                            variant="h5"
                            color={'primary'}
                        >
                            {icon}
                            {title}
                        </Typography>
                    </Grid>

                    {children}

                    <Grid item xs={12} sx={{ marginTop: 1 }}>
                        <Typography
                            align={titleBottomAlignment}
                            variant="h5"
                            color={'primary'}
                        >
                            {titleBottom}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

interface UniversalAutoCompleteProps {
    options: string[]
    value: any
    handleChange: any
    label: string
    name: string
    error?: boolean
    helperText?: string
    required?: boolean
    id?: string
    fullWidth?: boolean
    sx?: any
    onBlur?: any
    InputProps?: any
    getOptionLabel?: any
    disabled?: boolean
    clearFunction?: any
    additionalFunction?: any
    disableClearable?: boolean
    customIcon?: any
    multiple?: boolean
    isOptionEqualToValue?: any
    renderTags?: any
    onTextFieldChange?: any
}

export const UniversalAutoComplete = ({
    options,
    value,
    handleChange,
    onBlur,
    label,
    name,
    error,
    required,
    helperText,
    id,
    sx,
    InputProps,
    fullWidth = true,
    getOptionLabel,
    disabled = false,
    clearFunction,
    disableClearable,
    customIcon,
    multiple,
    isOptionEqualToValue,
    renderTags,
    onTextFieldChange,
    additionalFunction,
}: UniversalAutoCompleteProps) => {
    return (
        <Autocomplete
            multiple={multiple}
            disablePortal
            id={id}
            options={options}
            value={value}
            onBlur={onBlur}
            disabled={disabled}
            renderTags={renderTags}
            filterSelectedOptions
            isOptionEqualToValue={isOptionEqualToValue}
            disableClearable={disableClearable || disabled || !!clearFunction}
            {...getOptionLabel}
            onChange={(event, newValue) => {
                const data = {
                    target: {
                        name,
                        value: newValue,
                    },
                }
                handleChange(data)
            }}
            fullWidth={fullWidth}
            sx={sx}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={name}
                    label={label}
                    helperText={helperText}
                    error={error}
                    onChange={onTextFieldChange}
                    required={required}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment:
                            clearFunction &&
                            params.inputProps.value &&
                            !disabled ? (
                                <>
                                    {params.InputProps.endAdornment}
                                    <IconButton onClick={clearFunction}>
                                        <HighlightOffIcon />
                                    </IconButton>
                                    {additionalFunction && (
                                        <IconButton
                                            onClick={additionalFunction}
                                        >
                                            {customIcon}
                                        </IconButton>
                                    )}
                                </>
                            ) : (
                                params.InputProps.endAdornment
                            ),
                    }}
                />
            )}
        />
    )
}

interface UniversalTitle {
    title: string
    variant?: any
    color?: 'primary' | 'secondary'
    sx?: {}
    align?: 'center' | 'left' | 'right' | 'inherit' | 'justify'
}

export const UniversalTitle = ({
    title,
    variant = 'h1',
    color = 'primary',
    sx = { margin: 2 },
    align = 'center',
}: UniversalTitle) => {
    return (
        <Typography variant={variant} color={color} align={align} sx={sx}>
            {title}
        </Typography>
    )
}

// Universal Google Maps Autocomplete

interface UniversalGoogleMapsProps {
    value: any
    onBlur?: any
    label: string
    name?: string
    id?: string
    fullWidth?: boolean
    sx?: any
    onChange?: (location: any) => void
    onClick?: any
    clearFunction?: any
    disabled?: any
}

export const UniversalGoogleMapsAc = ({
    value,
    onChange,
    label,
    clearFunction,
    disabled,
    onBlur,
}: UniversalGoogleMapsProps) => {
    const handleSelect = async (address: any) => {
        try {
            const results = await geocodeByAddress(address.label)
            const latLng = await getLatLng(results[0])

            const location = {
                address: address.label,
                lat: latLng.lat,
                lng: latLng.lng,
            }

            onChange?.(location)
        } catch (error) {
            console.error('Error fetching location:', error)
        }
    }

    return (
        <Grid container spacing={2}>
            <GoogleMapsWrapper>
                <Grid item xs={11}>
                    <GooglePlacesAutocomplete
                        apiKey={osmosisGoogleMapAddressApi}
                        selectProps={{
                            value: value,
                            onChange: handleSelect,
                            isClearable: clearFunction,
                            placeholder: value ? value : label,
                            isDisabled: disabled,
                            onBlur: onBlur,

                            styles: {
                                container: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#1e1e1e',
                                    borderRadius: '4px',
                                    width: '100%',
                                }),
                                control: (provided, { isFocused }) => ({
                                    ...provided,
                                    backgroundColor: grey[800],
                                    border: `1px solid ${
                                        isFocused ? '#666666' : '#444444'
                                    }`,
                                    borderRadius: '4px',
                                    padding: '4px',
                                    boxShadow: isFocused
                                        ? '0 0 5px rgba(255, 255, 255, 0.2)'
                                        : 'none',
                                    '&:hover': {
                                        borderColor: '#666666',
                                    },
                                }),
                                option: (
                                    provided,
                                    { isFocused, isSelected }
                                ) => ({
                                    ...provided,
                                    color: isSelected ? 'white' : '#ddd',
                                    backgroundColor: isFocused
                                        ? '#555555'
                                        : isSelected
                                        ? '#333333'
                                        : '#1e1e1e',
                                    padding: '10px 15px',
                                    borderRadius: '4px',
                                    transition: 'background-color 0.2s',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#444444',
                                    },
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#1e1e1e',
                                    borderRadius: '4px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                                    zIndex: 1000,
                                }),
                                input: (provided) => ({
                                    ...provided,
                                    color: 'lightgray',

                                    padding: '12px 15px',
                                    outline: 'none',
                                    boxShadow: 'none',
                                    '&:focus': {
                                        borderColor: 'lightgray',
                                        boxShadow:
                                            '0 0 5px rgba(255, 255, 255, 0.5)',
                                    },
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: 'lightgray',
                                    backgroundColor: grey[800],
                                    padding: '12px 15px',
                                    borderRadius: '4px',
                                }),

                                placeholder: (provided) => ({
                                    ...provided,
                                    color: 'lightgray',
                                    backgroundColor: grey[800],
                                    padding: '12px 15px',
                                    borderRadius: '4px',
                                }),
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={1} sx={{ padding: 2 }}>
                    {value && (
                        <Tooltip title="Reset Address">
                            <Button
                                onClick={clearFunction}
                                sx={{ backgroundColor: grey[800] }}
                            >
                                <HighlightOffIcon
                                    sx={{ fontSize: 40, my: 1 }}
                                />
                            </Button>
                        </Tooltip>
                    )}
                </Grid>
            </GoogleMapsWrapper>
        </Grid>
    )
}

interface UniversalSaveButtonProps {
    handleSubmit: (e: any) => void
    handleReset: () => void
    disabledSave: boolean
    disabledReset: boolean
    saveTitle?: string
    resetTitle?: string
    title?: string
    titleAlignment?: 'center' | 'left' | 'right'
    saveStyle?: {}
    resetStyle?: {}
    saveVariant?: 'contained' | 'text' | 'outlined'
    resetVariant?: 'contained' | 'text' | 'outlined'
    containerStyle?: {}
    saveColor?: 'primary' | 'secondary' | 'error'
    resetColor?: 'primary' | 'secondary' | 'error'
    saveSize?: number
    resetSize?: number
    quickStart?: boolean
    manufacturer?: any
}
export const UniversalSaveButton = ({
    handleSubmit,
    handleReset,
    disabledSave,
    disabledReset,
    saveTitle,
    resetTitle,
    title,
    titleAlignment,
    saveStyle,
    resetStyle,
    saveVariant,
    resetVariant,
    containerStyle,
    saveColor,
    resetColor,
    saveSize,
    resetSize,
    quickStart,
    manufacturer,
}: UniversalSaveButtonProps) => {
    const currentUser = useContext(Authentication)
    const { activeManufacturer } = currentUser.auth
    const manufacturerCheck = activeManufacturer === manufacturer

    return (
        <UniversalFormSection
            title={
                manufacturer && !manufacturerCheck
                    ? "Your account's active Manufacturer does not match the Manufacturer found on this form"
                    : title || ''
            }
            titleAlignment={titleAlignment || 'center'}
            sx={{ ...containerStyle, marginTop: 2 }}
        >
            <Grid item xs={12}>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        alignContent: 'center',
                        paddingLeft: 2,
                        paddingRight: 2,
                    }}
                >
                    <Grid item xs={12} md={saveSize || 8}>
                        <Button
                            variant={saveVariant || 'contained'}
                            color={saveColor || 'primary'}
                            onClick={handleSubmit}
                            disabled={
                                manufacturer && !manufacturerCheck
                                    ? true
                                    : disabledSave
                            }
                            fullWidth
                            sx={{
                                height: 55,
                                ...saveStyle,
                            }}
                        >
                            {(quickStart && 'Save & Go To Next Step') ||
                                saveTitle ||
                                'Save & Close'}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={resetSize || 4}>
                        <Button
                            variant={resetVariant || 'contained'}
                            color={resetColor || 'error'}
                            onClick={handleReset}
                            disabled={
                                manufacturer && !manufacturerCheck
                                    ? true
                                    : disabledReset
                            }
                            fullWidth
                            sx={{
                                height: 55,
                                ...resetStyle,
                            }}
                        >
                            {resetTitle || 'reset'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </UniversalFormSection>
    )
}
