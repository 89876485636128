import { Grid, Divider } from '@mui/material'

interface UniversalDataGridLayoutTypes {
    cookieCrumbs?: React.ReactNode
    toolbar?: React.ReactNode
    dataGrid: React.ReactNode
    tabBar?: React.ReactNode
}

export const UniversalDataGridLayout = ({
    cookieCrumbs,
    toolbar,
    tabBar,
    dataGrid,
}: UniversalDataGridLayoutTypes) => {
    return (
        <Grid container spacing={2} sx={{ marginTop: 10 }}>
            <Grid item xs={12}>
                <Grid container sx={{ bgcolor: 'primary' }}>
                    <Grid item xs={12}>
                        {cookieCrumbs}
                    </Grid>

                    {!tabBar ? (
                        <Grid container>
                            <Grid item xs={12}>
                                {toolbar}
                            </Grid>
                            <Grid container>{dataGrid}</Grid>
                        </Grid>
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                {tabBar}
                            </Grid>
                            <Grid item xs={12}>
                                {toolbar}
                            </Grid>
                            {dataGrid}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

const UniversalLayouts = {
    UniversalDataGridLayout,
}

export default UniversalLayouts
