import buildRoute from './buildRoute'

export const getTelemetry = async (filters: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'get-telemetry',
            data: filters,
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

export const getBoatTelemetry = async ({ _id, telemetryType }) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'get-boat-telemetry',
            data: { _id, telemetryType },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}
