import { createTheme } from '@mui/material/styles'
import { green, grey } from '@mui/material/colors'

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: '#8cc63f',
            main: '#8cc63f',
            dark: '#8cc63f',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#333',
            dark: '#333',
            contrastText: '#fff',
        },
        action: {
            hover: '#8cc63f',
            active: '#8cc63f',
        },
        background: {
            paper: grey[900],
            default: '#212121',
        },
    },
})

const defaultTheme = {
    name: 'defaultTheme',
    theme,
}

export default defaultTheme
