import buildRoute from './buildRoute'

export const addDevice = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'device',
            data: formData,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

// export const addMultipleDevices = async (file: File) => {
//     try {
//         const formData = new FormData() // Create a new FormData object

//         formData.append('file', file) // Append the file to FormData

//         // Send the file to the backend route
//         const res = await buildRoute({
//             type: 'post',
//             route: 'multipleDevices', // Adjust to match your backend route
//             data: formData, // Pass the FormData object as 'data'
//         })

//         if (res?.data) {
//             return res // Return response if successful
//         }
//     } catch (err: any) {
//         console.error('Error uploading file:', err)
//         return err // Return the error if any occurs
//     }
// }

export const addMultipleDevices = async (formData: any) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'file',
            route: 'multiple-devices',
            data: formData,
        })

        if (res?.data) {
            return res // Return response if successful
        }
    } catch (err: any) {
        console.error('Error uploading file:', err)
        return err // Return the error if any occurs
    }
}

export const getDevices = async (active) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'devices',
            data: { active },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deactivateDevices = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'device-activations',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

interface SelectionType {
    data: []
    activate: boolean
}

export const deleteDevice = async (selection: SelectionType) => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'device-deletion',
            data: selection,
        })
        if (res.data) {
            return res
        }
    } catch (err: any) {
        console.error(err)
        return err
    }
}

const deviceRoutes = {
    getDevices,
    deactivateDevices,
    addDevice,
    deleteDevice,
    addMultipleDevices,
}

export default deviceRoutes
