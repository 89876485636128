import { useContext, useEffect, useReducer } from 'react'
import { Chip, Grid, Toolbar, IconButton, Tooltip, AppBar } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import StyleIcon from '@mui/icons-material/Style'
import { ToastMessage } from '../../context/ToastPopUpContext'
import { getManufacturers, getTags } from '../../apiRoutes'
import { UniversalAutoComplete } from './UniversalFormStyles'
import { useAuth } from '../../context/AuthenticationContext'

interface tags {
    addTag?: any
    row?: any
    pageType?: string
    setFormDirty?: any
    entryId?: any
}

const UniversalTagToolbar = ({
    addTag,
    row,
    pageType,
    setFormDirty,
    entryId,
}: tags) => {
    const tagsForMapping = row?.tags
    const filteredTags = tagsForMapping?.map((tag) => tag.tag)

    const currentUser = useAuth()

    const { activeManufacturer } = currentUser.auth

    const defaultState = {
        tags: filteredTags || [],
        tag: '',
        availableTags: [],
        tagData: [],
        addingTags: false,
        manufacturers: [],
        taggedItem: row?._id || '',
        manufacturerName: row?.manufacturerName || activeManufacturer,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)

    const {
        tags,
        availableTags,
        addingTags,
        tagData,
        tag,
        manufacturers,
        taggedItem,
        manufacturerName,
    } = state

    const getData = async (active) => {
        let manufacturers: any = []
        let availableTags: any = []
        let tagData: any = []

        try {
            const data = await getTags(active)
            const getManufacturerData = await getManufacturers(true)

            if (data && data.length !== 0) {
                const filterTags = data.map((tag) => tag.tag)

                availableTags = filterTags
                tagData = data
            }
            if (getManufacturerData && getManufacturerData.length !== 0) {
                manufacturers = getManufacturerData.filter(
                    (manufacturer) => manufacturer
                )
            } else {
                setState({ availableTags: [] })
            }
            setState({ availableTags, tagData, manufacturers })
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    useEffect(() => {
        const handleSubmit = (tags, tagData) => {
            let taggedManufacturer = activeManufacturer
            let page: any = pageType
            let taggedItems: any = {}
            let taggedId = taggedItem === '' ? entryId : taggedItem

            const pagedata = {
                pageType: pageType,
                taggedId: taggedItem === '' ? entryId : row?._id,
            }

            taggedItems = {
                ...taggedItems,
                [page]: [
                    ...(taggedItems[page] || []).filter(
                        (item: any) => item?._id !== taggedId
                    ),
                    taggedId,
                ],
            }

            const findManufacturer = manufacturers?.find(
                ({ manufacturerName }) =>
                    manufacturerName === taggedManufacturer
            )

            const existingTags = tagData.map((data) => data.tag)

            const removeTags = (row?.tags || []).filter(
                (tagObj) => !tags.includes(tagObj.tag)
            )
            const removedTags = [...removeTags, pagedata]

            const matchedTags = tagData
                .filter((data) => tags.includes(data.tag))
                .map((data) => {
                    const updatedTaggedItems: any = {
                        ...data.taggedItems,
                        [page]: [
                            ...(data.taggedItems?.[page] || []).filter(
                                (item) => item?._id !== taggedId
                            ),
                            taggedId,
                        ],
                    }

                    return {
                        ...data,
                        taggedItems: updatedTaggedItems,
                        pageType,
                    }
                })

            const newTags = tags
                .filter((tag) => !existingTags.includes(tag))
                .map((tag) => ({
                    tag,
                    manufacturer: findManufacturer,
                    taggedItems: taggedItems,
                    pageType,
                }))

            const compiledTags = [...matchedTags, ...newTags]

            if (addTag) {
                addTag(compiledTags, removedTags)
            } else {
                console.warn('addTag function is not provided.')
            }
        }

        if (tags && tagData) {
            handleSubmit(tags, tagData)
        }
    }, [tagData, tags, entryId])

    const handleStartTagging = () => {
        setState({ addingTags: true })
    }

    const handleTagChange = (e: any) => {
        const { name, value } = e.target

        if (name === 'tag') {
            setState({ tag: value })
        }
    }

    const handleTagSelectionChange = (event) => {
        const tagValue = event.target.value
        setState({ tags: [...tags, tagValue], tag: tagValue })
        addTag ? setFormDirty() : null
    }

    const saveCustomTag = () => {
        const newTag = tag
        if (!newTag) return

        const existingTagIndex = tags.indexOf(newTag)
        if (existingTagIndex === -1) {
            setState({ tags: [...tags, newTag], tag: '' })
            addTag ? setFormDirty() : null
        } else {
            const updatedTags = [...tags]
            updatedTags[existingTagIndex] = newTag
            setState({ tags: updatedTags, tag: '' })
            addTag ? setFormDirty() : null
        }
    }

    const removeTag = (tag) => {
        const updatedTags = tags.filter((tags) => tags !== tag)

        setState({ tags: updatedTags })

        addTag ? setFormDirty() : null
    }

    const filterOptions = (options, savedTags) => {
        return options.filter((option) => !savedTags.includes(option))
    }

    useEffect(() => {
        getData(true)
    }, [])

    const manufacturerCheck = activeManufacturer === manufacturerName

    return (
        <Grid container spacing={2}>
            {manufacturerCheck && (
                <Grid item xs={12} sx={{ marginBottom: 5 }}>
                    <AppBar position="static">
                        <Toolbar
                            variant="dense"
                            style={{ marginBottom: 10, marginTop: 10 }}
                        >
                            <Grid container spacing={2}>
                                {!addingTags && (
                                    <Grid item xs={12} md={1}>
                                        <Tooltip title={'Add Tags'}>
                                            <IconButton
                                                onClick={handleStartTagging}
                                            >
                                                <StyleIcon
                                                    sx={{ fontSize: 30 }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                )}

                                {addingTags && (
                                    <Grid item xs={12} md={3}>
                                        <UniversalAutoComplete
                                            name="tag"
                                            options={filterOptions(
                                                availableTags,
                                                tags
                                            )}
                                            value={tag}
                                            handleChange={
                                                handleTagSelectionChange
                                            }
                                            sx={{ width: '100%' }}
                                            label={'Tag'}
                                            id="tag-select"
                                            onTextFieldChange={handleTagChange}
                                            onBlur={saveCustomTag}
                                            clearFunction={() => {
                                                setState({ tag: '' })
                                            }}
                                            additionalFunction={saveCustomTag}
                                            customIcon={
                                                <Tooltip title="Add Tag">
                                                    <AddIcon />
                                                </Tooltip>
                                            }
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12} md={!addingTags ? 11 : 9}>
                                    {tags.legnth !== 0 &&
                                        tags?.map((tag, index) => (
                                            <Chip
                                                key={index}
                                                label={tag}
                                                onDelete={() => removeTag(tag)}
                                                icon={
                                                    <LocalOfferIcon
                                                        sx={{
                                                            fontSize: 15,
                                                        }}
                                                    />
                                                }
                                                color="default"
                                                sx={{ margin: 1 }}
                                            />
                                        ))}
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </Grid>
            )}
        </Grid>
    )
}

export default UniversalTagToolbar
