/* eslint-disable */
import { forwardRef, useRef, useReducer, ReactNode } from 'react'
import {
    Dialog,
    DialogActions,
    AppBar,
    Toolbar,
    Typography,
    Slide,
    IconButton,
    Grid,
    Breakpoint,
    Button,
    Paper,
    Box,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import {
    Close as CloseIcon,
    Minimize as MinimizeIcon,
} from '@mui/icons-material'
import Draggable from 'react-draggable'
import { relative } from 'path'

interface UniversalModalProps {
    children?: React.ReactNode
    data?: any[]
    open?: boolean
    onClose?: any
    onMinimize?: any
    onOpen?: any
    ariaLabel?: string
    ariaDescription?: string
    title?: string
    description?: string
    fullScreen?: boolean
    wrapperStyle?: any
    formTitle?: string
    sx?: any
    maxWidth?: Breakpoint
    draggable?: boolean
    minimizable?: boolean
    timeStamp?: string
    buttons?: any
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const DraggableModal = (props, { title }) => {
    const nodeRef = useRef(null)
    return (
        <Draggable
            handle={`#draggable-dialog-title`}
            cancel={'[class*="MuiDialogContent-root"]'}
            nodeRef={nodeRef}
        >
            <Paper ref={nodeRef} {...props} />
        </Draggable>
    )
}

const UniversalModal: React.FC<UniversalModalProps> = ({
    open = false,
    onClose = () => {},
    onMinimize = () => {},
    title = '',
    timeStamp = '',
    fullScreen = true,
    children,
    wrapperStyle,
    sx = {},
    maxWidth = 'lg',
    draggable = true,
    minimizable = false,
    buttons = [],
}) => {
    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={(event: any, reason: any) => {
                if (!fullScreen && reason !== 'backdropClick') {
                    return onClose
                } else {
                    return onMinimize()
                }
            }}
            TransitionComponent={Transition}
            sx={sx}
            PaperComponent={
                draggable && !fullScreen ? DraggableModal : undefined
            }
            aria-labelledby="draggable-dialog-title"
        >
            <Grid container>
                <Grid item xs={12}>
                    <AppBar
                        sx={{ position: 'relative' }}
                        id={
                            draggable && !fullScreen
                                ? `draggable-dialog-title`
                                : `non-draggable-dialog-title`
                        }
                        style={{
                            cursor: 'move',
                            background: `linear-gradient(
        104deg,
        rgba(33, 33, 33, 1) 23%,
        rgba(67, 85, 43, 1) 40%,
        rgba(68, 86, 43, 1) 60%,
        rgba(73, 94, 44, 1) 70%,
        rgba(83, 110, 47, 1) 75%,
        rgba(86, 114, 48, 1) 80%,
        rgba(91, 122, 49, 1) 85%,
        rgba(100, 137, 52, 1) 90%,
        rgba(113, 157, 56, 1) 95%,
        rgba(140, 198, 63, 0.75) 100%
    )`,
                        }}
                        enableColorOnDark
                    >
                        <Toolbar>
                            <Typography
                                sx={{ ml: 2, flex: 1 }}
                                variant="h5"
                                component="div"
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{ ml: 2, flex: 'flex', textAlign: 'right' }}
                                variant="h6"
                                component="div"
                                color={'#ffff'}
                            >
                                {timeStamp}
                            </Typography>
                            {minimizable && (
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={onMinimize}
                                    aria-label="minimize"
                                >
                                    <MinimizeIcon />
                                </IconButton>
                            )}
                            <IconButton
                                edge="end"
                                onClick={onClose}
                                aria-label="close"
                                sx={{ color: 'white' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Grid>

                <Grid item xs={12} className="universal-modal-background">
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{ marginLeft: 4, marginRight: 2 }}
                        >
                            {children}
                        </Grid>

                        <DialogActions>
                            {buttons.map((button) => (
                                <Button onClick={button.onClick}>
                                    {button.name}
                                </Button>
                            ))}
                        </DialogActions>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default UniversalModal
