import { Grid, Paper, Divider } from '@mui/material'
import { useLocation, NavLink, RouteMatch } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

type UniversalCookieCrumbType = {
    customTitle?: string
}

const UniversalCookieCrumb = ({ customTitle }: UniversalCookieCrumbType) => {
    const { pathname } = useLocation()
    let slashIndex = 0
    let indexLength: Array<number | never> = []
    const getSlashIndex = () => {
        if (pathname.length !== 0) {
            pathname.split('').map((char, index) => {
                if (char === '/') {
                    indexLength.push(index)
                    slashIndex = index
                    return slashIndex
                }
            })
        }

        return indexLength
    }

    getSlashIndex()

    const removeSlash =
        indexLength?.length >= 2
            ? [
                  `${pathname
                      .substring(1, 2)
                      .toUpperCase()}${pathname.substring(2, slashIndex)} `,
                  `${pathname
                      .substring(slashIndex + 1, slashIndex + 2)
                      .toUpperCase()}${pathname.substring(
                      slashIndex + 2,
                      pathname.length
                  )} `,
              ]
            : [
                  `${pathname
                      .substring(1, 2)
                      .toUpperCase()}${pathname.substring(
                      2,
                      pathname.length
                  )} `,
              ]

    return (
        <Grid container spacing={2} sx={{ marginBottom: 3, marginLeft: 1 }}>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography
                            color="#fff"
                            variant="h1"
                            sx={{ fontSize: 38 }}
                        >
                            {!customTitle
                                ? `${pathname
                                      .substring(slashIndex + 1, slashIndex + 2)
                                      .toUpperCase()}${pathname.substring(
                                      slashIndex + 2,
                                      pathname.length
                                  )} `.toUpperCase()
                                : customTitle.toUpperCase()}
                        </Typography>
                    </Grid>
                    <Divider
                        orientation="vertical"
                        variant="inset"
                        flexItem
                        style={{
                            position: 'relative',
                            top: 10,
                            left: -90,
                            backgroundColor: '#fff',
                        }}
                    />
                    <Grid item xs={4}>
                        <Typography
                            color="primary"
                            component="div"
                            style={{
                                width: 400,
                                position: 'relative',
                                top: 10,
                            }}
                        >
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ArrowRightIcon />}
                                sx={{
                                    fontSize: 18,
                                }}
                            >
                                <NavLink to="/" key="home.navlink">
                                    <Link
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        color="#fff"
                                        key="home.breadcrumb"
                                        component="span"
                                    >
                                        <HomeIcon
                                            sx={{ mr: 0.5 }}
                                            fontSize="inherit"
                                        />
                                        Home
                                    </Link>
                                </NavLink>
                                {removeSlash.map((link, index) => (
                                    <NavLink
                                        to={pathname}
                                        key={`${link}.navlink`}
                                    >
                                        <Link
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            key={`${link}.breadcrumb`}
                                            color="#fff"
                                            component="span"
                                        >
                                            {customTitle &&
                                            index === removeSlash.length - 1
                                                ? customTitle
                                                : link}
                                        </Link>
                                    </NavLink>
                                ))}
                            </Breadcrumbs>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UniversalCookieCrumb
