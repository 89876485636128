import { useRef } from 'react'
import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    ArcElement,
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    Title,
    CategoryScale,
    BarElement,
    BarController,
} from 'chart.js'
import {
    Chart,
    getDatasetAtEvent,
    getElementAtEvent,
    getElementsAtEvent,
    Pie,
    Doughnut,
} from 'react-chartjs-2'
ChartJS.register(
    ArcElement,
    LineController,
    LinearScale,
    CategoryScale,
    BarElement,
    BarController,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title
)

const options = {
    scales: {
        y: {
            beginAtZero: true,
        },
    },
}

const generateGraphColors = (telemetryType, footerLabels) => {
    if (telemetryType.toLowerCase() === 'ignition') {
        return {
            backgroundColor: footerLabels.map(
                (label) => 'rgba(255, 99, 132, 0.2)'
            ),
            borderColor: footerLabels.map((label) => 'rgba(255, 99, 132, 1)'),
        }
    } else if (telemetryType.toLowerCase() === 'sleep') {
        return {
            backgroundColor: footerLabels.map(
                (label) => 'rgba(165, 55, 253, 0.2)'
            ),
            borderColor: footerLabels.map((label) => 'rgba(165, 55, 253, 1)'),
        }
    } else if (telemetryType.toLowerCase() === 'combined') {
        return {
            backgroundColor: footerLabels.map(
                (label) => 'rgba(127,255,0, 0.2)'
            ),
            borderColor: footerLabels.map((label) => 'rgb(127,255,0)'),
        }
    }
}

const generateLineGraphColors = (telemetryType) => {
    if (telemetryType.toLowerCase() === 'ignition') {
        return {
            borderColor: 'rgba(255, 99, 132, 1)',
        }
    } else if (telemetryType.toLowerCase() === 'sleep') {
        return {
            borderColor: 'rgba(165, 55, 253, 1)',
        }
    } else if (telemetryType.toLowerCase() === 'combined') {
        return {
            borderColor: 'rgb(127,255,0)',
        }
    }
}
interface generateLabelsParams {
    telemetry: any
    rawSignal: string
    prefix?: string
    suffix?: string
}
const generateLabels = ({
    telemetry,
    rawSignal,
    prefix,
    suffix,
}: generateLabelsParams) => {
    const labels: any = []
    if (telemetry && telemetry?.length !== 0) {
        telemetry.forEach(({ data }) => {
            if (prefix) {
                if (!labels.includes(`${prefix}: ${data[rawSignal]}`)) {
                    labels.push(`${prefix}: ${data[rawSignal]}`)
                    return labels
                }
            } else {
                if (!labels.includes(`${data[rawSignal]}${suffix}`)) {
                    labels.push(`${data[rawSignal]}${suffix}`)
                    return labels
                }
            }
        })
    }
    return labels
}

interface BarChartProps {
    telemetry: any
    footerLabels: string[]
    label: string
    rawSignal: string
    telemetryType: string
}

export const BarChart = ({
    label,
    footerLabels,
    telemetry,
    rawSignal,
    telemetryType,
}: BarChartProps) => {
    const chartRef = useRef<ChartJS>(null)
    const data = [
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) === 0
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 0 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 2
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 2 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 4
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 4 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 6
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 6 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 8
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 8 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 10
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 10 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 12
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 12 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 14
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 14 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 16
        ).length,
    ]

    const chartData = {
        labels: footerLabels,
        datasets: [
            {
                label,
                data: data,
                borderWidth: 1,
                ...generateGraphColors(telemetryType, footerLabels),
            },
        ],
    }

    return (
        <Chart
            ref={chartRef}
            type="bar"
            options={options}
            data={chartData}
            key={`${label}.Chart`}
        />
    )
}

export const EngineHoursHistogram = ({
    label,
    telemetry,
    rawSignal,
    telemetryType,
}) => {
    const chartRef = useRef<ChartJS>(null)
    const labels = ['0hr', '0-10hr', '10-50hr', '50-100hr', '100hr+']

    const data = [
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) === 0
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 0 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 10
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 10 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 50
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 50 &&
                Math.floor(parseInt(data?.[rawSignal])) <= 100
        ).length,
        telemetry?.filter(
            ({ data }) =>
                data &&
                data?.[rawSignal] &&
                Math.floor(parseInt(data?.[rawSignal])) > 100
        ).length,
    ]

    const chartData = {
        labels: labels,
        datasets: [
            {
                label,
                data: data,
                borderWidth: 1,
                tension: 0.1,
                ...generateLineGraphColors(telemetryType),
            },
        ],
    }

    return (
        <Chart
            ref={chartRef}
            type="line"
            options={options}
            data={chartData}
            key={`${label}.Chart`}
        />
    )
}

interface DonutBoatModelGraphProps {
    telemetry: any
}

export const DonutBoatModelGraph = ({
    telemetry,
}: DonutBoatModelGraphProps) => {
    const mapLabels = telemetry
        .map(({ boat }) => boat.boatModel.model)
        .filter((model) => model !== undefined)
    const dataSets: any = [...new Set(mapLabels)]

    const data = dataSets.map((label) => ({
        label,
        count: telemetry?.filter(({ boat }) => boat?.boatModel?.model === label)
            .length,
        hin: telemetry
            .filter(({ boat }) => boat.boatModel.model === label)
            .map(({ fullHin }) => fullHin),
    }))

    const borderColor = [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)',
    ]

    const backgroundColor = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)',
    ]

    const donutChartData = {
        labels: data.map((data) => data.label),
        datasets: [
            {
                label: 'Boats by Model',
                data: data.map((label) => label.count),
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1,
            },
        ],
    }

    return (
        <div>
            <Doughnut data={donutChartData} />
        </div>
    )
}

interface DonutBoatManufacturerGraphProps {
    telemetry: any
}

export const DonutBoatManufacturerGraph = ({
    telemetry,
}: DonutBoatModelGraphProps) => {
    const mapLabels = telemetry
        .map(({ boat }) => boat?.manufacturer?.manufacturerName)
        .filter((manufacturer) => manufacturer !== undefined)
    const dataSets: any = [...new Set(mapLabels)]

    const data = dataSets.map((label) => ({
        label,
        count: telemetry?.filter(
            ({ boat }) => boat?.manufacturer?.manufacturerName === label
        ).length,
        hin: telemetry
            .filter(
                ({ boat }) => boat?.manufacturer?.manufacturerName === label
            )
            .map(({ fullHin }) => fullHin),
    }))

    const borderColor = [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)',
    ]

    const backgroundColor = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)',
    ]

    const donutChartData = {
        labels: data.map((data) => data.label),
        datasets: [
            {
                label: 'Boats by Manufacturer',
                data: data.map((label) => label.count),
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 1,
            },
        ],
    }

    return (
        <div>
            <Doughnut data={donutChartData} />
        </div>
    )
}
