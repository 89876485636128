import { useReducer, useEffect } from 'react'
import { UniversalCookieCrumb, DataGridLayout } from '../../UniversalComponents'
import { useMinimizer } from '../../../context/MinimizeContext'
import NotificationManager from './SignalEventManager'
import UnitOfMeasureManager from './UnitOfMeasureManager'
import SignalManager from './SignalManager'
import { Grid, useTheme, AppBar, Tab, Tabs } from '@mui/material'
import SignalProfileManager from './SignalProfileManager'
import SignalFormulaManager from './SignalFormulaManager'

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <Grid
            container
            key={`${value}-${index}`}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid container>
                    <Grid item xs={12} padding={2}>
                        {children}
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

const Signals = () => {
    const reducer = (state, newState) => ({ ...state, ...newState })

    const defaultState = {
        currentTab: 0,
        tabName: '',
    }

    const [state, setState] = useReducer(reducer, defaultState)

    const { currentTab, tabName } = state

    const tabs = [
        'Signal Manager',
        'Signal Event Manager',
        'Unit Of Measurement Manager',
        'Signal Profile Manager',
        'Signal Formula Manager',
    ]

    const setCurrentTab = (event: React.SyntheticEvent, newValue: number) =>
        setState({ currentTab: newValue, tabName: tabs[newValue] })

    const theme = useTheme()
    const { restoredData } = useMinimizer()

    useEffect(() => {
        if (restoredData?.id !== undefined && restoredData?.tab === 'signals') {
            setState({ currentTab: 0 })
        }
        if (restoredData?.id !== undefined && restoredData?.tab === 'events') {
            setState({ currentTab: 1 })
        }
        if (
            restoredData?.id !== undefined &&
            restoredData?.tab === 'unitOfMeasurement'
        ) {
            setState({ currentTab: 2 })
        }
    }, [restoredData])

    return (
        <DataGridLayout
            cookieCrumbs={<UniversalCookieCrumb customTitle={tabName} />}
            tabBar={
                <Tabs
                    value={currentTab}
                    onChange={setCurrentTab}
                    indicatorColor="secondary"
                    textColor="primary"
                    variant="scrollable"
                    aria-label="Osmosis"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    {tabs.map((label) => (
                        <Tab label={label} key={`${label}`} />
                    ))}
                </Tabs>
            }
            dataGrid={
                <>
                    <TabPanel
                        value={currentTab}
                        index={0}
                        dir={theme.direction}
                    >
                        <SignalManager restoredData={restoredData} />
                    </TabPanel>

                    <TabPanel
                        value={currentTab}
                        index={1}
                        dir={theme.direction}
                    >
                        <NotificationManager restoredData={restoredData} />
                    </TabPanel>
                    <TabPanel
                        value={currentTab}
                        index={2}
                        dir={theme.direction}
                    >
                        <UnitOfMeasureManager restoredData={restoredData} />
                    </TabPanel>
                    <TabPanel
                        value={currentTab}
                        index={3}
                        dir={theme.direction}
                    >
                        <SignalProfileManager restoredData={restoredData} />
                    </TabPanel>
                    <TabPanel
                        value={currentTab}
                        index={4}
                        dir={theme.direction}
                    >
                        <SignalFormulaManager restoredData={restoredData} />
                    </TabPanel>
                </>
            }
        />
    )
}

export default Signals
