import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import { useContext, useEffect, useReducer } from 'react'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import { Authentication } from '../../../../context/AuthenticationContext'
import {
    addEngine,
    getManufacturers,
    addEngineCalibrationFile,
    unlinkTag,
} from '../../../../apiRoutes'
import { addTag } from '../../../../apiRoutes'

import UniversalTagToolbar from '../../../UniversalComponents/UniversalTagToolbar'
import { FormSection, SaveButton } from '../../../UniversalComponents'
import responseError from '../../../../context/responseError/responseError'
import {
    basicValidator,
    parseFormWithFile,
} from '../../../../utilities/functionTools'

import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import EngineCalibration from './EngineCalibration'

interface engineManager {
    row: any
    onClose: any
    saveCache: any
    permissionCheck: any
    quickStart: any
    blobInfo?: any
    calibrationData?: any
    deleteBlob?: any
    successfullBlobFileDeletion?: Boolean
    resetSuccessfulBlobFileDeletion?: any
}

const EngineManagerForm = ({
    row,
    onClose,
    saveCache,
    permissionCheck,
    quickStart,
    blobInfo,
    calibrationData,
    deleteBlob,
    successfullBlobFileDeletion,
    resetSuccessfulBlobFileDeletion,
}: engineManager) => {
    const setToastMessage = useContext(ToastMessage)
    const currentUser = useContext(Authentication)

    const { userRole, activeManufacturer, user, username } = currentUser.auth
    const defaultState = {
        formData: {
            engineName: row?.engineName || '',
            engineIdentifier: row?.engineIdentifier || '',
            engineDescription: row?.engineDescription || '',
            engineCalibrationFiles: row?.engineCalibrationFiles || [],
            activeEngineCalibrationFile: row?.activeEngineCalibrationFile || {},
            tags: [],
            updatedBy: row?.updatedBy || '',
            id: row.id || '',
            manufacturer: row?.id
                ? row.manufacturer?.manufacturerName
                : activeManufacturer,
        },
        formValidation: {
            engineSerialValidation: false,
            hardwarePartNumber: false,
            manufacturerValidation: false,
            engineIdentifierValidation: false,
            calibrationValidation: false,
            engineNameValidation: false,
            isFormDirty: false,
            isFormValid: false,
        },
        newEngineCalibrationFiles: [],
        closeModal: false,
        manufacturers: [],
        manufacturerOptions: [],
        formCache: {},
        permissionsCheck: permissionCheck,
        etag: '',
        title: '',
        blobUrl: '',
        calData: calibrationData,
        entryId: undefined,
        successfulSubmission: false,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formData,
        formValidation,
        manufacturerOptions,
        manufacturers,
        newEngineCalibrationFiles,
        etag,
        title,
        blobUrl,
        calData,
        entryId,
        successfulSubmission,
    } = state

    const {
        engineName,
        engineDescription,
        engineIdentifier,
        manufacturer,
        engineCalibrationFiles,
        activeEngineCalibrationFile,
        tags,
    } = formData

    const {
        manufacturerValidation,
        engineNameValidation,
        engineIdentifierValidation,
        calibrationValidation,
        isFormDirty,
        isFormValid,
    } = formValidation

    const handleTagSubmit = async (tagData, removedTags) => {
        try {
            if (successfulSubmission) {
                const tag = await addTag({ tagData, entryId })

                if (tag) {
                    const { data, status } = tag

                    if (data && status === 200) {
                        console.log('Tag updated successfully')
                    } else {
                        responseError(tag, row)
                    }
                }

                if (removedTags && removedTags.length > 1) {
                    const removeTags: any = await unlinkTag({ removedTags })

                    if (removeTags) {
                        const { data, status } = removeTags

                        if (data && status === 200) {
                            console.log('Tag removed successfully')
                        } else {
                            responseError(removeTags, row)
                        }
                    }
                }
                setState({ successfulSubmission: false })
                onClose(row.engineIdentifier, row)
            }
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleSubmit = async () => {
        try {
            const findManufacturer = user?.activeManufacturer?._id

            const parsedFormData = {
                ...formData,
                manufacturer: findManufacturer,
                updatedBy: username,
            }

            let res: any

            res = await addEngine(parsedFormData)
            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `Engine: ${data.engineName} was ${
                            row.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )

                    setState({ entryId: data?._id, successfulSubmission: true })
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleReset = () => {
        if (etag.length !== 0 && title.length !== 0 && blobUrl.length !== 0) {
            deleteBlob(true, true)
        } else {
            const clonedFiles = [...calData]
            setState({
                formData: {
                    ...defaultState.formData,
                    engineCalibrationFiles: clonedFiles,
                },
                formValidation: defaultState.formValidation,
            })
        }
    }

    useEffect(() => {
        if (successfullBlobFileDeletion) {
            const clonedFiles = [...calData]

            setState({
                formData: {
                    ...defaultState.formData,
                    engineCalibrationFiles: clonedFiles,
                },
                formValidation: defaultState.formValidation,
                etag: '',
                title: '',
                blobUrl: '',
            })
            resetSuccessfulBlobFileDeletion()
        }
    }, [successfullBlobFileDeletion])

    const handleChange = (e: any) => {
        const { name, value } = e.target

        setState({
            formData: { ...formData, [name]: value },
            formValidation: { ...formValidation, isFormDirty: true },
        })

        saveCache({ ...formData, [name]: value })
    }

    const getData = async () => {
        let manufacturers: any = []
        let manufacturerOptions: any = []

        try {
            const getManufacturerData = await getManufacturers(true)
            if (getManufacturerData && getManufacturerData.length !== 0) {
                manufacturers = getManufacturerData.filter(
                    (manufacturer) => manufacturer.manufacturerType === 'engine'
                )
                manufacturerOptions = manufacturers.map(
                    ({ manufacturerName }) => manufacturerName
                )
            }

            setState({
                manufacturers,
                manufacturerOptions,
            })
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const addCalibrationFile = async (calibrationFormData, files) => {
        try {
            const newEngineCalibrations = (
                oldCalibrationFormData,
                newCalibrationFormData
            ) => {
                const index = oldCalibrationFormData.findIndex(
                    (calibrationFile) =>
                        calibrationFile._id === newCalibrationFormData.id ||
                        calibrationFile.id === newCalibrationFormData.id
                )

                if (index !== -1) {
                    oldCalibrationFormData[index] = newCalibrationFormData
                } else {
                    oldCalibrationFormData.push(newCalibrationFormData)
                }

                return oldCalibrationFormData
            }

            const updatedEngineCalibrationFiles = newEngineCalibrations(
                formData.engineCalibrationFiles,
                calibrationFormData
            )

            let newCalibrations
            if (calibrationFormData.engineCalibrationActive) {
                newCalibrations = updatedEngineCalibrationFiles.map((file) => {
                    if (
                        file.id !== calibrationFormData.id &&
                        file.engineCalibrationActive
                    ) {
                        return { ...file, engineCalibrationActive: false }
                    }
                    return file
                })

                setState({
                    formData: {
                        ...formData,
                        activeEngineCalibrationFile: calibrationFormData,
                        engineCalibrationFiles: newCalibrations,
                    },
                    formValidation: { ...formValidation, isFormDirty: true },
                })
            } else {
                setState({
                    formData: {
                        ...formData,
                        engineCalibrationFiles: updatedEngineCalibrationFiles,
                    },
                    formValidation: { ...formValidation, isFormDirty: true },
                })
            }

            const clonedFiles = new FormData()
            files.forEach((file, key) => {
                if (key === 'file') {
                    clonedFiles.append(key, file)
                }
            })
            const calibrationFile = [clonedFiles.get('file')]

            const findManufacturer = user?.activeManufacturer?._id
            const newFormData = {
                ...calibrationFormData,
                manufacturer: findManufacturer,
            }

            const parsedFormData = parseFormWithFile(
                newFormData,
                calibrationFile
            )
            const res = await addEngineCalibrationFile(parsedFormData)

            if (res && res.status === 200) {
                const { data } = res

                if (data?.engineCalibrationFileUrl && data?.id) {
                    const finalCalibrationFiles = newCalibrations
                        ? newCalibrations.map((file) => {
                              if (file.id === data.id) {
                                  return {
                                      ...file,
                                      engineCalibrationFileUrl:
                                          data?.engineCalibrationFileUrl,
                                      engineCalibrationMD5:
                                          data?.engineCalibrationMD5,
                                  }
                              }

                              return file
                          })
                        : engineCalibrationFiles.map((file) => {
                              if (file.id === data.id) {
                                  return {
                                      ...file,
                                      engineCalibrationFileUrl:
                                          data?.engineCalibrationFileUrl,
                                      engineCalibrationMD5:
                                          data?.engineCalibrationMD5,
                                  }
                              }

                              return file
                          })

                    setState({
                        etag: data?.etag,
                        title: data?.title,
                        blobUrl: data?.engineCalibrationFileUrl,
                        formData: {
                            ...formData,
                            engineCalibrationFiles: finalCalibrationFiles,
                        },
                    })

                    const setBlobInfo = {
                        etag: data?.etag,
                        title: data?.title,
                        url: data?.engineCalibrationFileUrl,
                    }

                    blobInfo(setBlobInfo)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    useEffect(() => {
        getData()
        saveCache(row)
    }, [])

    useEffect(() => {
        if (
            isFormDirty &&
            !engineIdentifierValidation &&
            !manufacturerValidation &&
            !engineNameValidation &&
            !calibrationValidation &&
            engineIdentifier &&
            manufacturer &&
            engineName &&
            engineCalibrationFiles
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        manufacturerValidation,
        engineNameValidation,
        engineIdentifierValidation,
        calibrationValidation,
        manufacturer,
        engineName,
        engineIdentifier,
        engineCalibrationFiles,
    ])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ marginTop: 4, marginBottom: -3 }}>
                <UniversalTagToolbar
                    row={row}
                    pageType="engines"
                    addTag={handleTagSubmit}
                    entryId={entryId}
                    setFormDirty={() =>
                        setState({
                            formValidation: {
                                ...formValidation,
                                isFormValid: true,
                            },
                        })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <FormSection title="Engine Details">
                    <Grid item xs={12} md={3}>
                        <Autocomplete
                            disablePortal
                            id="manufacturer"
                            options={manufacturerOptions}
                            value={manufacturer}
                            fullWidth
                            disabled={true}
                            onChange={(event, newValue) => {
                                const data = {
                                    target: {
                                        name: 'manufacturer',
                                        value: newValue,
                                    },
                                }
                                handleChange(data)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Engine Manufacturer"
                                    name="manufacturer"
                                    onChange={handleChange}
                                    required
                                    disabled={
                                        permissionCheck ||
                                        userRole !== 'Super Admin'
                                    }
                                    value={manufacturer}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={manufacturerValidation}
                                    helperText={
                                        manufacturerValidation
                                            ? 'Please enter a manufacturer name.'
                                            : ''
                                    }
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            required={true}
                            fullWidth
                            label="Engine Name"
                            name="engineName"
                            onChange={handleChange}
                            value={engineName}
                            onBlur={(e: any) =>
                                basicValidator(e, formValidation, setState)
                            }
                            error={engineNameValidation}
                            helperText={
                                engineNameValidation
                                    ? 'Please enter a Engine Name.'
                                    : ''
                            }
                            disabled={permissionCheck}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required={true}
                            fullWidth
                            label="Engine Identifier"
                            name="engineIdentifier"
                            onChange={handleChange}
                            value={engineIdentifier}
                            onBlur={(e: any) =>
                                basicValidator(e, formValidation, setState)
                            }
                            error={engineIdentifierValidation}
                            helperText={
                                engineIdentifierValidation
                                    ? 'Please enter an Engine Identifier.'
                                    : ''
                            }
                            disabled={permissionCheck}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            fullWidth
                            label="Engine Description"
                            name="engineDescription"
                            onChange={handleChange}
                            value={engineDescription}
                            disabled={permissionCheck}
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12}>
                <FormSection
                    title="Engine Calibration"
                    titleBottom={
                        <Grid item xs={12}>
                            <TipsAndUpdatesIcon
                                sx={{
                                    fontSize: 20,
                                }}
                            />
                            Please save the form for any calibration file
                            changes to take effect
                        </Grid>
                    }
                    titleBottomAlignment="left"
                >
                    <Grid item xs={12}>
                        <EngineCalibration
                            engineCalibrationFiles={engineCalibrationFiles}
                            activeEngineCalibrationFile={
                                activeEngineCalibrationFile
                            }
                            addCalibrationFile={addCalibrationFile}
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item xs={12} md={12}>
                <SaveButton
                    title="Required Fields Are Marked With An *"
                    manufacturer={row.manufacturerName}
                    titleAlignment="center"
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                    disabledSave={!isFormValid || permissionCheck}
                    disabledReset={!isFormDirty || permissionCheck}
                    quickStart={quickStart}
                    resetVariant="contained"
                />
            </Grid>
        </Grid>
    )
}

export default EngineManagerForm
