import { useReducer, createContext, useContext } from 'react'

import { UniversalModal } from '../../components/UniversalComponents'
import { permissionsCheck } from '../../utilities/functionTools'

import { useToastMessage } from '../ToastPopUpContext'
import { usePermissions } from '../AuthenticationContext'
import QuickStartMenu from './Steps'
type Props = {
    children: React.ReactNode
}

export const QuickStartWizardProvider = createContext({
    showQuickStartWizard: false,
    setQuickStartWizard: (open: boolean) => {},
})

export const QuickStartWizardContext = ({ children }: Props) => {
    const defaultState = {
        showQuickStartWizard: false,
        type: 'boat',
        step: 1,
    }
    const setToastMessage = useToastMessage()
    const reducer = (oldState, newState) => ({ ...oldState, ...newState })
    const [state, setState] = useReducer(reducer, defaultState)
    const { showQuickStartWizard, step, type } = state
    const setQuickStartWizard = (open: boolean) =>
        setState({ showQuickStartWizard: open })
    const setStart = (type: string, step: number) => setState({ type, step })
    const setNextStep = (step: number) => setState({ step })
    const handleCloseQuickstartWizard = () => {
        setState(defaultState)
        setToastMessage(
            'Quickstart wizard closed. Hit quickstart to begin again.'
        )
    }
    const setCloseCheck = () =>
        setToastMessage(
            `Are you sure you want to close the wizard? All progress will be lost.`,
            'warning',
            handleCloseQuickstartWizard
        )
    const userPermissions = usePermissions()
    const permissionCheck =
        type === 'engine'
            ? permissionsCheck(userPermissions, 'Engines')
            : permissionsCheck(userPermissions, 'Boats')
    return (
        <QuickStartWizardProvider.Provider
            value={{ showQuickStartWizard, setQuickStartWizard }}
        >
            {children}
            <UniversalModal
                title="Quick Start Wizard"
                open={showQuickStartWizard}
                onClose={setCloseCheck}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 10,
                    marginBottom: 5,
                }}
            >
                <QuickStartMenu
                    setStart={setStart}
                    step={step}
                    permissionsCheck={permissionCheck}
                    type={type}
                    setNextStep={setNextStep}
                    endQuickStart={handleCloseQuickstartWizard}
                />
            </UniversalModal>
        </QuickStartWizardProvider.Provider>
    )
}

export const useQuickStartWizard = () => useContext(QuickStartWizardProvider)
