import buildRoute from './buildRoute'

export const getCDNURLS = async () => {
    try {
        let res: any
        res = await buildRoute({
            type: 'post',
            route: 'cdn-urls',
            data: { active: true },
        })
        if (res.data) {
            return res.data
        }
    } catch (err: any) {
        return console.error(err)
    }
}
