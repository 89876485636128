import { Grid, Typography } from '@mui/material'
import {
    FormSection,
    UniversalLoadingCircle,
    UniversalModal,
    UniversalToolBar,
} from '../../../UniversalComponents'
import UniversalDataTable from '../../../UniversalComponents/UniversalDataTable'
import { useContext, useEffect, useReducer } from 'react'
import SignalFormulaManagerForm from './SignalFormulaManagerForm'
import {
    Sensors as SensorsIcon,
    Archive as ArchiveIcon,
    Restore,
    Delete as DeleteIcon,
    Margin as MarginIcon,
} from '@mui/icons-material'
import { useMinimizer } from '../../../../context/MinimizeContext'
import {
    Authentication,
    useAuth,
} from '../../../../context/AuthenticationContext'
import {
    getSignalFormulas,
    activateSignalFormulas,
    deleteSignalFormulas,
} from '../../../../apiRoutes'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import {
    standardColumnMapper,
    handleActivation,
    handleDeletion,
} from '../../../../utilities/functionTools'
import { permissionsCheck } from '../../../../utilities/functionTools'
import { usePermissions } from '../../../../context/AuthenticationContext'

const today = new Date().toISOString().substring(0, 10)

const SignalFormulaManager = ({ restoredData }) => {
    const currentUser = useContext(Authentication)
    const { username, userRole, activeManufacturer, userPreferences } =
        currentUser.auth
    const { setUser } = useAuth()
    const userPermissions = usePermissions()

    const reducer = (state, newState) => ({ ...state, ...newState })
    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        formCache: {},
        data: [],
        viewDeactivated: false,
        activateData: [],
        signals: {
            signalFormulaManager: {
                columns: [
                    {
                        field: 'signalFormulaName',
                        headerName: 'FORMULA NAME',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'signalFormulaType',
                        headerName: 'FORMULA TYPE',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'updatedAt',
                        headerName: 'UPDATE AT',
                        width: 150,
                        type: 'string',
                    },
                    {
                        field: 'manufacturerName',
                        headerName: 'MANUFACTURER',
                        width: 150,
                        type: 'string',
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.signals?.signalFormulaManager
                ?.density || 'standard',
    }

    const [state, setState] = useReducer(reducer, defaultState)
    const setToastMessage = useContext(ToastMessage)

    const {
        open,
        row,
        formCache,
        data,
        viewDeactivated,
        activateData,
        signals,
        density,
    } = state

    const { signalFormulaManager } = signals

    const { setMinimized } = useMinimizer()

    const getData = async (active: boolean) => {
        try {
            const getSignalFormulaData = await getSignalFormulas(active)

            if (getSignalFormulaData && getSignalFormulaData.length !== 0) {
                const dataMapping = standardColumnMapper(
                    defaultColumns,
                    getSignalFormulaData
                )

                setState({
                    data: dataMapping,
                })
            } else {
                setState({
                    data: [],
                })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const data =
            userPreferences?.dataTableColumns?.signals?.signalFormulaManager
                ?.columns

        if (Array.isArray(data)) {
            const mapColumns = data.map((item) => ({
                field: item?.field,
                headerName: item?.headerName,
                width: item?.width,
                isVisible: item.isVisible,
                type: item?.type,
            }))

            const format = {
                signalFormulaManager: {
                    columns: mapColumns,
                },
            }

            setState({ ...state, signals: format })
        }
    }

    const submitColumnData = async () => {
        try {
            setUser({
                key: 'signals',
                value: signals,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'signalFormulaName',
            setState,
            getData,
            route: activateSignalFormulas,
            viewDeactivated,
            setToastMessage,
            page: 'signalFormulas',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'signalFormulaName',
            setState,
            getData,
            route: deleteSignalFormulas,
            viewDeactivated,
            setToastMessage,
            page: 'signalFormulas',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these signals?`,
            'warning',
            handleDeletionData
        )
    }

    useEffect(() => {
        collectColumnData()
        getData(true)
        setMinimized(false, restoredData.id, true)
    }, [])

    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.signalFriendlyName
                ? `Edit: ${formCache.signalFriendlyName}`
                : 'Add New Signal',
            link: '/data-hub/signals',
            data: formCache.id
                ? { ...formCache, tab: 'signals' }
                : { ...formCache, id: 'newSignal', tab: 'signals' },
            id: formCache.id ? formCache.id : 'newSignal',
        })
        setState({ open: false })
    }

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?._id) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        getData(!viewDeactivated)
    }

    const setViewDeactivated = () =>
        setState({ viewDeactivated: !viewDeactivated })

    const buttons = [
        {
            buttonName: 'Add New Signal Formula',
            buttonIcon: <MarginIcon />,
            buttonFunction: () => setState({ open: !open }),
            disabled: permissionsCheck(userPermissions, 'Signals'),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
            disabled: permissionsCheck(userPermissions, 'Signals'),
        },
        {
            buttonName: !viewDeactivated
                ? 'View Deactivated Signal Formulas'
                : 'View Active Signal Formulas',
            buttonIcon: <Restore />,
            buttonFunction: setViewDeactivated,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteIcon />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
    ]

    const defaultColumns = [
        'signalFormulaName',
        'signalFormulaType',
        'updatedAt',
        'manufacturer.manufacturerName',
    ]

    const visibleColumns = [
        'signalFormulaName',
        'signalFormulaType',
        'updatedAt',
        'manufacturerName',
    ]

    const customColumnNames = [
        'Formula Name',
        'Formula Type',
        'Updated At',
        'Manufacturer',
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
            type: col.type,
        }))

        const setDensity = state.density

        const setData = {
            signalFormulaManager: { columns: format, density: setDensity },
        }

        setState({
            signals: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            signals: {
                signalFormulaManager: {
                    ...signalFormulaManager,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !viewDeactivated
                            ? 'No Active Signal Formulas Available'
                            : 'No Deactivated Signal Formulas Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        apiDensity={density}
                        apiColumns={signals?.signalFormulaManager?.columns}
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        customColumnNames={customColumnNames}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(rowSelection: any) => {
                            setState({ activateData: rowSelection })
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>
            </Grid>

            <UniversalModal
                title={`${
                    row.signalFormulaName
                        ? ` ${row.signalFormulaName}`
                        : 'Signal Formula Manager'
                }`}
                timeStamp={`${
                    row.updatedBy
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
                onMinimize={minimizeModal}
                draggable={true}
                minimizable={true}
            >
                <SignalFormulaManagerForm
                    row={row}
                    formDescription={''}
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Signals'
                    )}
                />
            </UniversalModal>
        </Grid>
    )
}

export default SignalFormulaManager
