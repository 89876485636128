import { Grid, Stack, Typography, CircularProgress } from '@mui/material'
import { FormSection } from '../../../../UniversalComponents'
import {
    BarChart,
    EngineHoursHistogram,
} from '../../../../Analytics/FleetAnalytics/FleetAnalyticsCharts'
const BoatConfigInsightsHistogram = ({ loading, telemetry }) => {
    const batteryFooterLabels = [
        '0v',
        '2v',
        '6v',
        '8v',
        '10v',
        '12v',
        '14v',
        '16v',
    ]
    const engineSpeedFooterLabels = [
        '0rpm',
        '1000rpm',
        '2000rpm',
        '3000rpm',
        '4000rpm',
        '5000rpm',
        '6000rpm',
        '7000rpm',
        '8000rpm',
    ]
    return (
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
                {loading ? (
                    <Grid xs={12} item>
                        <Stack
                            sx={{
                                color: 'grey.500',
                                direction: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: 25,
                            }}
                            spacing={2}
                            direction="row"
                        >
                            <Typography color={'primary'} variant="h4">
                                Generating Charts...
                            </Typography>

                            <CircularProgress color="primary" size={100} />
                        </Stack>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Typography color={'primary'} variant="h4">
                                Total Combined Ignition and Sleep Entries:{' '}
                                {telemetry.length}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSection title="Cumulative Battery 1">
                                <Grid item xs={12} md={12}>
                                    <BarChart
                                        telemetry={telemetry}
                                        rawSignal="Analog_Input_1"
                                        telemetryType={'combined'}
                                        label="Battery 1"
                                        footerLabels={batteryFooterLabels}
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSection title="Cumulative Battery 2">
                                <Grid item xs={12} md={12}>
                                    <BarChart
                                        telemetry={telemetry}
                                        rawSignal="Analog_Input_2"
                                        telemetryType={'combined'}
                                        label="Battery 2"
                                        footerLabels={batteryFooterLabels}
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSection title="Cumulative Battery 3">
                                <Grid item xs={12} md={12}>
                                    <BarChart
                                        telemetry={telemetry}
                                        rawSignal="Analog_Input_3"
                                        telemetryType={'combined'}
                                        label="Battery 3"
                                        footerLabels={batteryFooterLabels}
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSection title="Cumulative Engine Hours">
                                <Grid item xs={12} md={12}>
                                    <EngineHoursHistogram
                                        telemetry={telemetry}
                                        rawSignal="Totalenginehours_0"
                                        telemetryType={'combined'}
                                        label="Engine Hours"
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSection title="Cumulative Engine Speeds">
                                <Grid item xs={12} md={12}>
                                    <BarChart
                                        telemetry={telemetry}
                                        rawSignal="EngineSpeed_0"
                                        telemetryType={'combined'}
                                        label="Engine Speed"
                                        footerLabels={engineSpeedFooterLabels}
                                    />
                                </Grid>
                            </FormSection>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

export default BoatConfigInsightsHistogram
