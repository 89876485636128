import { Grid, Typography } from '@mui/material'

import UniversialPatchNotesForm from '../../../UniversalComponents/UniversalPatchNoteForm'
import {
    Authentication,
    useAuth,
    usePermissions,
} from '../../../../context/AuthenticationContext'
import {
    Archive as ArchiveIcon,
    Restore,
    DeleteForever,
} from '@mui/icons-material/'
import EditNoteIcon from '@mui/icons-material/EditNote'
import { useContext, useEffect, useReducer } from 'react'
import { useMinimizer } from '../../../../context/MinimizeContext'
import { ToastMessage } from '../../../../context/ToastPopUpContext'
import {
    FormSection,
    UniversalDataTable,
    UniversalLoadingCircle,
    UniversalModal,
    UniversalToolBar,
} from '../../../UniversalComponents'
import {
    handleActivation,
    handleDeletion,
    permissionsCheck,
    standardColumnMapper,
} from '../../../../utilities/functionTools'
import {
    deactivatePatchNote,
    deletePatchNote,
    getPatchNotes,
} from '../../../../apiRoutes'

const today = new Date().toISOString().substring(0, 10)

const OsIndex = ({ restoredData }) => {
    const reducer = (state, newState) => ({ ...state, ...newState })

    const currentUser = useAuth()
    const { setUser } = useAuth()
    const setToastMessage = useContext(ToastMessage)
    const { username, userRole, activeManufacturer, userPreferences, _id } =
        currentUser.auth

    const defaultState = {
        open: restoredData.id === undefined ? false : true,
        row: restoredData.id === undefined ? {} : restoredData,
        formCache: {},
        viewArchived: false,
        archive: [],
        viewDeactivated: false,
        activateData: [],
        data: [],
        system: {
            osPatchNotes: {
                columns: [
                    {
                        field: 'version',
                        headerName: 'VERSION',
                        width: 150,
                    },
                    {
                        field: 'releaseDate',
                        headerName: 'RELEASE DATE',
                        width: 150,
                    },
                    {
                        field: 'upcomingPatchVersion',
                        headerName: 'UPCOMING PATCH VERSION',
                        width: 150,
                    },
                    {
                        field: 'upcomingPatchDetails',
                        headerName: 'UPCOMING PATCH DETAILS',
                        width: 150,
                    },
                ],
                density: 'standard',
            },
        },
        density:
            userPreferences?.dataTableColumns?.system?.osPatchNotes?.density ||
            'standard',
    }

    const [state, setState] = useReducer(reducer, defaultState)

    const {
        open,
        row,
        formCache,
        viewDeactivated,
        data,
        activateData,
        system,
        density,
    } = state

    const { osPatchNotes } = system

    const { setMinimized } = useMinimizer()
    const userPermissions = usePermissions()

    const getData = async (active) => {
        try {
            const patchNotes = await getPatchNotes(active, 'web')
            if (patchNotes && patchNotes.length !== 0) {
                const notes = standardColumnMapper(defaultColumns, patchNotes)

                setState({ data: notes })
            } else {
                setState({ data: [] })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const collectColumnData = () => {
        const system =
            userPreferences?.dataTableColumns?.system?.osPatchNotes?.columns

        if (Array.isArray(system)) {
            const mapColumns = system.map((col) => ({
                field: col?.field,
                headerName: col?.headerName,
                width: col?.width,
                isVisible: col?.isVisible,
            }))

            const format = {
                osPatchNotes: {
                    columns: mapColumns,
                },
            }

            setState({ ...state, system: format })
        }
    }
    const submitColumnData = async () => {
        try {
            setUser({
                key: 'system',
                value: system,
                type: 'columnData',
            })
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const handleActivateData = () => {
        handleActivation({
            activateData,
            data,
            returnKey: 'version',
            setState,
            getData,
            route: deactivatePatchNote,
            viewDeactivated,
            setToastMessage,
            page: 'notes',
        })
    }

    const handleDeletionData = () => {
        handleDeletion({
            activateData,
            data,
            returnKey: 'version',
            setState,
            getData,
            route: deletePatchNote,
            viewDeactivated,
            setToastMessage,
            page: 'notes',
        })
    }

    const handleDeletionCheck = () => {
        setToastMessage(
            `Are you sure you want to delete these Models?`,
            'warning',
            handleDeletionData
        )
    }

    const setDeactivatedView = () => {
        setState({ viewDeactivated: !viewDeactivated })
    }
    useEffect(() => {
        collectColumnData()
        getData(true)
        setMinimized(false, restoredData.id, true)
    }, [])
    useEffect(() => {
        getData(!viewDeactivated)
    }, [viewDeactivated, activeManufacturer, userRole])

    const setModalVisbility = async (tableRow: any, newData: any) => {
        if (tableRow?.version) {
            setState({ row: tableRow, open: !open })
        } else {
            setState({ row: defaultState.row, open: !open })
        }
        if (newData !== undefined) {
            return getData(true)
        }
    }

    const minimizeModal = () => {
        setMinimized(true, {
            title: formCache.unitOfMeasurement
                ? `Edit: ${formCache.unitOfMeasurement}`
                : 'Add New Unit of Measure',
            link: '/data-hub/signals',
            data: formCache.id
                ? { ...formCache, tab: 'unitOfMeasurement' }
                : {
                      ...formCache,
                      id: 'unitOfMeasurement',
                      tab: 'unitOfMeasurement',
                  },
            id: formCache.id ? formCache.id : 'unitOfMeasurement',
        })
        setState({ open: false })
    }

    const defaultColumns = [
        'version',
        'releaseDate',
        'upcomingPatchVersion',
        'upcomingPatchDetails',
        'patchDetails',
        'patchNoteType',
    ]

    const buttons = [
        {
            buttonName: 'Add OS Patch Note',
            buttonIcon: <EditNoteIcon />,
            buttonFunction: () => setState({ open: !open }),
        },
        {
            buttonName: !viewDeactivated ? 'Deactivate' : 'Activate',
            buttonIcon: <ArchiveIcon />,
            buttonFunction: handleActivateData,
        },
        {
            buttonName: viewDeactivated
                ? 'View Active Patch Notes'
                : 'View Deactivated Patch Notes',
            buttonIcon: <Restore />,
            buttonFunction: setDeactivatedView,
        },
        {
            buttonName: 'Delete',
            buttonIcon: <DeleteForever />,
            buttonFunction: handleDeletionCheck,
            disabled: permissionsCheck(userPermissions, 'Super Admin'),
            display: !viewDeactivated ? 'none' : null,
        },
        // {
        //     buttonName: 'Delete Sensor',
        //     buttonIcon: <DeleteIcon />,
        //     buttonFunction: () => {},
        // },
    ]

    const handleUpdatedColumnsChange = (updatedColumns) => {
        const format = updatedColumns.map((col) => ({
            field: col.field,
            headerName: col.headerName,
            width: col.width,
            isVisible: col.isVisible,
        }))

        const setDensity = state.density

        const setData = {
            osPatchNotes: { columns: format, density: setDensity },
        }

        setState({
            system: setData,
        })
    }

    const handleDensityChange = (newDensity) => {
        setState({
            density: newDensity,
            system: {
                osPatchNotes: {
                    ...osPatchNotes,
                    density: newDensity,
                },
            },
        })
    }

    return (
        <Grid container>
            <Grid container>
                <UniversalToolBar buttons={buttons} />
            </Grid>

            <Grid item xs={12}>
                <UniversalLoadingCircle
                    data={data}
                    reloadFunction={
                        !viewDeactivated
                            ? () => getData(true)
                            : () => getData(false)
                    }
                    customTimeoutMessage={
                        !!viewDeactivated &&
                        'No Deactivated Patch Notes Available'
                    }
                >
                    <UniversalDataTable
                        data={data}
                        apiDensity={density}
                        apiColumns={system?.osPatchNotes?.columns}
                        savePreferences={() => submitColumnData()}
                        visibleColumns={[]}
                        getRowData={(tableRow) =>
                            setModalVisbility(tableRow, undefined)
                        }
                        onRowSelectionModelChange={(newSelection) => {
                            setState({
                                activateData: newSelection,
                            })
                        }}
                        onUpdatedColumnsChange={handleUpdatedColumnsChange}
                        onDensityChange={handleDensityChange}
                    />
                </UniversalLoadingCircle>
            </Grid>
            <UniversalModal
                title={`${
                    row.unitOfMeasurement
                        ? ` ${row.unitOfMeasurement}`
                        : 'OS Patch Note'
                }`}
                timeStamp={`${
                    row.updatedBy
                        ? ` ${
                              row.updatedBy
                                  ? ` Modified By ${row.updatedBy}`
                                  : ''
                          } ${
                              row.updatedAt
                                  ? `| Modified Date ${row.updatedAt.substring(
                                        0,
                                        10
                                    )}`
                                  : ''
                          }  `
                        : ` Modified By: ${username} | Modified Date: ${today} `
                }`}
                open={open}
                onClose={(tableRow) => setModalVisbility(tableRow, undefined)}
                wrapperStyle={{
                    margin: 'auto',
                    width: '90%',
                    marginTop: 5,
                }}
                onMinimize={minimizeModal}
                draggable={true}
                minimizable={true}
            >
                <UniversialPatchNotesForm
                    row={row}
                    permissionCheck={permissionsCheck(
                        userPermissions,
                        'Super Admin'
                    )}
                    onClose={(tableRow: any, newData: any) =>
                        setModalVisbility(tableRow, newData)
                    }
                    saveCache={(data: any) => setState({ formCache: data })}
                    patchNoteTypeDenotation="web"
                />
            </UniversalModal>
        </Grid>
    )
}

export default OsIndex
