import { Grid } from '@mui/material'

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
}

const UniversalTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <Grid
            container
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Grid container>{children}</Grid>}
        </Grid>
    )
}

export default UniversalTabPanel
