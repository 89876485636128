import { Grid, Paper } from '@mui/material'
import { useEffect, useState } from 'react'

interface SignalDefaultIconCardProps {
    cdnUrl: string
    signalIconUrl: string
    name: string
    handleChange: (e: any) => void
    icon: string
}

const SignalDefaultIconCard = ({
    cdnUrl,
    signalIconUrl,
    name,
    handleChange,
    icon,
}: SignalDefaultIconCardProps) => {
    const iconSelected = signalIconUrl === cdnUrl && !icon

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                margin: 1,
                width: 100,
                cursor: 'pointer',
            }}
            onClick={() => {
                const data = {
                    target: {
                        name: 'signalIconUrl',
                        value: cdnUrl,
                    },
                }
                handleChange(data)
            }}
        >
            <Grid
                item
                xs={12}
                sx={{
                    textAlign: 'center',
                }}
            >
                <Paper elevation={!iconSelected ? 4 : 0}>
                    <img
                        src={cdnUrl}
                        alt={name}
                        width={65}
                        height={65}
                        style={{ display: 'block', margin: 'auto' }}
                    />
                    <h5 style={{ paddingBottom: 5 }}>{name}</h5>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default SignalDefaultIconCard
