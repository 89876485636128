import { useReducer, useState, useEffect, useContext } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import { Typography, Chip } from '@mui/material'
import { Padding } from '@mui/icons-material'

import { FormSection } from '../../UniversalComponents'
import { ToastMessage } from '../../../context/ToastPopUpContext'
import { addDealer, getManufacturers } from '../../../apiRoutes'
import { UniversalGoogleMapsAc } from '../../UniversalComponents/UniversalFormStyles'
import { is } from '@babel/types'
import responseError from '../../../context/responseError/responseError'
import { basicValidator } from '../../../utilities/functionTools'
import { permissionsCheck } from '../../../utilities/functionTools'
import { usePermissions } from '../../../context/AuthenticationContext'

const DealerForm = ({ formTitle, row, onClose, permissionCheck }) => {
    const defaultState = {
        formData: {
            dealerName: row.dealerName || '',
            dealerEmail: row.dealerEmail || '',
            dealerAddress: row.dealerAddress || '',
            dealerSalesContactName: row.dealerSalesContactName || '',
            dealerSalesContactEmail: row.dealerSalesContactEmail || '',
            dealerSalesContactPhone: row.dealerSalesContactPhone || '',
            dealerServiceContactName: row.dealerServiceContactName || '',
            dealerServiceContactEmail: row.dealerServiceContactEmail || '',
            dealerServiceContactPhone: row.dealerServiceContactPhone || '',
            dealerPhoneNumber: row.dealerPhoneNumber || '',
            dealerLong: row.dealerLong || '',
            dealerLat: row.dealerLat || '',
            id: row.id,
            _id: row._id,
            manufacturer: row?.manufacturer || [],
        },

        formValidation: {
            dealerNameValidation: false,
            dealerEmailValidation: false,
            dealerLatValidation: false,
            dealerLongValidation: false,
            manufacturerValidation: false,
            isFormValid: false,
            isFormDirty: false,
        },
        manufacturers: [],
        manufacturerNames: [],
        permissionsCheck: permissionCheck,
    }

    const reducer = (state, newState) => ({ ...state, ...newState })
    const setToastMessage = useContext(ToastMessage)
    const [state, setState] = useReducer(reducer, defaultState)

    const {
        formValidation,
        formData,
        permissionsCheck,
        manufacturers,
        manufacturerNames,
    } = state

    const {
        isFormDirty,
        isFormValid,
        dealerNameValidation,
        dealerEmailValidation,
        dealerLatValidation,
        dealerLongValidation,
        manufacturerValidation,
    } = formValidation

    const {
        dealerName,
        dealerEmail,
        dealerPhoneNumber,
        dealerAddress,
        dealerSalesContactName,
        dealerSalesContactEmail,
        dealerSalesContactPhone,
        dealerServiceContactName,
        dealerServiceContactEmail,
        dealerServiceContactPhone,
        dealerLat,
        dealerLong,
        manufacturer,
    } = formData

    const handleChange = (e: any) => {
        const { name, value } = e.target

        setState({
            formData: { ...formData, [name]: value },
        })
    }

    const handleSubmit = async () => {
        try {
            const parsedFormData = { ...formData }
            let res: any
            res = await addDealer(parsedFormData)

            if (res) {
                const { data, status } = res

                if (data && status === 200) {
                    setToastMessage(
                        `Dealer: ${data.dealerName} was ${
                            row.id ? 'edited' : 'added'
                        } successfully`,
                        'success'
                    )
                    onClose(row.dealerName, data)
                } else {
                    responseError(res, row)
                }
            }
        } catch (err: any) {
            console.error(err, 'error')
            setToastMessage(`${err}`, 'error')
        }
    }

    const getManufacturerData = async () => {
        try {
            const data = await getManufacturers(true)
            if (data && data.length !== 0) {
                const manufacturerNames = data.map(
                    ({ manufacturerName }) => manufacturerName
                )

                setState({
                    manufacturerNames,
                    manufacturers: data.map(({ _id, manufacturerName }) => ({
                        _id,
                        manufacturerName,
                    })),
                })
            }
        } catch (err: any) {
            setToastMessage(err, 'error')
            console.error(err, 'error')
        }
    }

    const validateOnBlur = (e: any) => {
        const { name, value } = e.target

        if (value !== '') {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: false,
                    isFormDirty: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    [name + 'Validation']: true,
                    isFormDirty: true,
                },
            })
        }
    }

    useEffect(() => {
        getManufacturerData()
    }, [])

    const handleReset = () =>
        setState({
            formData: defaultState.formData,
            formValidation: defaultState.formValidation,
        })

    const handleCountryChange = (e: any) => {
        const selectedCountry = e.target.value
        let newState = {}

        if (selectedCountry !== 'United States') {
            newState = { dealerAddressState: '' }
        }

        setState({
            formData: {
                ...formData,
                dealerAddressCountry: selectedCountry,
                dealerAddressState: '',
            },
            ...newState,
            isFormDirty: true,
        })
    }

    const dealerAddressReset = () => {
        setState({
            formData: {
                ...formData,
                dealerAddress: '',
                dealerLat: '',
                dealerLong: '',
            },

            isFormDirty: true,
        })
    }

    useEffect(() => {
        const emailValid =
            dealerEmail.includes('.') && dealerEmail.includes('@')
        if (
            isFormDirty &&
            !dealerNameValidation &&
            !dealerEmailValidation &&
            emailValid &&
            !dealerLatValidation &&
            !dealerLongValidation &&
            dealerName &&
            dealerEmail &&
            dealerLat &&
            dealerLong
        ) {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: true,
                },
            })
        } else {
            setState({
                formValidation: {
                    ...formValidation,
                    isFormValid: false,
                },
            })
        }
    }, [
        isFormDirty,
        dealerNameValidation,
        dealerEmailValidation,
        dealerLatValidation,
        dealerLongValidation,
        dealerName,
        dealerEmail,
        dealerLat,
        dealerLong,
    ])
    const emailCheck = () => {
        if (dealerEmail.includes('.') && dealerEmail.includes('@')) {
            return true
        } else {
            return false
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ padding: 1, marginTop: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <FormSection
                            title="Dealer Information"
                            titleAlignment="left"
                        >
                            <Grid item xs={6} marginBottom={2.8}>
                                <TextField
                                    disabled={permissionCheck}
                                    label="Dealer Name"
                                    name="dealerName"
                                    value={dealerName}
                                    fullWidth
                                    onChange={handleChange}
                                    required
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={dealerNameValidation}
                                    helperText={
                                        dealerNameValidation
                                            ? 'Please enter a dealer name.'
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Email"
                                    name="dealerEmail"
                                    value={dealerEmail}
                                    fullWidth
                                    disabled={permissionCheck}
                                    onChange={handleChange}
                                    required
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    error={
                                        dealerEmailValidation === false &&
                                        dealerEmail
                                            ? !emailCheck()
                                            : dealerEmailValidation &&
                                              !dealerEmail
                                    }
                                    helperText={
                                        dealerEmailValidation === false &&
                                        !dealerEmail
                                            ? dealerEmailValidation &&
                                              !dealerEmail
                                            : !emailCheck()
                                            ? 'Please enter a valid Email Address.'
                                            : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Dealer Phone Number"
                                    name="dealerPhoneNumber"
                                    value={dealerPhoneNumber}
                                    fullWidth
                                    disabled={permissionCheck}
                                    onChange={handleChange}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    multiple
                                    id="manufacturer"
                                    value={manufacturer}
                                    onBlur={(e) => {
                                        setState({
                                            formValidation: {
                                                ...formValidation,
                                                isFormDirty: true,
                                            },
                                        })
                                        validateOnBlur({
                                            target: {
                                                name: 'manufacturer',
                                                value:
                                                    manufacturer.length === 0
                                                        ? ''
                                                        : true,
                                            },
                                        })
                                    }}
                                    onChange={(event, newValue) => {
                                        const data = {
                                            target: {
                                                name: 'manufacturer',
                                                value: newValue,
                                            },
                                        }

                                        handleChange(data)
                                    }}
                                    options={[
                                        ...manufacturer,
                                        ...manufacturers.filter(
                                            ({ manufacturerName }) =>
                                                !manufacturer.find(
                                                    (brand) =>
                                                        brand.manufacturerName ===
                                                        manufacturerName
                                                )
                                        ),
                                    ]}
                                    getOptionLabel={(option) =>
                                        option.manufacturerName
                                    }
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option: any, index) => (
                                            <Chip
                                                {...getTagProps({
                                                    index,
                                                })}
                                                label={option.manufacturerName}
                                                disabled={
                                                    manufacturer.indexOf(
                                                        index
                                                    ) !== -1
                                                }
                                                onDelete={(e) => {
                                                    getTagProps({
                                                        index,
                                                    }).onDelete(e)
                                                    setState({
                                                        formValidation: {
                                                            ...formValidation,
                                                            isFormDirty: true,
                                                        },
                                                    })
                                                }}
                                            />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="manufacturer"
                                            label="Assigned Manufacturers"
                                            placeholder="Assigned Manufacturers"
                                            helperText={
                                                manufacturerValidation
                                                    ? 'Please enter at least one Manufacturer'
                                                    : ''
                                            }
                                            error={manufacturerValidation}
                                        />
                                    )}
                                />
                            </Grid>
                        </FormSection>
                        <FormSection
                            title="Dealer Address Information"
                            titleAlignment="left"
                        >
                            <Grid item xs={12}>
                                <UniversalGoogleMapsAc
                                    name="dealerAddress"
                                    value={dealerAddress}
                                    disabled={permissionCheck}
                                    label="Dealer Address"
                                    fullWidth={true}
                                    clearFunction={dealerAddressReset}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                    onChange={(event) => {
                                        setState({
                                            formData: {
                                                ...formData,
                                                dealerAddress: event.address,
                                                dealerLat: event.lat,
                                                dealerLong: event.lng,
                                            },
                                            isFormDirty: true,
                                        })
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    label="GPS Latitude"
                                    name="dealerLat"
                                    value={dealerLat}
                                    fullWidth
                                    onChange={handleChange}
                                    required
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="GPS Longitude"
                                    name="dealerLong"
                                    value={dealerLong}
                                    fullWidth
                                    onChange={handleChange}
                                    required
                                    disabled
                                />
                            </Grid>
                        </FormSection>
                    </Grid>
                    <Grid item xs={4}>
                        <FormSection
                            title="Sales Contact Information"
                            titleAlignment="center"
                        >
                            <Grid item xs={12}>
                                <TextField
                                    label="Sales Contact Name"
                                    name="dealerSalesContactName"
                                    value={dealerSalesContactName}
                                    fullWidth
                                    disabled={permissionCheck}
                                    onChange={handleChange}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Sales Contact Email"
                                    name="dealerSalesContactEmail"
                                    value={dealerSalesContactEmail}
                                    fullWidth
                                    disabled={permissionCheck}
                                    onChange={handleChange}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Sales Contact Phone"
                                    name="dealerSalesContactPhone"
                                    value={dealerSalesContactPhone}
                                    fullWidth
                                    disabled={permissionCheck}
                                    onChange={handleChange}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Service Contact Name"
                                    name="dealerServiceContactName"
                                    value={dealerServiceContactName}
                                    fullWidth
                                    disabled={permissionCheck}
                                    onChange={handleChange}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Service Contact Email"
                                    name="dealerServiceContactEmail"
                                    value={dealerServiceContactEmail}
                                    fullWidth
                                    disabled={permissionCheck}
                                    onChange={handleChange}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Service Contact Phone"
                                    name="dealerServiceContactPhone"
                                    value={dealerServiceContactPhone}
                                    fullWidth
                                    disabled={permissionCheck}
                                    onChange={handleChange}
                                    onBlur={(e: any) =>
                                        basicValidator(
                                            e,
                                            formValidation,
                                            setState
                                        )
                                    }
                                />
                            </Grid>
                        </FormSection>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <FormSection
                    title="Required fields are marked with an * "
                    titleAlignment="center"
                >
                    <Grid item xs={7}>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{
                                width: '100%',
                                height: 55,
                            }}
                            disabled={!isFormValid || permissionCheck}
                        >
                            Save Dealer
                        </Button>
                    </Grid>
                    <Grid item xs={5}>
                        <Button
                            onClick={handleReset}
                            variant={isFormValid ? 'outlined' : 'contained'}
                            color="error"
                            disabled={!isFormDirty}
                            sx={{
                                width: '100%',
                                height: 55,
                            }}
                            fullWidth
                        >
                            Reset
                        </Button>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    )
}

export default DealerForm
